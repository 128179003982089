import { computed, makeObservable, observable } from 'mobx'

import {
  Account,
  EditAccountResponse,
  Invitation,
  Member,
  Plan,
  StripeConfiguration,
  SubscriptionPlan,
  isSubscriptionPlan,
} from '../@types/account'
import { ApplicationError } from '../@types/application'
import { ApplicationConstants as Constants } from '../constants'
import { CognitoPayloadAccount, CognitoSession } from './../@types/external'
import { AccountService } from './../services/accountService'
import { AuthStore } from './authStore'
import { BaseStore } from './baseStore'

export class AccountStore extends BaseStore {
  public authStore: AuthStore
  _account: Account | undefined
  _invitations: Invitation[] = []
  _members: (Member | Invitation)[] = []
  _stripe: StripeConfiguration
  _users: Member[] = []

  constructor(store: AuthStore) {
    super()

    this.authStore = store
    this._stripe = {
      clientSecret: '',
      publishableKey: '',
    }
    makeObservable(this, {
      account: computed,
      invitations: computed,
      lastFour: computed,
      members: computed,
      plan: computed,
      stripe: computed,
      users: computed,
      authStore: observable,
      _account: observable,
      _invitations: observable,
      _members: observable,
      _stripe: observable,
      _users: observable,
      accountStatus: false,
      accountFromSession: false,
      createInvitation: false,
      deleteInvitation: false,
      updateInvitation: false,
      createSubscription: false,
      deleteAccount: false,
      deleteSubscription: false,
      deleteUser: false,
      getUsers: false,
      updateUser: false,
      editAccount: false,
      getAccount: false,
      updateAccount: false,
      getAccountMembers: false,
      getAccountUsage: false,
      getPlans: false,
      newSubscription: false,
      planFor: false,
      updateSubscription: false,
      _errorFor: false,
    })
  }

  public get account(): Account | undefined {
    return this._account
  }

  public set account(account: Account | undefined) {
    this._account = account
  }

  public get accountStatus(): string {
    return (
      this.authStore.session &&
      this.accountFromSession(this.authStore.session as CognitoSession).status
    )
  }

  public get invitations(): Invitation[] {
    return this._invitations
  }

  public set invitations(value: Invitation[]) {
    this._invitations = value
  }

  public get lastFour(): string | undefined {
    return this.account?.paymentMethod.last4
  }

  public get members(): (Invitation | Member)[] {
    return this._members
  }

  public set members(value: (Invitation | Member)[]) {
    this._members = value
  }

  public get plan(): string | undefined {
    let planName = undefined
    if (typeof this._account == 'undefined') {
      return planName
    }
    const { plan, status } = this._account.subscription

    if (['active', 'incomplete'].includes(status)) {
      if (isSubscriptionPlan(plan)) {
        planName = this.planFor(plan.productId as string)
      }
    } else {
      planName = 'free'
    }

    return planName
  }

  planFor(productId: string): string {
    let planName = 'free'
    const key = `PLANS${process.env.PLANS}` as keyof typeof Constants
    const plans = Constants[key] as Plan[]

    plans.forEach(plan => {
      const planProductId = plan.product.id
      if (planProductId == productId) {
        planName = plan.name
      }
    })

    return planName
  }

  public get stripe(): StripeConfiguration {
    return this._stripe
  }

  public set stripe(value: StripeConfiguration) {
    this._stripe = value
  }

  public get users(): Member[] {
    return this._users
  }

  public set users(value: Member[]) {
    this._users = value
  }

  // Accounts

  public async deleteAccount(): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.deleteAccount(account.id, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.accounts.delete.error.message',
              })
              reject(error)
            }
          })
        }
      )
    })
  }

  public async editAccount(): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.editAccount(account.id, jwtToken)
        .then(response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.accounts.edit.error.message',
              })
              reject(error)
            }
          })
        })
        .then((data: unknown) => {
          this.stripe = { ...(data as EditAccountResponse).stripe }
        })
    })
  }

  public async getAccount() {
    const session = await this.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    const account = this.accountFromSession(session as CognitoSession)

    await AccountService.getAccount(account.id, jwtToken)
      .then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.accounts.get.error.message',
            })
            reject(error)
          }
        })
      })
      .then(data => {
        this.account = data as Account
      })
  }

  public async getAccountMembers() {
    const session = await this.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    const account = this.accountFromSession(session as CognitoSession)

    return await AccountService.getAccountMembers(account.id, jwtToken).then(
      response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.accounts.members.get.error.message',
            })
            reject(error)
          }
        })
      }
    )
  }

  public async getAccountUsage() {
    const session = await this.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    const account = this.accountFromSession(session as CognitoSession)

    return await AccountService.getAccountUsage(account.id, jwtToken).then(
      response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.accounts.usage.get.error.message',
            })
            reject(error)
          }
        })
      }
    )
  }

  public async getPlans() {
    return await AccountService.getPlans().then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          reject()
        }
      })
    })
  }

  public async updateAccount() {
    const session = await this.authStore.currentSession()
    const {
      accessToken: { jwtToken },
      idToken: { payload },
    } = session as CognitoSession

    const account = this.accountFromSession(session as CognitoSession)

    return await AccountService.updateAccount(
      account.id,
      payload,
      jwtToken
    ).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.accounts.update.get.error.message',
          })
          reject(error)
        }
      })
    })
  }

  // Subscriptions

  public async createSubscription(plan: SubscriptionPlan): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.createSubscription(account.id, plan, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response)
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.subscriptions.create.error.message',
              })
              reject(error)
            }
          })
        }
      )
    })
  }

  public async deleteSubscription(): Promise<Response> {
    const session = await this.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    const account = this.accountFromSession(session as CognitoSession)

    return AccountService.deleteSubscription(account.id, jwtToken).then(
      response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.subscriptions.delete.error.message',
            })
            reject(error)
          }
        })
      }
    )
  }

  public async newSubscription(): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.newSubscription(account.id, jwtToken)
        .then(response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.subscriptions.new.error.message',
              })
              reject(error)
            }
          })
        })
        .then((data: unknown) => {
          this._stripe = { ...(data as EditAccountResponse).stripe }
        })
    })
  }

  public async updateSubscription(plan: SubscriptionPlan): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.updateSubscription(account.id, plan, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response)
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.subscriptions.update.error.message',
              })
              reject(error)
            }
          })
        }
      )
    })
  }

  // Team

  public async createInvitation(invitation: Invitation): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.createInvitation(
        account.id,
        invitation,
        jwtToken
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.users.create.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            invitations: Invitation[]
          }
          this.invitations = users.invitations

          this.members = [...this.invitations]
          this.members.push(...this.users)
        })
      })
    })
  }

  public async deleteInvitation(invitationId: string): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return AccountService.deleteInvitation(invitationId, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.invitations.delete.error.message',
              })
              reject(error)
            }
          }).then((members: unknown) => {
            const users = members as {
              invitations: Invitation[]
            }
            this.invitations = users.invitations

            this.members = [...this.invitations]
            this.members.push(...this.users)
          })
        }
      )
    })
  }

  public async updateInvitation(
    accountId: string,
    invitationId: string
  ): Promise<unknown> {
    return AccountService.updateInvitation(accountId, invitationId).then(
      response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.users.create.error.message',
            })
            reject(error)
          }
        })
      }
    )
  }

  public async deleteUser(userId: string): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.deleteUser(account.id, userId, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.users.delete.error.message',
              })
              reject(error)
            }
          }).then((members: unknown) => {
            const users = members as {
              users: Member[]
            }
            this.users = users.users

            this.members = [...this.invitations]
            this.members.push(...users.users)
          })
        }
      )
    })
  }

  public async getUsers(): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.getUsers(account.id, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.users.get.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            invitations: Invitation[]
            users: Member[]
          }
          this.invitations = users.invitations
          this.users = users.users

          this.members = [...this.invitations]
          this.members.push(...users.users)
        })
      })
    })
  }

  public async updateUser(userId: string, role: string): Promise<unknown> {
    return await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      return AccountService.updateUser(account.id, userId, role, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.users.update.error.message',
              })
              reject(error)
            }
          }).then((members: unknown) => {
            const users = members as {
              users: Member[]
            }
            this.users = users.users

            this.members = [...this.invitations]
            this.members.push(...users.users)
          })
        }
      )
    })
  }

  accountFromSession(session: CognitoSession): CognitoPayloadAccount {
    const {
      idToken: {
        payload: { account: accountString },
      },
    } = session as CognitoSession

    return JSON.parse(accountString)
  }
}
