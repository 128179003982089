import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { PageProps } from '../app/@types/page'
import { Header } from '../components/Blog'
import { Footer, NavBar, Pricing } from '../components/Posters'
import { Faqs } from '../components/Posters/FAQ'

export const PricingPage = observer((props: PageProps) => {
  return (
    <>
      <NavBar
        {...props.pageViewModel}
        plans={[]}
        viewModel={props.posterViewModel}
      />
      <Pricing viewModel={props.posterViewModel} />
      <Faqs viewModel={props.posterViewModel} />
      <Header
        primaryLink="/auth/signup"
        secondaryLink="/pages/savings"
        viewModel={props.posterViewModel}
      />
      <Footer viewModel={props.posterViewModel} plans={[]} />
    </>
  )
})
