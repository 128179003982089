import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { Link } from '@tanstack/react-location'

import { AccountBillingViewProps } from '../../app/@types/account'
import { Spinner } from '../Spinner'
import { PaymentMethod } from './PaymentMethod'

export const PaymentSection = observer((props: AccountBillingViewProps) => {
  React.useEffect(() => {
    props.viewModel.editAccount()
  }, [])

  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box as="section" bg="bg-surface" py={{ base: '4', md: '8' }}>
      <Container maxW="full">
        <Stack spacing="5">
          <Skeleton isLoaded={!props.viewModel.isLoading}>
            <Stack
              spacing="4"
              direction={{ base: 'column', sm: 'row' }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  {t('accounts.billing.payment.title')}
                </Text>
                <Text fontSize="sm" color="muted">
                  {!props.viewModel.lastFour
                    ? t('accounts.billing.payment.absent.detail')
                    : props.viewModel.accountStatus == 'requires_action'
                    ? t('accounts.billing.payment.authenticate.detail')
                    : t('accounts.billing.payment.present.detail', {
                        lastFour: props.viewModel.lastFour,
                      })}
                </Text>
              </Box>
              <Stack direction="row" spacing="3">
                {props.viewModel.accountStatus == 'requires_action' ? (
                  <Button
                    as={Link}
                    to="/external/authenticate"
                    variant="primary"
                  >
                    {t('accounts.billing.payment.authenticate.action')}
                  </Button>
                ) : (
                  props.viewModel.lastFour && (
                    <Button onClick={onOpen} variant="primary">
                      {t('accounts.billing.payment.present.action')}
                    </Button>
                  )
                )}
              </Stack>
            </Stack>
          </Skeleton>
        </Stack>
        <Drawer onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              {props.viewModel.clientSecret ? (
                <Elements
                  stripe={props.viewModel.stripePromise}
                  options={{ clientSecret: props.viewModel.clientSecret }}
                >
                  <PaymentMethod {...props} />
                </Elements>
              ) : (
                <Spinner />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Container>
    </Box>
  )
})
