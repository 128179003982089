import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'

type HeaderProps = {
  index?: number
} & PosterProps

export const Header = observer((props: HeaderProps) => {
  const { t } = useTranslation()

  let index = props.index
  if (typeof props.index == 'undefined') {
    index = 0
  }

  return (
    <Box as="section">
      <Container py={{ base: '16', md: '24' }} maxW="5xl">
        <Stack spacing={{ base: '6', md: '8' }}>
          <Stack
            spacing={{ base: '2', md: '4' }}
            align="center"
            textAlign="center"
          >
            <Stack spacing="1">
              <Heading
                as="h1"
                fontWeight="bold"
                fontSize={useBreakpointValue({ base: 'lg', md: '4xl' })}
              >
                {t(`posters.${props.viewModel.name}.header.${index}.title`)}
              </Heading>
            </Stack>
            <Text fontSize={{ base: 'md', md: '3xl' }} lineHeight="1.25">
              {t(`posters.${props.viewModel.name}.header.${index}.subtitle`)}
            </Text>
          </Stack>
          <Center>
            <HStack spacing="3">
              {t(
                `posters.${props.viewModel.name}.header.${index}.actions.secondary`
              ) && (
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/pages/productivity"
                  variant="secondary"
                >
                  {t(
                    `posters.${props.viewModel.name}.header.${index}.actions.secondary`
                  )}
                </Button>
              )}
              {t(
                `posters.${props.viewModel.name}.header.${index}.actions.primary`
              ) && (
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/auth/signup"
                  variant="primary"
                >
                  {t(
                    `posters.${props.viewModel.name}.header.${index}.actions.primary`
                  )}
                </Button>
              )}
            </HStack>
          </Center>
        </Stack>
      </Container>
    </Box>
  )
})
