import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

import { AlertProps } from './../app/@types/application'

export const Alert = observer((props: AlertProps) => {
  const { t } = useTranslation()
  const cancelRef = React.useRef()

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{t(props.header)}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{t(props.body)}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={props.onClose}>
            {t(props.dismiss)}
          </Button>
          <Button colorScheme="red" ml={3} onClick={props.onConfirm}>
            {t(props.confirm)}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
})
