import { TFunction } from 'i18next'
import _ from 'lodash'
import { computed, makeObservable, observable } from 'mobx'
import * as React from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { MdOutlineInput } from 'react-icons/md'

import {
  Badge,
  HStack,
  IconButton,
  Spinner,
  Tag,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { ApplicationError, ApplicationNotice } from '../../@types/application'
import { Document, NewDocumentFormInput } from '../../@types/document'
import { Instance } from '../../@types/instance'
import { AccountStore } from '../../stores/accountStore'
import { ApplicationStore } from '../../stores/applicationStore'
import { DocumentStore } from '../../stores/documentStore'
import { InstanceStore } from '../../stores/instanceStore'

export class DocumentViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  documentStore: DocumentStore
  instanceStore: InstanceStore
  _currentDocument?: Document = undefined
  _error: ApplicationError | undefined
  _instances: Instance[] = []
  _isLoading = false
  _isProgressing = false
  _notice: ApplicationNotice | undefined
  _onCloseAction: () => void = () => {
    return null
  }

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    documentStore: DocumentStore,
    instanceStore: InstanceStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.documentStore = documentStore
    this.instanceStore = instanceStore
    makeObservable(this, {
      currentDocument: computed,
      documents: computed,
      error: computed,
      isLoading: computed,
      isProgressing: computed,
      instances: computed,
      notice: computed,
      onCloseAction: computed,
      accountStore: observable,
      applicationStore: observable,
      documentStore: observable,
      instanceStore: observable,
      _currentDocument: observable,
      _error: observable,
      _instances: observable,
      _isLoading: observable,
      _isProgressing: observable,
      _notice: observable,
      _onCloseAction: observable,
      createDocument: false,
      deleteDocument: false,
      getDocuments: false,
      runDocument: false,
      getInstances: false,
      setDocumentState: false,
      tableColumns: false,
      tableData: false,
      trackProgress: false,
      updateDocument: false,
    })
  }

  get currentDocument(): Document | undefined {
    return this._currentDocument
  }

  set currentDocument(documentId: string | Document | undefined) {
    this.documentStore.documents.forEach(document => {
      if (document._id == documentId) {
        this._currentDocument = document
      }
    })
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  get documents(): Document[] {
    return this.documentStore.documents
  }

  get instances(): Instance[] {
    return this._instances
  }

  set instances(value: Instance[]) {
    this._instances = value
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  get isProgressing() {
    return this._isProgressing
  }

  set isProgressing(value: boolean) {
    this._isProgressing = value
  }

  get notice(): ApplicationNotice | undefined {
    return this._notice
  }

  set notice(value: ApplicationNotice | undefined) {
    this._notice = value
  }

  get onCloseAction(): () => void | undefined {
    return this._onCloseAction
  }

  set onCloseAction(value: () => void | undefined) {
    this._onCloseAction = value
  }

  setDocumentState(
    documentId: string,
    status: 'creating' | 'deleting' | 'running'
  ) {
    this.documentStore.documents.forEach(document => {
      if (document._id == documentId) {
        document.status = status
      }
    })
  }

  trackProgress() {
    if (
      _.some(this.documentStore.documents, ['status', 'creating']) ||
      _.some(this.documentStore.documents, ['status', 'deleting']) ||
      _.some(this.documentStore.documents, ['status', 'running'])
    ) {
      const id = setInterval(() => {
        this.getDocuments(true)
      }, 15000)

      return () => clearInterval(id)
    }
  }

  // Documents

  async createDocument(values: NewDocumentFormInput) {
    this.isLoading = true
    return await this.documentStore
      .createDocument(values)
      .then(() => {
        this.isProgressing = true
        this.getDocuments(true)
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  async deleteDocument(id: string) {
    this.setDocumentState(id, 'deleting')

    return await this.documentStore
      .deleteDocument(id)
      .then(() => {
        this.getDocuments(true)
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async getDocuments(isProgressing = false) {
    if (isProgressing) {
      this.isProgressing = isProgressing
    } else {
      this.isLoading = true
      this.isProgressing = false
    }
    await this.documentStore
      .getDocuments()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async runDocument(documentId: string, values: string[]): Promise<void> {
    const closeAction = this.onCloseAction
    closeAction()
    this.setDocumentState(documentId, 'running')
    await this.documentStore
      .runDocument(documentId, values)
      .then(() => {
        this.notice = {
          message: 'requests.documents.run.notice.message',
        }
        this.trackProgress()
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async updateDocument(
    documentId: string,
    values: NewDocumentFormInput
  ): Promise<void> {
    const closeAction = this.onCloseAction
    closeAction()

    this.setDocumentState(documentId, 'creating')

    await this.documentStore
      .updateDocument(documentId, values)
      .then(() => {
        this.notice = {
          message: 'requests.documents.update.notice.message',
        }

        this.trackProgress()
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  // Instances

  async getInstances() {
    this.isLoading = true
    return await this.instanceStore
      .getInstances()
      .then(() => {
        this.instances = this.instanceStore.instances.filter(instance =>
          ['running'].includes(instance.status)
        )
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  // UI

  tableColumns(
    t: TFunction<'translation', undefined>,
    onRunDocument: (event: unknown, documentId: string) => void,
    onShowDocument: (event: unknown, documentId: string) => void,
    onDeleteDocument: (event: unknown, documentId: string) => void
  ): ColumnDef<Document, string | any>[] {
    const columnHelper = createColumnHelper<Document>()
    return [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: props => (
          <>
            <Text fontWeight="medium" mb={2}>
              {props.row.original.name}
            </Text>
            <Wrap>
              {props.row.original.tags.map(tag => {
                if (tag.label == 'On Build') {
                  return <></>
                }
                return (
                  <WrapItem key={tag.id} size="sm">
                    <Tag borderRadius={2} fontSize={10} minH={4}>
                      {tag.label}
                    </Tag>
                  </WrapItem>
                )
              })}
            </Wrap>
          </>
        ),
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: props => (
          <HStack>
            <Badge
              borderRadius={4}
              colorScheme={
                props.row.original.status === 'ready'
                  ? 'green'
                  : props.row.original.status === 'failed'
                  ? 'red'
                  : ['creating', 'running'].includes(props.row.original.status)
                  ? 'orange'
                  : 'gray'
              }
              size="sm"
            >
              {props.row.original.status}
            </Badge>
          </HStack>
        ),
      }),
      columnHelper.accessor('tags', {
        accessorFn: originalRow => {
          return originalRow.tags
            .map(tag => {
              if (tag.id !== 'run') {
                return tag.label
              }
            })
            .join(' ')
        },
        header: () => '',
        cell: () => <></>,
      }),
      columnHelper.accessor('status2', {
        id: 'actions',
        header: () => '',
        cell: props =>
          ['creating', 'deleting', 'running'].includes(
            props.row.original.status
          ) ? (
            <HStack justify="flex-end" spacing="1">
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            </HStack>
          ) : (
            <HStack justify="flex-end" spacing="1">
              <Tooltip label={t('documents.index.table.buttons.edit.label')}>
                <IconButton
                  id="pw-edit-button"
                  icon={<FiEdit2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Edit document"
                  onClick={event =>
                    onShowDocument(event, props.row.original._id)
                  }
                />
              </Tooltip>
              <Tooltip label={t('documents.index.table.buttons.apply.label')}>
                <IconButton
                  id="pw-run-button"
                  icon={<MdOutlineInput fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Run document"
                  onClick={event =>
                    onRunDocument(event, props.row.original._id)
                  }
                />
              </Tooltip>
              <Tooltip label={t('documents.index.table.buttons.delete.label')}>
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete document"
                  onClick={event =>
                    onDeleteDocument(event, props.row.original._id)
                  }
                />
              </Tooltip>
            </HStack>
          ),
        enableSorting: false,
      }),
    ]
  }

  get tableData(): Record<string, unknown>[] {
    return this.documents
  }
}
