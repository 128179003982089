// src/ChevronRight.tsx
import { createIcon } from "@chakra-ui/icon";
var ChevronRightIcon = createIcon({
  d: "M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z",
  displayName: "ChevronRightIcon"
});

export {
  ChevronRightIcon
};
