import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Box,
  Container,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { PosterProps } from '../../app/@types/poster'

type FeaturesProps = PosterProps & {
  level?: number
}

export const Features = observer((props: FeaturesProps) => {
  const features = []

  let level = props.level
  if (typeof props.level == 'undefined') {
    level = 1
  }

  for (
    let i = 3 * ((level as number) - 1);
    i <
    props.viewModel.featureLength[
      props.viewModel.name as 'landing' | 'onboarding' | 'secure'
    ] *
      (level as number);
    i++
  ) {
    features.push({
      name: `posters.${props.viewModel.name}.features.${i}.name`,
      description: `posters.${props.viewModel.name}.features.${i}.description`,
      icon: `posters.${props.viewModel.name}.features.${i}`['icon'],
    })
  }

  const { t } = useTranslation()
  return (
    <Box as="section">
      <Container py={{ base: '16', md: '24' }} maxW="4xl">
        <Stack spacing={{ base: '12', md: '16' }}>
          <Stack
            spacing={{ base: '4', md: '5' }}
            align="center"
            textAlign="center"
          >
            <Stack spacing="3">
              <Heading
                fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
              >
                {t(`posters.${props.viewModel.name}.features.heading${level}`)}
              </Heading>
            </Stack>
            <Text fontSize={{ base: 'md', md: '3xl' }} lineHeight="1.25">
              <Trans
                i18nKey={`posters.${props.viewModel.name}.features.subHeading${level}`}
              >
                Venv Instances enable real-time collaboration from the same
                virtual machine. Learn why collaboration is better for your team{' '}
                <Link textDecoration={'underline'} to="/pages/collaboration">
                  here
                </Link>
                .
              </Trans>
            </Text>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            columnGap={8}
            rowGap={{ base: 10, md: 16 }}
          >
            {features.map((feature, idx) => (
              <Stack
                key={t(feature.name)}
                spacing={{ base: '4', md: '5' }}
                align="center"
                textAlign="center"
              >
                <Square
                  size={{ base: '10', md: '12' }}
                  // bg="accent"
                  // color="inverted"
                  borderRadius="lg"
                >
                  <Icon
                    as={
                      props.viewModel.icons[props.viewModel.name][level - 1][
                        idx
                      ]
                    }
                    boxSize={{ base: '8', md: '12' }}
                    stroke="black"
                  />
                </Square>
                <Stack spacing={{ base: '0', md: '1' }}>
                  <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
                    {t(feature.name)}
                  </Text>
                  <Text fontSize="md">{t(feature.description)}</Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  )
})
