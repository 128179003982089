import { ApplicationError, ErrorOptions } from '../@types/application'
import {
  CognitoSessionResponse,
  CognitoSignUpResponse,
  CognitoUser,
  isCognitoSessionResponse,
  isCognitoSignUpResponse,
  isCognitoUser,
  isResponse,
} from '../@types/external'
import { ApplicationConstants as Constants } from '../constants'

export class BaseStore {
  _errorFor(
    response?:
      | Response
      | CognitoSessionResponse
      | CognitoSignUpResponse
      | CognitoUser,
    options?: ErrorOptions
  ): ApplicationError {
    if (
      isCognitoSessionResponse(response) ||
      isCognitoUser(response) ||
      isCognitoSignUpResponse(response)
    ) {
      return {
        message: options?.message,
        type: Constants.HTTP_ERROR,
      }
    } else if (isResponse(response)) {
      return {
        message: options?.message,
        status: 'error',
        type: Constants.HTTP_ERROR,
      }
    } else {
      return {
        message: options?.message,
        type: Constants.APPLICATION_ERROR,
      }
    }
  }
}
