import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  BoxProps,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

type StatisticProps = {
  dark: boolean
  label: string
  value: string
}

type Props = BoxProps & StatisticProps

export const Statistic = observer((props: Props) => {
  const { dark, label, value, ...boxProps } = props
  const { t } = useTranslation()

  if (dark) {
    return (
      <Box
        px={{ base: '4', md: '6' }}
        py={{ base: '2', md: '4' }}
        {...boxProps}
        bgColor="gray.600"
        borderRadius="8"
        maxW={{ base: 'full', md: '60%' }}
      >
        <Stack spacing="4">
          <Text
            color="white"
            fontSize={useBreakpointValue({ base: 'md', md: 'lg' })}
            fontWeight="bold"
          >
            {t(label)}
          </Text>
          <Text
            color="white"
            fontSize={useBreakpointValue({ base: 'md', md: 'lg' })}
          >
            {value}
          </Text>
        </Stack>
      </Box>
    )
  }

  return (
    <Box px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }} {...boxProps}>
      <Stack spacing="4">
        <Text
          fontSize={useBreakpointValue({ base: 'md', md: 'lg' })}
          color="muted"
        >
          {t(label)}
        </Text>
        <Heading fontSize={useBreakpointValue({ base: 'md', md: 'lg' })}>
          {value}
        </Heading>
      </Stack>
    </Box>
  )
})
