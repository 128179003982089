import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useRouter } from '@tanstack/react-location'

import { PrivateRoute } from '../../../components/PrivateRoute'
import { useStores } from '../../../hooks/useStores'
import { PrivateRouteViewModel } from '../../viewModels/Components'

export const PrivateRouteProvider: React.FC<{
  children: React.ReactElement
}> = observer(({ children }) => {
  const { store } = useStores()
  const viewModel = new PrivateRouteViewModel(store.authStore)

  return <PrivateRoute viewModel={viewModel} children={children} />
})

export const ScrollProvider = observer(() => {
  const { state } = useRouter()

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1)
  }, [state.location.pathname])

  return <></>
})

export default ScrollProvider
