import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { ExternalViewModel } from '../../viewModels/External'
import { PairViewModel } from '../../viewModels/Pair'
import { Authenticate, Complete, Invitation, Pair } from '../../views/External'
import { AccountTeamViewModel } from './../../viewModels/Account/AccountTeam'

export const ExternalAuthenticateProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new ExternalViewModel(store.accountStore)

  return <Authenticate viewModel={viewModel} />
})

export const ExternalCompleteProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new ExternalViewModel(store.accountStore)

  return <Complete viewModel={viewModel} />
})

export const ExternalInvitationProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AccountTeamViewModel(
    store.accountStore,
    store.authStore
  )

  return <Invitation viewModel={viewModel} />
})

export const ExternalPairProvider = observer(() => {
  // const { store } = useStores()

  const viewModel = new PairViewModel()

  return <Pair viewModel={viewModel} />
})
