import { observer } from 'mobx-react-lite'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FiBox, FiCpu } from 'react-icons/fi'
import { VscBrowser } from 'react-icons/vsc'

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'

import { RadioCard, RadioCardGroup } from '../../../components/RadioCardGroup'
import { ModalProps, NewInstanceFormInput } from './../../@types/instance'

export const NewInstance = observer((props: ModalProps) => {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<NewInstanceFormInput>({
    defaultValues: {},
  })
  const { t } = useTranslation()

  const [spaceTypeIsSet, setSpaceTypeIsSet] = React.useState(false)
  const watchSpaceType = watch('spaceType', undefined)

  React.useEffect(() => {
    const watcher = watch((value, { name }) => {
      if (name === 'spaceType') {
        setSpaceTypeIsSet(!spaceTypeIsSet)
        setValue('name', '')
        setValue('instanceType', '')
        setValue('operatingSystem', '')
        setValue('region', '')
      }
    })
    return () => watcher.unsubscribe()
  }, [watch])

  return (
    <Box id="pw-new-instance">
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: '4', md: '8' }}
        pb={{ base: '12', md: '24' }}
      >
        <Container maxW="full">
          <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {t('instances.new.header.title')}
              </Heading>
              <Text color="muted">{t('instances.new.header.subheading')}</Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container maxW="full">
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <Tabs>
              <TabList>
                <Tab>Space Type</Tab>
                <Tab isDisabled={!spaceTypeIsSet}>Space Configuration</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Controller
                    name="spaceType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RadioCardGroup
                        defaultValue="1"
                        spacing="3"
                        onChange={onChange}
                        value={value}
                      >
                        <RadioCard key={1} value="fargate">
                          <HStack>
                            <Icon as={VscBrowser} />
                            <VStack align="stretch" spacing={1}>
                              <Text
                                color="emphasized"
                                fontWeight="medium"
                                fontSize="sm"
                              >
                                Browser
                              </Text>
                              <Text color="muted" fontSize="sm">
                                Pair from your Browser.
                              </Text>
                            </VStack>
                          </HStack>
                        </RadioCard>
                        <RadioCard key={1} value="devcontainer">
                          <HStack>
                            <Icon as={FiBox} />
                            <VStack align="stretch" spacing={1}>
                              <Text
                                color="emphasized"
                                fontWeight="medium"
                                fontSize="sm"
                              >
                                DevContainer
                              </Text>
                              <Text color="muted" fontSize="sm">
                                Pair from a DevContainer.
                              </Text>
                            </VStack>
                          </HStack>
                        </RadioCard>
                        <RadioCard key={2} value="ec2">
                          <HStack>
                            <Icon as={FiCpu} />
                            <VStack align="stretch" spacing={1}>
                              <Text
                                color="emphasized"
                                fontWeight="medium"
                                fontSize="sm"
                              >
                                Virtual Machine
                              </Text>
                              <Text color="muted" fontSize="sm">
                                Pair from a shell or your favorite IDE.
                              </Text>
                            </VStack>
                          </HStack>
                        </RadioCard>
                      </RadioCardGroup>
                    )}
                  />
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!!errors.name}>
                      <Input
                        id="name"
                        size="lg"
                        placeholder={
                          t('instances.new.form.name.label') as string
                        }
                        {...register('name', {
                          required: t(
                            'instances.new.form.name.required'
                          ) as string,
                          minLength: {
                            value: 3,
                            message: t(
                              'instances.new.form.name.minLength'
                            ) as string,
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.name && errors.name.message}
                      </FormErrorMessage>
                    </FormControl>
                    {['ec2'].includes(watchSpaceType as string) && (
                      <>
                        <FormControl isInvalid={!!errors.instanceType}>
                          <Select
                            id="instanceType"
                            placeholder="Select CPU and RAM"
                            {...register('instanceType', {
                              required: 'Please select CPU and RAM',
                            })}
                          >
                            {props.viewModel.instanceTypes.map(instanceType => {
                              return (
                                <option value={instanceType.value}>
                                  {instanceType.name}
                                </option>
                              )
                            })}
                          </Select>
                          <FormErrorMessage>
                            {errors.instanceType && errors.instanceType.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.operatingSystem}>
                          <Select
                            id="operatingSystem"
                            placeholder="Select an operating system"
                            {...register('operatingSystem', {
                              required: 'Please select an operating system',
                            })}
                          >
                            {props.viewModel.operatingSystems.map(
                              operatingSystem => {
                                return (
                                  <option value={operatingSystem.value}>
                                    {operatingSystem.name}
                                  </option>
                                )
                              }
                            )}
                          </Select>
                          <FormErrorMessage>
                            {errors.operatingSystem &&
                              errors.operatingSystem.message}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                    <FormControl isInvalid={!!errors.region}>
                      <Select
                        id="region"
                        placeholder="Select a region"
                        {...register('region', {
                          required: 'Please select a region',
                        })}
                      >
                        <option value="us-east-1">America (Virginia)</option>
                        {/* <option value="us-west-1">America (California)</option>
                  <option value="ap-southeast-1">Asia (Singapore)</option>
                  <option value="eu-central-1">Europe (Germany)</option>
                  <option value="eu-north-1">Europe (Sweden)</option>
                  <option value="eu-west-1">Europe (Ireland)</option> */}
                      </Select>
                      <FormErrorMessage>
                        {errors.region && errors.region.message}
                      </FormErrorMessage>
                    </FormControl>

                    <ButtonGroup alignSelf="end">
                      <Button variant="secondary" onClick={props.onClose}>
                        {t('instances.new.form.action.cancel')}
                      </Button>
                      <Button variant="primary" type="submit">
                        {t('instances.new.form.action.launch')}
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </form>
        </Container>
      </Box>
    </Box>
  )
})
