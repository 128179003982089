import { observer } from 'mobx-react-lite'
import React, { createContext, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ApplicationConstants as Constants } from '../app/constants'
import { CognitoSession } from './../app/@types/external'
import { LogMessage } from './../app/@types/logger'
import { useStores } from './useStores'

type UseLogger = {
  log: {
    [key: string]: (content: LogMessage) => void
  }
}

type Props = {
  children?: React.ReactNode
}

const LoggerContext = createContext({} as UseLogger)

const useProvideLogger = (): UseLogger => {
  const { store } = useStores()

  const _logRequest = (request: LogMessage, token: string) => {
    fetch(`${Constants.API_URI}/log`, {
      method: Constants.HTTP_METHODS.POST,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(request),
    })
  }

  const _log_func = async (request: LogMessage) => {
    await store.authStore
      .currentSession()
      .then((session: CognitoSession | unknown) => {
        const {
          accessToken: { jwtToken },
        } = session as CognitoSession
        _logRequest(request, jwtToken)
      })
  }

  const log = {
    debug: async (content: LogMessage) => {
      content.level = Constants.LOG_LEVEL.DEBUG as string
      content.level = content.level.toUpperCase()
      await _log_func(content)
    },
    error: async (content: LogMessage) => {
      content.level = Constants.LOG_LEVEL.ERROR as string
      content.level = content.level.toUpperCase()
      await _log_func(content)
    },
    exception: async (content: LogMessage) => {
      content.level = Constants.LOG_LEVEL.EXCEPTION as string
      content.level = content.level.toUpperCase()
      await _log_func(content)
    },
    info: async (content: LogMessage) => {
      content.level = Constants.LOG_LEVEL.INFO as string
      content.level = content.level.toUpperCase()
      await _log_func(content)
    },
    warn: async (content: LogMessage) => {
      content.level = Constants.LOG_LEVEL.WARN as string
      content.level = content.level.toUpperCase()
      await _log_func(content)
    },
  }

  return {
    log,
  }
}

export const LoggerProvider: React.FC<Props> = observer(({ children }) => {
  const log = useProvideLogger()
  return <LoggerContext.Provider value={log}>{children}</LoggerContext.Provider>
})

export const useLogger = () => {
  return useContext(LoggerContext)
}
