import { observer } from 'mobx-react-lite'
import React from 'react'
import { Control, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { ModalProps, NewRepositoryFormInput } from '../../@types/repository'

export const NewRepository = observer((props: ModalProps) => {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<NewRepositoryFormInput>({
    defaultValues: {},
  })
  const { t } = useTranslation()

  const RepositoryParentWatch = observer(
    ({ control }: { control: Control<NewRepositoryFormInput> }) => {
      const repositoryUrl = useWatch({
        control,
        name: 'url',
      })

      const parentPattern =
        /https:\/\/(www.)?(bitbucket.org|github.com|gitlab.com)+\/(.*)+\/(.*)+/g

      if (
        repositoryUrl &&
        (repositoryUrl.includes('github.com') ||
          repositoryUrl.includes('gitlab.com'))
      ) {
        const matches = parentPattern.exec(repositoryUrl)
        const name = matches && matches[4]
        const parentName = matches && matches[3]

        name && setValue('name', name as string)
        parentName && setValue('parentName', parentName as string)

        return (
          <>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>{t('repositories.new.form.name.label')}</FormLabel>
              <Input
                id="name"
                size="lg"
                disabled={true}
                hidden={true}
                {...register('name')}
              />
            </FormControl>
            <FormControl isInvalid={!!errors.parentName}>
              <FormLabel>{t('')}</FormLabel>
              <Input
                id="parentName"
                size="lg"
                disabled={true}
                hidden={true}
                {...register('parentName')}
              />
            </FormControl>
          </>
        )
      }

      if (repositoryUrl && repositoryUrl.includes('bitbucket.org')) {
        const matches = parentPattern.exec(repositoryUrl)
        const name = matches && matches[4]
        const parentName = matches && matches[3]

        name && setValue('name', name as string)
        parentName && setValue('parentName', parentName as string)

        return (
          <>
            <FormControl isInvalid={!!errors.name}>
              <Input
                id="name"
                size="lg"
                disabled={true}
                hidden={true}
                {...register('name')}
              />
            </FormControl>
            <FormControl isInvalid={!!errors.parentName}>
              <Input
                id="parentName"
                value={parentName as string}
                size="lg"
                disabled={true}
                hidden={true}
                {...register('parentName')}
              />
              <FormErrorMessage>
                {errors.parentName && errors.parentName.message}
              </FormErrorMessage>
            </FormControl>
            <Divider />
            <Text>{t('repositories.new.form.bitbucket.label')}</Text>
            <FormControl isInvalid={!!errors.parentId}>
              <FormLabel>
                {t('repositories.new.form.bitbucket.parent.id.label')}
              </FormLabel>
              <Input
                id="parentId"
                size="lg"
                placeholder="Enter the UUID of the Bitbucket Workspace"
                {...register('parentId', {
                  required: t(
                    'repositories.new.form.bitbucket.parent.id.required'
                  ) as string,
                  pattern: {
                    value:
                      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                    message: t(
                      'repositories.new.form.bitbucket.parent.id.pattern'
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.parentId && errors.parentId.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.id}>
              <FormLabel>
                {t('repositories.new.form.bitbucket.id.label')}
              </FormLabel>
              <Input
                id="id"
                size="lg"
                placeholder="Enter the UUID of the Bitbucket Repository"
                {...register('id', {
                  required: t(
                    'repositories.new.form.bitbucket.id.required'
                  ) as string,
                  pattern: {
                    value:
                      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                    message: t('repositories.new.form.bitbucket.id.pattern'),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.id && errors.id.message}
              </FormErrorMessage>
            </FormControl>
          </>
        )
      }

      return <></>
    }
  )

  return (
    <Box id="pw-new-repository">
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: '4', md: '8' }}
        pb={{ base: '12', md: '24' }}
      >
        <Container maxW="full">
          <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {t('repositories.new.header.title')}
              </Heading>
              <Text color="muted">
                {t('repositories.new.header.subheading')}
              </Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container maxW="full">
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.url}>
                <FormLabel htmlFor="url" variant="floating" size="lg">
                  {t('repositories.new.form.url.label')}
                </FormLabel>
                <Input
                  id="url"
                  size="lg"
                  placeholder="https://github.com/organization/repository.git"
                  {...register('url', {
                    required: t('repositories.new.form.url.required') as string,
                    pattern: {
                      value:
                        /https:\/\/(www\.)?(bitbucket|github|gitlab)+\.(com|org)+[:/](.*)+\/(.*)+.(git)?/,
                      message: t('repositories.new.form.url.pattern'),
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.url && errors.url.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.operatingSystem}>
                <FormLabel>{t('repositories.new.form.os.label')}</FormLabel>
                <Select
                  id="operatingSystem"
                  placeholder="Select an operating system"
                  {...register('operatingSystem', {
                    required: 'Please select a operating system',
                  })}
                >
                  <option value="amzn_linux_2023">Amazon Linux 2023</option>
                  <option value="ubuntu_22_04_lts">Ubuntu 22.04 LTS</option>
                </Select>
                <FormErrorMessage>
                  {errors.operatingSystem && errors.operatingSystem.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.region}>
                <FormLabel>{t('repositories.new.form.region.label')}</FormLabel>
                <Select
                  id="region"
                  placeholder="Select a region"
                  {...register('region', {
                    required: 'Please select a region',
                  })}
                >
                  <option value="us-east-1">America (Virginia)</option>
                  <option value="us-west-1">America (California)</option>
                  <option value="ap-southeast-1">Asia (Singapore)</option>
                  <option value="eu-central-1">Europe (Germany)</option>
                  <option value="eu-north-1">Europe (Sweden)</option>
                  <option value="eu-west-1">Europe (Ireland)</option>
                </Select>
                <FormErrorMessage>
                  {errors.region && errors.region.message}
                </FormErrorMessage>
              </FormControl>
              <RepositoryParentWatch control={control} />
              <ButtonGroup alignSelf="end">
                <Button variant="secondary" onClick={props.onClose}>
                  {t('repositories.new.form.action.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('repositories.new.form.action.submit')}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </Container>
      </Box>
    </Box>
  )
})
