import { observer } from 'mobx-react-lite'
import React, { createContext, useContext } from 'react'

import { ApplicationConstants as Constants } from '../app/constants'

type UsePlausible = Promise<unknown>

const PlausibleContext = createContext({} as UsePlausible)

const useProvidePlausible = async (): UsePlausible => {
  const plausibleScript = document.createElement('script')
  plausibleScript.defer = true
  plausibleScript.src = 'https://plausible.io/js/script.js'
  plausibleScript.setAttribute('data-domain', Constants.PLAUSIBLE_DOMAIN)
  document.getElementsByTagName('head')[0].appendChild(plausibleScript)

  const customEventScript = document.createElement('script')
  const scriptText = document.createTextNode(
    `window.plausible = window.plausible || function() {(window.plausible.q = window.plausible.q || []).push(arguments)}`
  )
  customEventScript.appendChild(scriptText)
  document.getElementsByTagName('head')[0].appendChild(customEventScript)

  return {}
}

export const PlausibleProvider: React.FC<Props> = observer(({ children }) => {
  const plausible = useProvidePlausible()
  return (
    <PlausibleContext.Provider value={plausible}>
      {children}
    </PlausibleContext.Provider>
  )
})

export const usePlausible = () => {
  return useContext(PlausibleContext)
}
