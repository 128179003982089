import { action, computed, makeObservable, observable } from 'mobx'

import { ApplicationError } from '../../@types/application'
import { ApplicationConstants as Constants } from '../../constants'
import { AnalyticsService } from '../../services/analyticsService'
import { AccountStore } from '../../stores/accountStore'
import { errorObjectFromError } from '../../utilities'
import { ApplicationStore } from './../../stores/applicationStore'
import { AuthStore } from './../../stores/authStore'

export class AuthViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  authStore: AuthStore
  _error: ApplicationError | undefined
  _isBitbucket = false
  _isCognito = false
  _isGitHub = false
  _isGitLab = false
  _isLoading = false
  _isUpgrading = false

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    authStore: AuthStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.authStore = authStore
    makeObservable(this, {
      confirmCode: action,
      confirmGuest: action,
      signIn: action,
      signInBitbucket: action,
      signInGitHub: action,
      signInGitLab: action,
      signUp: action,
      signUpBitbucket: action,
      signUpGitHub: action,
      signUpGitLab: action,
      error: computed,
      isBitbucket: computed,
      isCognito: computed,
      isGitHub: computed,
      isGitLab: computed,
      isLoading: computed,
      isUpgrading: computed,
      isAuthenticated: computed,
      user: computed,
      accountStore: observable,
      applicationStore: observable,
      authStore: observable,
      _error: observable,
      _isBitbucket: observable,
      _isCognito: observable,
      _isGitHub: observable,
      _isGitLab: observable,
      _isLoading: observable,
      _isUpgrading: observable,
      redirectPath: false,
    })
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  get isAuthenticated() {
    return this.applicationStore.isAuthenticated
  }

  get isBitbucket() {
    return this._isBitbucket
  }

  set isBitbucket(value: boolean) {
    this._isBitbucket = value

    this._isGitHub = false
    this._isGitLab = false
  }

  get isCognito() {
    return this._isCognito
  }

  set isCognito(value: boolean) {
    this._isCognito = value
  }

  get isGitHub() {
    return this._isGitHub
  }

  set isGitHub(value: boolean) {
    this._isGitHub = value

    this._isBitbucket = false
    this._isGitLab = false
  }

  get isGitLab() {
    return this._isGitLab
  }

  set isGitLab(value: boolean) {
    this._isGitLab = value

    this._isBitbucket = false
    this._isGitHub = false
  }

  get isLoading() {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  get isUpgrading() {
    return this._isUpgrading
  }

  set isUpgrading(value: boolean) {
    this._isUpgrading = value
  }

  get redirectPath(): string | undefined {
    const redirectPath = this.authStore.redirectPath
    if (typeof redirectPath != 'undefined') {
      return redirectPath
    }

    if (['account.read', 'billing.read'].includes(this.authStore.scope)) {
      return '/accounts/billing'
    }

    return '/spaces'
  }

  get user() {
    return this.authStore.user
  }

  set user(value: unknown) {
    this.authStore.user = value
  }

  async confirmCode(code: string) {
    this.isLoading = true
    return await this.authStore
      .confirmSignIn(code)
      .then(() => {
        this.isCognito = false
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.isCognito = false
        this.error = error
      })
  }

  async confirmGuest() {
    this.isLoading = false
    this.isCognito = false
    this.isUpgrading = true
  }

  async signIn(email: string) {
    this.isCognito = true
    this.isLoading = true

    if (this.isUpgrading) {
      return await this.signUp(email)
    }

    return await this.authStore
      .signIn(email)
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        if (error.name == 'UserLambdaValidationException') {
          const errorObject = errorObjectFromError(error)

          if (
            errorObject &&
            errorObject.action &&
            [Constants.PROMPT_USER].includes(errorObject.action)
          ) {
            errorObject.status = 'warning'
            this.error = errorObject
          } else {
            this.error = {
              message: 'requests.auth.signIn.error.message',
              type: Constants.HTTP_ERROR,
            }
          }
        } else {
          this.error = error
          this.isCognito = false
          this.isLoading = false
        }
      })
  }

  async signInBitbucket() {
    this.isBitbucket = true
    this.isLoading = true
    setTimeout(() => {
      return this.authStore
        .signUpBitbucket()
        .catch((error: ApplicationError) => {
          this.error = error
          this.isLoading = false
        })
    }, 500)
  }

  async signInGitHub() {
    this.isGitHub = true
    this.isLoading = true
    setTimeout(() => {
      return this.authStore.signUpGitHub().catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
    }, 500)
  }

  async signInGitLab() {
    this.isGitLab = true
    this.isLoading = true
    setTimeout(() => {
      return this.authStore.signUpGitLab().catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
    }, 500)
  }

  async signUp(email: string) {
    this.isCognito = true
    this.isLoading = true
    return this.authStore
      .signUp(email, {
        clientMetadata: {
          isUpgrading: this.isUpgrading.toString(),
        },
      })
      .then(() => {
        this.isLoading = false

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        if (error.name == 'UserLambdaValidationException') {
          const errorObject = errorObjectFromError(error)

          if (
            errorObject &&
            errorObject.action &&
            [Constants.PROMPT_USER].includes(errorObject.action)
          ) {
            errorObject.status = 'warning'
            this.error = errorObject
          } else if (
            errorObject &&
            errorObject.action &&
            [Constants.CONFIRM_CODE].includes(errorObject.action)
          ) {
            this.isUpgrading = false
            this.isLoading = false

            errorObject.status = 'info'
            this.error = errorObject
          } else {
            this.error = {
              message: 'requests.auth.cognito.error.message',
              type: Constants.HTTP_ERROR,
            }
          }
        } else {
          this.error = error
          this.isLoading = false
        }
      })
  }

  async signUpBitbucket() {
    this.isBitbucket = true
    this.isLoading = true
    return this.authStore
      .signUpBitbucket()
      .then(() => {
        this.isLoading = false

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  async signUpGitHub() {
    this.isGitHub = true
    this.isLoading = true
    return this.authStore
      .signUpGitHub()
      .then(() => {
        this.isLoading = false

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  async signUpGitLab() {
    this.isGitLab = true
    this.isLoading = true
    return this.authStore
      .signUpGitLab()
      .then(() => {
        this.isLoading = false

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }
}
