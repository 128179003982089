import { v4 as uuidv4 } from 'uuid'

import { InstanceMember, InstanceUpdate, NewInstance } from '../@types/instance'
import { ApplicationConstants as Constants } from './../constants'

export type IInstanceService = {
  create: (body: NewInstance, token: string) => Promise<Response>
  delete: (id: string, token: string) => Promise<Response>
  list: (token: string) => Promise<Response>
  update: (
    id: string,
    values: InstanceUpdate,
    token: string
  ) => Promise<Response>
}

class InstanceServiceClass implements IInstanceService {
  async create(body: NewInstance, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/instances`, {
      method: Constants.HTTP_METHODS.POST,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async delete(id: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/instances/${id}`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async deleteFromSession(session: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/sessions/${session}/instance`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async list(token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/spaces`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async restore(id: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/instances/${id}/restore`, {
      method: Constants.HTTP_METHODS.POST,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async share(
    id: string,
    values: InstanceMember[],
    token: string
  ): Promise<Response> {
    const body = {
      users: values,
    }
    return fetch(`${Constants.API_URI}/instances/${id}/share`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async shareContainer(
    id: string,
    values: InstanceMember[],
    token: string
  ): Promise<Response> {
    const body = {
      users: values,
    }
    return fetch(`${Constants.API_URI}/containers/${id}/share`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async update(
    id: string,
    values: InstanceUpdate,
    token: string
  ): Promise<Response> {
    return fetch(`${Constants.API_URI}/instances/${id}`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(values),
    })
  }
}

export const InstanceService = new InstanceServiceClass()
