import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { AboutPage } from '../../../pages/About'
import { AgencyPage } from '../../../pages/Agency'
import { CalculatorPage } from '../../../pages/Calculator'
import { CollaborationPage } from '../../../pages/Collaboration'
import { EnterprisePage } from '../../../pages/Enterprise'
import { PricingPage } from '../../../pages/Pricing'
import { ProductivityPage } from '../../../pages/Productivity'
import { SecurityPage } from '../../../pages/Security'
import { StartupPage } from '../../../pages/Startup'
import { PrivacyPage } from '../../../pages/legal/Privacy'
import { TermsPage } from '../../../pages/legal/Terms'
import { BlogViewModel } from '../../viewModels/Blog'
import { PageViewModel } from '../../viewModels/Pages'
import { PosterViewModel } from '../../viewModels/Posters'

export const AboutPageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'about'

  return <AboutPage viewModel={viewModel} />
})

export const AgencyPageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'agency'

  return <AgencyPage viewModel={viewModel} />
})

export const CalculatorPageProvider = observer(() => {
  const { store } = useStores()

  const pageViewModel = new PageViewModel()
  pageViewModel.name = 'calculator'

  const posterViewModel = new PosterViewModel(store.accountStore)
  posterViewModel.name = 'calculator'

  return (
    <CalculatorPage
      pageViewModel={pageViewModel}
      posterViewModel={posterViewModel}
    />
  )
})

export const CollaborationPageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'collaboration'

  return <CollaborationPage viewModel={viewModel} />
})

export const EnterprisePageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'enterprise'

  return <EnterprisePage viewModel={viewModel} />
})

export const LegalTermsPageProvider = observer(() => {
  const { store } = useStores()

  const pageViewModel = new PageViewModel()
  pageViewModel.name = 'legal.terms'

  const posterViewModel = new PosterViewModel(store.accountStore)

  return (
    <TermsPage
      pageViewModel={pageViewModel}
      posterViewModel={posterViewModel}
    />
  )
})

export const LegalPrivacyPageProvider = observer(() => {
  const { store } = useStores()

  const pageViewModel = new PageViewModel()
  pageViewModel.name = 'legal.privacy'

  const posterViewModel = new PosterViewModel(store.accountStore)

  return (
    <PrivacyPage
      pageViewModel={pageViewModel}
      posterViewModel={posterViewModel}
    />
  )
})

export const PricingPageProvider = observer(() => {
  const { store } = useStores()

  const pageViewModel = new PageViewModel()
  pageViewModel.name = 'pricing'

  const posterViewModel = new PosterViewModel(store.accountStore)
  posterViewModel.name = 'pricing'

  return (
    <PricingPage
      pageViewModel={pageViewModel}
      posterViewModel={posterViewModel}
    />
  )
})

export const ProductivityPageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'productivity'

  return <ProductivityPage viewModel={viewModel} />
})

export const SecurityPageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'security'

  return <SecurityPage viewModel={viewModel} />
})

export const StartupPageProvider = observer(() => {
  const viewModel = new BlogViewModel()
  viewModel.name = 'startup'

  return <StartupPage viewModel={viewModel} />
})
