import { computed, makeObservable, observable } from 'mobx'

import { ApplicationConstants as Constants } from '../../constants'

export class PageViewModel {
  _name = 'legal'

  constructor() {
    makeObservable(this, {
      name: computed,
      _name: observable,
      documentation: false,
      numberOfSections: false,
    })
  }

  public documentation() {
    window.open(Constants.DOCUMENTATION_URL, '_blank')
  }

  public get name(): string {
    return this._name
  }

  public set name(value: string) {
    this._name = value
  }

  public get numberOfSections() {
    return {
      about: 2,
      'legal.privacy': 41,
      'legal.terms': 41,
      'more.business': 3,
      'more.focus': 4,
      'more.time': 3,
    }[this.name]
  }
}
