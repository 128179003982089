import { AccountStore } from './accountStore'
import { ApplicationStore } from './applicationStore'
import { AuthStore } from './authStore'
import { ContainerStore } from './containerStore'
import { DocumentStore } from './documentStore'
import { InstanceStore } from './instanceStore'
import { RepositoryStore } from './repositoryStore'
import { WatchStore } from './watchStore'

export class RootStore {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  authStore: AuthStore
  containerStore: ContainerStore
  documentStore: DocumentStore
  instanceStore: InstanceStore
  repositoryStore: RepositoryStore
  watchStore: WatchStore

  constructor() {
    this.authStore = new AuthStore()

    this.accountStore = new AccountStore(this.authStore)
    this.applicationStore = new ApplicationStore(
      this.accountStore,
      this.authStore
    )
    this.containerStore = new ContainerStore(this)
    this.documentStore = new DocumentStore(this)
    this.instanceStore = new InstanceStore(this)
    this.repositoryStore = new RepositoryStore(this)
    this.watchStore = new WatchStore(this, this.authStore)
  }
}
