import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiSearch } from 'react-icons/fi'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'

import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {
  AccountTeamViewProps,
  Invitation,
  Member,
  User,
} from '../../@types/account'
import { Alert } from './../../../components/AlertDialog'
import { EditUser } from './EditUser'
import { NewUser } from './NewUser'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const AccountTeam = observer((props: AccountTeamViewProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
    if (props.viewModel.notice) {
      toast({
        description: t(props.viewModel.notice.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'success',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.notice = undefined
        },
      })
    }
  }, [props.viewModel.error, props.viewModel.notice])

  useEffect(() => {
    props.viewModel.getUsers()
  }, [])

  useEffect(() => {
    table.setPageSize(50) // Arbitrary value
  }, [props.viewModel.members])

  const breakpointValue = useBreakpointValue({ base: true, md: false })
  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  const [invitationId, setInvitationId] = React.useState('')
  const [userId, setUserId] = React.useState('')

  const {
    isOpen: isOpenDeleteInvitation,
    onOpen: onOpenDeleteInvitation,
    onClose: onCloseDeleteInvitation,
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteUser,
    onOpen: onOpenDeleteUser,
    onClose: onCloseDeleteUser,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmDeleteUser = (event: unknown) => {
    onCloseDeleteUser()

    props.viewModel.deleteUser(userId)
    setUserId('')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmDeleteInvitation = (event: unknown) => {
    onCloseDeleteInvitation()

    props.viewModel.deleteInvitation(invitationId)
    setInvitationId('')
  }

  const onDeleteInvitation = (event: unknown, invitationId: string) => {
    setInvitationId(invitationId)

    onOpenDeleteInvitation()
  }

  const onDeleteUser = (event: unknown, userId: string) => {
    setUserId(userId)

    onOpenDeleteUser()
  }

  const {
    isOpen: isOpenNewUser,
    onOpen: onOpenNewUser,
    onClose: onCloseNewUser,
  } = useDisclosure()

  const onCreateUser = (value: Invitation) => {
    onCloseNewUser()
    props.viewModel.createInvitation(value)
  }

  const newUserProps = {
    isOpen: isOpenNewUser,
    onClose: onCloseNewUser,
    onOpen: onOpenNewUser,
    onSubmit: onCreateUser,
  }

  const {
    isOpen: isOpenEditUser,
    onOpen: onOpenEditUser,
    onClose: onCloseEditUser,
  } = useDisclosure()

  const onUpdateUser = (values: User) => {
    onCloseEditUser()

    if (typeof props.viewModel.currentUser != 'undefined') {
      props.viewModel.updateUser(props.viewModel.currentUser._id, values.role)
    }
  }

  const editUserProps = {
    isOpen: isOpenNewUser,
    onClose: onCloseEditUser,
    onOpen: onOpenEditUser,
    onSubmit: onUpdateUser,
  }

  const onEditUser = (event: unknown, user: Member) => {
    props.viewModel.currentUser = user

    onOpenEditUser()
  }

  const [globalFilter, setGlobalFilter] = React.useState('')
  const columns = props.viewModel.tableColumns(
    t,
    onDeleteInvitation,
    onDeleteUser,
    onEditUser
  )
  const data = props.viewModel.tableData

  const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <Box
      id="pw-account-team"
      as="section"
      bg="bg-surface"
      p={{ base: '4', md: '8' }}
      w="100%"
    >
      <Container maxW="full">
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Stack
            spacing="4"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text color="muted">
                {props.viewModel.users.length == 0
                  ? t('users.index.header.usersEmpty')
                  : t('users.index.header.usersWithCount', {
                      count: props.viewModel.users.length,
                    })}
                {props.viewModel.invitations.length == 0
                  ? t('users.index.header.invitationsEmpty')
                  : t('users.index.header.invitationsWithCount', {
                      count: props.viewModel.invitations.length,
                    })}
              </Text>
            </Stack>
            <Stack direction="row" spacing="3">
              <Button
                className="instance-guide-0"
                variant="primary"
                onClick={onOpenNewUser}
              >
                {t('users.index.header.buttonLabel')}
              </Button>
            </Stack>
          </Stack>
        </Skeleton>
      </Container>
      <Container
        maxW="full"
        py={{ base: '4', md: '8' }}
        px={{ base: '0', md: 8 }}
      >
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Box
            bg="bg-surface"
            boxShadow={{
              base: 'none',
              md: colorModeValue,
            }}
            borderRadius={breakpointValue}
          >
            <Stack spacing="5">
              <Box px={{ base: '4', md: '6' }} pt="5">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  justify="space-between"
                >
                  <Text fontSize="lg" fontWeight="medium">
                    {t('instances.index.table.title')}
                  </Text>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search members"
                      value={globalFilter}
                      onChange={event =>
                        setGlobalFilter(String(event.target.value))
                      }
                    />
                  </InputGroup>
                </Stack>
              </Box>
              <Box overflowX="auto">
                <Table>
                  <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = header.column.columnDef.meta
                          return (
                            <Th
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              isNumeric={meta?.isNumeric}
                            >
                              <Flex direction={'row'}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                                <Spacer />
                                <chakra.span pl="4">
                                  {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === 'desc' ? (
                                      <Icon
                                        aria-label="sorted descending"
                                        as={IoArrowDown}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    ) : (
                                      <Icon
                                        aria-label="sorted ascending"
                                        as={IoArrowUp}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    )
                                  ) : null}
                                </chakra.span>
                              </Flex>
                            </Th>
                          )
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map(row => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = cell.column.columnDef.meta
                          return (
                            <Td key={cell.id} isNumeric={meta?.isNumeric}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          )
                        })}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Stack>
          </Box>
          <Alert
            onClose={onCloseDeleteUser}
            onConfirm={onConfirmDeleteUser}
            onOpen={onOpenDeleteUser}
            isOpen={isOpenDeleteUser}
            body="users.index.table.actions.delete.user.alert.body"
            confirm="users.index.table.actions.delete.user.alert.confirm"
            dismiss="users.index.table.actions.delete.user.alert.dismiss"
            header="users.index.table.actions.delete.user.alert.header"
          />
          <Alert
            onClose={onCloseDeleteInvitation}
            onConfirm={onConfirmDeleteInvitation}
            onOpen={onOpenDeleteInvitation}
            isOpen={isOpenDeleteInvitation}
            body="users.index.table.actions.delete.invitation.alert.body"
            confirm="users.index.table.actions.delete.invitation.alert.confirm"
            dismiss="users.index.table.actions.delete.invitation.alert.dismiss"
            header="users.index.table.actions.delete.invitation.alert.header"
          />
        </Skeleton>
      </Container>
      <Drawer isOpen={isOpenNewUser} onClose={onCloseNewUser} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <NewUser {...newUserProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer isOpen={isOpenEditUser} onClose={onCloseEditUser} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <EditUser {...editUserProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
})
