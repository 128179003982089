import { observer } from 'mobx-react-lite'
import React from 'react'

import { Center, Spinner as ChakraSpinner, Container } from '@chakra-ui/react'

export const Spinner = observer(() => (
  <Container width="100%" height="100vh">
    <Center height="full">
      <ChakraSpinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  </Container>
))
