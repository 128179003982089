import { computed, makeObservable, observable } from 'mobx'

import { Stripe, StripeElements } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

import { Plan } from '../../@types/account'
import { ApplicationError } from '../../@types/application'
import { CognitoSession } from '../../@types/external'
import { ApplicationConstants as Constants } from '../../constants'
import { AccountStore } from '../../stores/accountStore'
import { AuthStore } from '../../stores/authStore'

export class AccountSettingsViewModel {
  accountStore: AccountStore
  authStore: AuthStore
  page = 'settings'
  _error: ApplicationError | undefined
  _stripePromise: Promise<Stripe | null> | undefined = undefined

  constructor(accountStore: AccountStore, authStore: AuthStore) {
    this.accountStore = accountStore
    this.authStore = authStore

    makeObservable(this, {
      accountStore: observable,
      authStore: observable,
      _error: observable,
      clientSecret: computed,
      error: computed,
      planName: computed,
      confirmPaymentMethod: false,
      deleteAccount: false,
      editAccount: false,
      getAccount: false,
      page: false,
      stripePromise: false,
      _stripePromise: false,
    })
  }

  public get clientSecret(): string {
    return this.accountStore.stripe.clientSecret
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get planName(): string | undefined {
    return this.accountStore.plan
  }

  public get stripePromise(): Promise<Stripe | null> {
    if (typeof this._stripePromise == 'undefined') {
      this._stripePromise = loadStripe(Constants.STRIPE_API_KEY as string)
    }
    return this._stripePromise
  }

  public async confirmPaymentMethod(stripe: Stripe, elements: StripeElements) {
    await stripe
      .confirmSetup({
        confirmParams: {
          return_url: `${process.env.URI}/external/complete`,
        },
        elements,
      })
      .then(response => {
        const { error } = response
        if (error) {
          this.error = {
            message: error.message,
            type: 'application_error',
          }
        }
      })
      .catch(error => {
        this.error = {
          message: error.message,
          type: 'application_error',
        }
      })
  }

  public deleteAccount() {
    this.accountStore
      .deleteAccount()
      .then(() => {
        this.authStore.signOut()
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  public editAccount() {
    this.accountStore.editAccount()
  }

  public getAccount() {
    if (typeof this.accountStore.account == 'undefined') {
      const {
        idToken: {
          payload: { account: accountString, subscription },
        },
      } = this.authStore.session as CognitoSession

      const account = JSON.parse(accountString)
      const key = `PLANS${process.env.PLANS}` as keyof typeof Constants

      this.accountStore.account = {
        account: {
          awsAccountId: account.awsAccountId,
          id: account.id,
          organization: account.organization,
          status: account.status,
        },
        paymentMethod: {
          last4: undefined,
        },
        plans: Constants[key] as Plan[],
        subscription: JSON.parse(subscription as string),
      }
    } else {
      setTimeout(() => {
        this.accountStore.getAccount().catch((error: ApplicationError) => {
          this.error = error
        })
      }, 5000) // Wait for the Stripe hook to fire
    }
  }
}
