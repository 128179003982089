import { action, makeObservable, observable } from 'mobx'

import { RootStore } from '.'
import { ApplicationError } from '../@types/application'
import { ApplicationConstants as Constants } from '../constants'
import { InstanceService } from '../services/instanceService'
import { CognitoPayloadAccount, CognitoSession } from './../@types/external'
import {
  Instance,
  InstanceMember,
  InstanceUpdate,
  NewInstance,
  NewInstanceFormInput,
} from './../@types/instance'
import { BaseStore } from './baseStore'

export class InstanceStore extends BaseStore {
  rootStore: RootStore
  instances: Instance[] = []

  constructor(store: RootStore) {
    super()

    this.rootStore = store
    makeObservable(this, {
      createInstance: action,
      deleteInstance: action,
      deleteInstanceFromSession: action,
      getInstances: action,
      restoreInstance: action,
      shareInstance: action,
      setInstances: action,
      updateInstance: action,
      rootStore: observable,
      instances: observable,
      accountFromSession: false,
    })
  }

  async createInstance(values: NewInstanceFormInput): Promise<unknown> {
    const instance: NewInstance = {
      action: 'create',
      name: values.name,
      region: values.region,
      spaceType: values.spaceType,
      userId: null,
    }

    if (instance.spaceType == 'ec2') {
      instance.instanceType = values.instanceType
      instance.operatingSystem = values.operatingSystem
    }

    return this.rootStore.authStore.currentSession().then(session => {
      const {
        idToken: { payload },
        accessToken: { jwtToken },
      } = session as CognitoSession

      const { [Constants.COGNITO.USERNAME]: username } = payload

      instance.userId = username

      return InstanceService.create(instance, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.create.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async deleteInstance(id: string): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return InstanceService.delete(id, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.delete.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async deleteInstanceFromSession(session: string): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(currentSession => {
      const {
        accessToken: { jwtToken },
      } = currentSession as CognitoSession

      return InstanceService.deleteFromSession(session, jwtToken).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response)
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.instances.delete.error.message',
              })
              reject(error)
            }
          })
        }
      )
    })
  }

  async getInstances(): Promise<unknown> {
    const session = await this.rootStore.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    return await InstanceService.list(jwtToken).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.instances.get.error.message',
          })
          reject(error)
        }
      }).then((instances: unknown) => {
        this.setInstances(instances as Instance[])
      })
    })
  }

  async restoreInstance(id: string): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return InstanceService.restore(id, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.restore.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  setInstances(value: Instance[]) {
    this.instances = value
  }

  async shareInstance(id: string, values: InstanceMember[]): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return InstanceService.share(id, values, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.share.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async updateInstance(id: string, values: InstanceUpdate): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return InstanceService.update(id, values, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.update.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  accountFromSession(session: CognitoSession): CognitoPayloadAccount {
    const {
      idToken: {
        payload: { account: accountString },
      },
    } = session as CognitoSession

    return JSON.parse(accountString)
  }
}
