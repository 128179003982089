import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, useToast } from '@chakra-ui/react'

import { AccountDelete } from '../../../components/Account/AccountDelete'
import { AccountSettingsViewProps } from './../../@types/account'

export const AccountSettings = observer((props: AccountSettingsViewProps) => {
  // After redirecting from /external/complete
  // window.location.search = ''

  const { t } = useTranslation()
  const toast = useToast()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error?.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
  }, [props.viewModel.error])

  return (
    <Container maxW="full" id="pw-account-settings">
      <AccountDelete {...props} />
    </Container>
  )
})
