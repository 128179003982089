import { observer } from 'mobx-react-lite'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircleOutline, IoCloseCircleOutline } from 'react-icons/io5'
import { MdAddCircle } from 'react-icons/md'

import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Stack,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useBreakpointValue,
} from '@chakra-ui/react'

import { DocumentTag } from './../../../@types/document'
import { ModalProps, NewDocumentFormInput } from './../../../@types/document'

export const NewDocument = observer((props: ModalProps) => {
  const {
    clearErrors,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<NewDocumentFormInput>({
    defaultValues: {
      tags: [],
    },
  })
  const { t } = useTranslation()
  const watchFrequency = watch('onBuild', false)

  const toSnakeCase = (value: string) =>
    value
      .replace(/([a-z0-9])([A-Z0-9])/g, '$1-$2')
      .replace(/[\s_]+/g, '_')
      .toLowerCase()

  const [label, setLabel] = React.useState('')
  const [tags, setTags] = React.useState([])
  const [willCreateTag, setWillCreateTag] = React.useState(false)
  const necessaryTags = [
    {
      id: 'amzn_linux_2023',
      label: 'Amazon Linux 2023',
    },
    {
      id: 'ubuntu_22_04_lts',
      label: 'Ubuntu 22.04 LTS',
    },
  ]

  React.useEffect(() => {
    if (
      tags.filter(tag =>
        ['amzn_linux_2023', 'ubuntu_22_04_lts'].includes(tag.id)
      ).length == 0
    ) {
      setError('tags', {
        message: 'An operating system tag is required',
        type: 'string',
      })
    } else {
      clearErrors('tags')
    }
  }, [tags])

  const addTag = (tagId: string) => {
    necessaryTags.forEach(tag => {
      if (tag.id == tagId) {
        const newTags = [...tags, tag]
        setTags(newTags)
        setValue('tags', newTags)
      }
    })
  }

  const removeTag = (tagId: string) => {
    const newTags = tags.filter(tag => tag.id != tagId)
    setTags(newTags)
    setValue('tags', newTags)
  }

  const onCreateTag = () => {
    setWillCreateTag(true)
  }

  const onAddTag = () => {
    const tagId = toSnakeCase(label)
    const tagExists = tags?.filter(tag => tag.id === tagId)

    if (tagExists?.length > 0) {
      return
    }

    if (label.length > 1) {
      const newTags = [...tags, { id: tagId, label: label }]
      setTags(newTags)
      setValue('tags', newTags)

      setWillCreateTag(false)
      setLabel('')
    }
  }

  const onCancelTag = () => {
    setWillCreateTag(false)
    setLabel('')
  }

  const onEnterTag = event => {
    if (event.key == 'Enter') {
      onAddTag()
      event.preventDefault()
    }
  }

  const onInputTag = event => {
    setLabel(event.target.value)
  }

  return (
    <Box id="pw-new-document">
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: '4', md: '8' }}
        pb={{ base: '12', md: '24' }}
      >
        <Container maxW="full">
          <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {t('documents.new.header.title')}
              </Heading>
              <Text color="muted">{t('documents.new.header.subheading')}</Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container maxW="full">
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name" variant="floating" size="lg">
                  {t('documents.new.form.name.label')}
                </FormLabel>
                <Input
                  id="name"
                  size="lg"
                  placeholder=" "
                  {...register('name', {
                    required: t('documents.new.form.name.required') as string,
                    minLength: {
                      value: 3,
                      message: t('documents.new.form.name.minLength') as string,
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.content}>
                <FormLabel>{t('documents.new.form.commands.label')}</FormLabel>
                <Input
                  id="content"
                  as={Textarea}
                  {...register('content', {
                    required: t(
                      'documents.new.form.content.required'
                    ) as string,
                  })}
                />
                <FormErrorMessage>
                  {errors.content && errors.content.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.onBuild}>
                <FormLabel>{t('documents.new.form.onBuild.label')}</FormLabel>
                <HStack>
                  <Switch id="onBuild" size="lg" {...register('onBuild')} />
                  <Text>
                    {watchFrequency
                      ? t(`documents.new.form.onBuild.option.onBuild`)
                      : t(`documents.new.form.onBuild.option.onDemand`)}
                  </Text>
                </HStack>
                <FormErrorMessage>
                  {errors.onBuild && errors.onBuild.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.tags}>
                <FormLabel>
                  {t('documents.new.form.tags.label')}
                  <Text fontSize="sm" fontWeight="normal">
                    {t(`documents.new.form.tags.description`)}
                  </Text>
                </FormLabel>
                {tags.map((tag: DocumentTag) => {
                  return (
                    <Tag key={tag.id} mr={2} size="md">
                      <TagLabel>{tag.label}</TagLabel>
                      <TagCloseButton onClick={() => removeTag(tag.id)} />
                    </Tag>
                  )
                })}
                <FormErrorMessage>
                  {errors.tags && errors.tags.message}
                </FormErrorMessage>
              </FormControl>
              <HStack>
                {necessaryTags.map(tag => {
                  return (
                    <Tag
                      bgColor="gray.600"
                      color="white"
                      key={tag.id}
                      size="md"
                    >
                      <TagLeftIcon
                        as={AddIcon}
                        cursor="pointer"
                        onClick={() => addTag(tag.id)}
                      />
                      <TagLabel cursor="default">{tag.label}</TagLabel>
                    </Tag>
                  )
                })}
                <IconButton
                  aria-label="Create tag"
                  as={MdAddCircle}
                  bg="none"
                  color="gray.700"
                  cursor="pointer"
                  height={6}
                  hidden={willCreateTag}
                  onClick={onCreateTag}
                  _hover={{ bg: 'none' }}
                />
                <HStack hidden={!willCreateTag} spacing={1}>
                  <Input
                    onChange={onInputTag}
                    onKeyDown={onEnterTag}
                    placeholder={
                      t(`documents.new.form.tags.placeholder`) as string
                    }
                    value={label}
                  />
                  <IconButton
                    aria-label="Create tag"
                    as={IoCheckmarkCircleOutline}
                    bg="none"
                    color="gray.700"
                    cursor="pointer"
                    height={6}
                    minWidth={6}
                    onClick={onAddTag}
                    _hover={{ bg: 'none' }}
                  />
                  <IconButton
                    aria-label="Cancel tag"
                    as={IoCloseCircleOutline}
                    bg="none"
                    color="gray.700"
                    cursor="pointer"
                    height={6}
                    minWidth={6}
                    onClick={onCancelTag}
                    _hover={{ bg: 'none' }}
                  />
                </HStack>
              </HStack>
              <ButtonGroup alignSelf="end">
                <Button variant="secondary" onClick={props.onClose}>
                  {t('documents.new.form.action.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('documents.new.form.action.submit')}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </Container>
      </Box>
    </Box>
  )
})
