import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Box, Divider } from '@chakra-ui/react'

import { BlogProps } from '../app/@types/blog'
import { Footer, Header, NavBar, Paragraph } from '../components/Blog'

export const EnterprisePage = observer((props: BlogProps) => {
  return (
    <>
      <NavBar {...props} />
      <Header {...props} index={0} />
      <Paragraph {...props} index={0} />
      <Paragraph {...props} index={1} />
      <Paragraph {...props} index={2} />
      <Paragraph {...props} index={3} linked={true} />
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '16', md: '24' }}
      >
        <Divider borderColor="gray.700" />
      </Box>
      <Paragraph {...props} index={4} linked={true} />
      <Header primaryLink="/auth/signup" {...props} index={1} />
      <Footer {...props} />
    </>
  )
})
