import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FiSearch } from 'react-icons/fi'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'

import {
  Box,
  Container,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { AccountBillingViewProps, Download } from '../../app/@types/account'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const Invoices = observer((props: AccountBillingViewProps) => {
  const { t } = useTranslation()

  const breakpointValue = useBreakpointValue({ base: true, md: false })
  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDownloadInvoice = (event: unknown, invoice: Download) => {
    props.viewModel.downloadInvoice(invoice)
  }

  const [globalFilter, setGlobalFilter] = React.useState('')
  const columns = props.viewModel.tableColumns(t, onDownloadInvoice)
  const data = props.viewModel.tableData

  const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <Box as="section" bg="bg-surface" py={{ base: '4', md: '8' }}>
      <Container maxW="full">
        <Stack spacing="5">
          <Skeleton isLoaded={!props.viewModel.isLoading}>
            <Stack
              spacing="4"
              direction={{ base: 'column', sm: 'row' }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  {t('accounts.billing.invoices.title')}
                </Text>
                <Text fontSize="sm" color="muted">
                  {t('accounts.billing.invoices.description')}
                </Text>
              </Box>
            </Stack>
          </Skeleton>
        </Stack>
      </Container>
      <Container
        maxW="full"
        py={{ base: '4', md: '8' }}
        px={{ base: '0', md: 8 }}
      >
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Box
            bg="bg-surface"
            boxShadow={{
              base: 'none',
              md: colorModeValue,
            }}
            borderRadius={breakpointValue}
          >
            <Stack spacing="5">
              <Box px={{ base: '4', md: '6' }} pt="5">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  justify="space-between"
                >
                  <Text fontSize="lg" fontWeight="medium">
                    {t('accounts.billing.invoices.table.title')}
                  </Text>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search"
                      value={globalFilter}
                      onChange={event =>
                        setGlobalFilter(String(event.target.value))
                      }
                    />
                  </InputGroup>
                </Stack>
              </Box>
              <Box overflowX="auto">
                <Table>
                  <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = header.column.columnDef.meta
                          return (
                            <Th
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              isNumeric={meta?.isNumeric}
                            >
                              <Flex direction={'row'}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                                <Spacer />
                                <chakra.span pl="4">
                                  {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === 'desc' ? (
                                      <Icon
                                        aria-label="sorted descending"
                                        as={IoArrowDown}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    ) : (
                                      <Icon
                                        aria-label="sorted ascending"
                                        as={IoArrowUp}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    )
                                  ) : null}
                                </chakra.span>
                              </Flex>
                            </Th>
                          )
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map(row => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = cell.column.columnDef.meta
                          return (
                            <Td key={cell.id} isNumeric={meta?.isNumeric}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          )
                        })}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Stack>
          </Box>
        </Skeleton>
      </Container>
    </Box>
  )
})
