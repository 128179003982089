import { v4 as uuidv4 } from 'uuid'

import { ApplicationConstants as Constants } from './../constants'

type IWatchService = {
  watchOrganization: (accountId: string, token: string) => Promise<Response>
}

class WatchServiceClass implements IWatchService {
  async watchOrganization(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }
}

export const WatchService = new WatchServiceClass()
