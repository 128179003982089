import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useNavigate } from '@tanstack/react-location'

import { AccountBillingViewProps } from '../../app/@types/account'

type StripeElementProps = {
  onSubmit: (event: React.SyntheticEvent<EventTarget>) => void
}

type PaymentDetailsProps = StripeElementProps & AccountBillingViewProps

const PaymentDetails = observer((props: PaymentDetailsProps) => {
  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  return (
    <Box
      as="form"
      bg="bg-surface"
      boxShadow={colorModeValue}
      p={4}
      borderRadius="lg"
      onSubmit={props.onSubmit}
      width="100%"
    >
      <PaymentElement />
    </Box>
  )
})

export const AccountPayment = observer((props: AccountBillingViewProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleSubmit = async (event: React.SyntheticEvent<EventTarget>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    props.viewModel.confirmPaymentMethod(stripe, elements, navigate)
  }

  return (
    <Container maxW="full" py={{ base: '4', md: '8' }}>
      <Stack spacing="5">
        <Stack
          direction={{ base: 'column', lg: 'column' }}
          spacing={{ base: '5', lg: '8' }}
          justify="space-between"
        >
          <Box flexShrink={0}>
            <Text fontSize="lg" fontWeight="medium">
              {t(`accounts.billing.new.payment.title`)}
            </Text>
            <Text color="muted" fontSize="sm">
              {t(`accounts.billing.new.payment.description`)}
            </Text>
          </Box>
          {props.viewModel.clientSecret && (
            <PaymentDetails {...props} onSubmit={handleSubmit} />
          )}
        </Stack>
        <Stack direction="row" align="right" justify="flex-end">
          <Button
            onClick={() => props.onStep && props.onStep(1)}
            variant="ghost"
          >
            {t(`accounts.billing.new.payment.actions.secondary`)}
          </Button>
          <Button onClick={handleSubmit} variant="primary">
            {t(`accounts.billing.new.payment.actions.primary`)}
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
})
