import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Heading,
  Link,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { PosterProps } from '../../app/@types/poster'

export const Faqs = observer((props: PosterProps) => {
  const { t } = useTranslation()
  const faqs = []

  for (let i = 0; i < props.viewModel.faqLength; i++) {
    faqs.push({
      question: `posters.common.faq.questions.${i}.question`,
      answer: `posters.common.faq.questions.${i}.answer`,
      linked: i == 0 ? true : false,
    })
  }

  return (
    <Box as="section">
      <Container
        px={{ base: '16', md: '24' }}
        py={{ base: '16', md: '24' }}
        maxW="5xl"
      >
        <Stack spacing={{ base: '4', md: '5' }} align="center">
          <Heading fontSize={useBreakpointValue({ base: '2xl', md: '5xl' })}>
            {t(`posters.common.faq.heading`)}
          </Heading>
          <Text fontSize={{ base: 'lg', md: '2xl' }}>
            Have another question? Contact us at{' '}
            <Link
              href="mailto:support@pairspaces.com"
              textDecoration={'underline'}
            >
              support@pairspaces.com
            </Link>
            .
          </Text>
          <Accordion width="100%">
            {faqs.map(faq => (
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize={{ base: 'lg', md: '2xl' }}
                      fontWeight="semibold"
                    >
                      {t(faq.question)}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize={{ base: 'md', md: 'lg' }}>
                  {faq.linked ? (
                    <Trans i18nKey="faq.answer">
                      {t(faq.answer)}
                      <Link textDecoration={'underline'}></Link>
                    </Trans>
                  ) : (
                    (t(faq.answer) as string)
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Stack>
      </Container>
    </Box>
  )
})
