import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { AuthViewModel } from '../../viewModels/Auth'
import { Callback, SignIn, SignUp } from '../../views/Auth'

export const CallbackProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AuthViewModel(
    store.accountStore,
    store.applicationStore,
    store.authStore
  )

  return <Callback viewModel={viewModel} />
})

export const SignInProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AuthViewModel(
    store.accountStore,
    store.applicationStore,
    store.authStore
  )
  viewModel.applicationStore.featureFlags()

  return <SignIn viewModel={viewModel} />
})

export const SignUpProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AuthViewModel(
    store.accountStore,
    store.applicationStore,
    store.authStore
  )
  viewModel.applicationStore.featureFlags()

  return <SignUp viewModel={viewModel} />
})
