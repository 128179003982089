import { v4 as uuidv4 } from 'uuid'

import { NewRepositoryRequest } from '../@types/repository'
import { ApplicationConstants as Constants } from '../constants'

export type IRepositoryService = {
  create: (body: NewRepositoryRequest, token: string) => Promise<Response>
  delete: (id: string, token: string) => Promise<Response>
  list: (token: string) => Promise<Response>
}

class RepositoryServiceClass implements IRepositoryService {
  async create(
    repository: NewRepositoryRequest,
    token: string
  ): Promise<Response> {
    const body = {
      repository,
    }
    return fetch(`${Constants.API_URI}/repositories`, {
      method: Constants.HTTP_METHODS.POST,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async delete(id: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/repositories/${id}`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async list(token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/repositories`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }
}

export const RepositoryService = new RepositoryServiceClass()
