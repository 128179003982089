import { action, computed, makeObservable, observable } from 'mobx'

import { ApplicationError } from '../../@types/application'
import { Step } from '../../@types/session'
import { SessionService } from '../../services/sessionService'
import { AuthStore } from '../../stores/authStore'

export class SessionViewModel {
  authStore: AuthStore
  _error: ApplicationError | undefined
  _isAuthenticated = false
  _nextStep: number
  _isLoading = false
  _message = 'sessions.delete.session'
  _steps: Step[] = [
    {
      title: 'sessions.delete.steps.0.title',
      description: '',
    },
    {
      title: 'sessions.delete.steps.1.title',
      description: '',
    },
    {
      title: 'sessions.delete.steps.2.title',
      description: '',
    },
  ]

  constructor(store: AuthStore) {
    this.authStore = store
    this._nextStep = 0
    makeObservable(this, {
      authenticate: action,
      error: computed,
      isAuthenticated: computed,
      isLoading: computed,
      nextStep: computed,
      steps: computed,
      _error: observable,
      _isAuthenticated: observable,
      _isLoading: observable,
      _nextStep: observable,
      _steps: observable,
      _message: observable,
      authStore: false,
      deleteCredentials: false,
      deleteSession: false,
    })
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated
  }

  set isAuthenticated(value: boolean) {
    this._isAuthenticated = value
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  get nextStep(): number {
    return this._nextStep
  }

  set nextStep(value: number) {
    this._nextStep = value
  }

  get steps(): Step[] {
    return this._steps
  }

  public authenticate() {
    this.authStore.currentSession().then(session => {
      if (session) {
        this.isAuthenticated = true
      } else {
        this.isAuthenticated = false
      }

      this.isLoading = false
    })
  }

  public deleteCredentials(session: string) {
    SessionService.deleteCredentials(session)
      .then(() => {
        this.isLoading = false
        this.nextStep += 1
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  public deleteSession(session: string) {
    SessionService.deleteSession(session)
      .then(() => {
        this.isLoading = false
        this.nextStep += 1
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  // public sessionIdFromSession(session: string) {
  //   const decodedSession = this.decodeSession(session)
  //   const sessionData = JSON.parse(decodedSession)
  //   return sessionData
  // }

  // decodeSession(session: string) {
  //   const padding = Array(((4 - (session.length % 4)) % 4) + 1).join('=')
  //   const encryptedMessage = session + padding
  //   return Buffer.from(encryptedMessage, 'base64').toString('utf8')
  // }
}
