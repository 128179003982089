import { action, computed, makeObservable, observable } from 'mobx'

import { ApplicationError } from '../@types/application'
import {
  CognitoSession,
  CognitoSignUpResponse,
  isCognitoSignUpResponse,
} from '../@types/external'
import { AuthService } from './../services/authService'
import { BaseStore } from './baseStore'

export class AuthStore extends BaseStore {
  _isAuthenticated = false
  _redirectPath: string | undefined
  _session: CognitoSession | unknown
  _user: Record<string, unknown> | unknown

  constructor() {
    super()

    makeObservable(this, {
      confirmSignIn: action,
      signIn: action,
      signOut: action,
      signUp: action,
      signUpBitbucket: action,
      signUpGitHub: action,
      signUpGitLab: action,
      signUpGoogle: action,
      isAuthenticated: computed,
      redirectPath: computed,
      scope: computed,
      session: computed,
      user: computed,
      _user: observable,
      _isAuthenticated: observable,
      _redirectPath: observable,
      currentSession: false,
      _session: false,
    })
  }

  public currentSession(): Promise<unknown> {
    return AuthService.currentSession()
      .then(session => {
        this.isAuthenticated = true
        this.session = session as CognitoSession

        return new Promise((resolve, reject) => {
          if (session) {
            resolve(session)
          } else {
            reject()
          }
        })
      })
      .catch(() => {
        this.isAuthenticated = false
      })
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated
  }

  public set isAuthenticated(value: boolean) {
    this._isAuthenticated = value
  }

  public get redirectPath(): string | undefined {
    return this._redirectPath
  }

  set redirectPath(value: string | undefined) {
    this._redirectPath = value
  }

  get scope(): string {
    return this.session.idToken.payload['cognito:groups'][0]
  }

  get session(): CognitoSession {
    return this._session as CognitoSession
  }

  set session(value: CognitoSession) {
    this._session = value
  }

  get user(): Record<string, unknown> | unknown {
    return this._user
  }

  set user(value: unknown) {
    this._user = value
  }

  async confirmSignIn(code: string): Promise<unknown> {
    return AuthService.confirmSignIn(this.user, code).then(response => {
      return new Promise((resolve, reject) => {
        const session = response.signInUserSession
        if (session) {
          this.session = session as CognitoSession
          this.isAuthenticated = true
          resolve(session)
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.auth.confirm.error.message',
          })
          reject(error)
        }
      })
    })
  }

  async signIn(email: string): Promise<unknown> {
    return AuthService.signIn(email).then(response => {
      return new Promise((resolve, reject) => {
        if (response.username) {
          this.user = response
          resolve(response)
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.auth.signIn.error.message',
          })
          reject(error)
        }
      })
      // .catch(error => {
      //   return new Promise((resolve, reject) => {
      //     reject(error)
      //   })
      // })
    })
  }

  async signOut(): Promise<unknown> {
    return AuthService.signOut().then(() => {
      this.isAuthenticated = false
      this.user = undefined
    })
  }

  async signUp(email: string, options: object): Promise<unknown> {
    return AuthService.signUp(email, options).then(response => {
      return new Promise((resolve, reject) => {
        if (isCognitoSignUpResponse(response)) {
          resolve(response)
        } else {
          const error: ApplicationError = this._errorFor(
            response as CognitoSignUpResponse,
            {
              message: 'requests.auth.signUp.error.message',
            }
          )
          reject(error)
        }
      })
    })
  }

  async signUpBitbucket(): Promise<unknown> {
    return AuthService.signUpBitbucket()
  }

  async signUpGitHub(): Promise<unknown> {
    return AuthService.signUpGitHub()
  }

  async signUpGitLab(): Promise<unknown> {
    return AuthService.signUpGitLab()
  }

  async signUpGoogle(): Promise<unknown> {
    return AuthService.signUpGoogle()
  }
}
