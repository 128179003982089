import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FaBitbucket, FaGithub, FaGitlab } from 'react-icons/fa'
import { FiSearch } from 'react-icons/fi'
import { IoMdCopy } from 'react-icons/io'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  chakra,
  useBreakpointValue,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {
  NewRepositoryFormInput,
  NewRepositoryRequest,
  RepositoryViewProps,
} from '../../@types/repository'
import { Alert } from './../../../components/AlertDialog'
import { NewRepository } from './NewRepository'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const IndexRepositories = observer((props: RepositoryViewProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
    if (props.viewModel.notice) {
      toast({
        description: t(props.viewModel.notice.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'success',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.notice = undefined
        },
      })
    }
  }, [props.viewModel.error, props.viewModel.notice])

  React.useEffect(() => {
    props.viewModel.getRepositories()
    props.viewModel.readIdentities()
  }, [])

  const breakpointValue = useBreakpointValue({ base: true, md: false })
  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  const [repositoryId, setRepositoryId] = React.useState('')

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmDelete = (event: unknown) => {
    onCloseDelete()
    props.viewModel.deleteRepository(repositoryId)

    setRepositoryId('')
  }

  const onDeleteRepository = (event: unknown, instanceId: string) => {
    setRepositoryId(instanceId)

    onOpenDelete()
  }

  const {
    isOpen: isOpenNewRepository,
    onOpen: onOpenNewRepository,
    onClose: onCloseNewRepository,
  } = useDisclosure()

  const onCreateRepository = (values: NewRepositoryFormInput) => {
    onCloseNewRepository()

    const url = values.url
    let provider = 'github'
    if (url.includes('bitbucket')) {
      provider = 'bitbucket'
    } else if (url.includes('gitlab')) {
      provider = 'gitlab'
    }

    if (typeof values.parentId == 'undefined') {
      values.parentId = '-'
    }

    const request: NewRepositoryRequest = {
      commands: [],
      id: values.id,
      name: values.name,
      operatingSystem: values.operatingSystem,
      parent: {
        id: values.parentId,
        name: values.parentName,
      },
      provider: provider,
      region: values.region,
    }

    props.viewModel.createRepository(request)
  }

  const newRepositoryProps = {
    isOpen: isOpenNewRepository,
    onClose: onCloseNewRepository,
    onOpen: onOpenNewRepository,
    onSubmit: onCreateRepository,
  }

  const onCopyRegion = useClipboard('')
  const onCopyRole = useClipboard('')
  const copyRole = () => {
    window.navigator.clipboard.writeText(
      `arn:aws:iam::${props.viewModel.account?.account.awsAccountId}:role/${props.viewModel.account?.account.id}`
    )
    onCopyRole.onCopy()
  }
  const copyRegion = (event: unknown, region: string) => {
    window.navigator.clipboard.writeText(region)
    onCopyRegion.onCopy()
  }

  const [globalFilter, setGlobalFilter] = React.useState('')
  const columns = props.viewModel.tableColumns(
    t,
    copyRegion,
    onDeleteRepository
  )
  const data = props.viewModel.tableData

  const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    usePagination: true,
  })

  return (
    <Box
      as="section"
      bg="bg-surface"
      p={{ base: '4', md: '8' }}
      w="100%"
      maxW="full"
      id="pw-repos"
    >
      {props.viewModel.identities.length == 0 ? (
        <>
          <Container
            py={{ base: '4', md: '8' }}
            px={{ base: '0', md: 8 }}
            maxW="5xl"
          >
            <Stack spacing={12}>
              <Text color="muted">
                {t('repositories.index.connect.header')}
              </Text>
              <Center>
                <Stack maxW="md">
                  <Button
                    cursor="pointer"
                    iconSpacing="3"
                    isLoading={
                      props.viewModel.isGitHub && props.viewModel.isLoading
                    }
                    leftIcon={<FaGithub boxSize="5" />}
                    onClick={() => props.viewModel.connectGitHub()}
                    opacity={
                      props.viewModel.isBitbucket || props.viewModel.isGitLab
                        ? 0
                        : 100
                    }
                    type="submit"
                    variant="secondary"
                  >
                    {t('repositories.index.connect.github.buttonLabel')}
                  </Button>
                  <Button
                    cursor="pointer"
                    iconSpacing="3"
                    isLoading={
                      props.viewModel.isBitbucket && props.viewModel.isLoading
                    }
                    leftIcon={<FaBitbucket boxSize="5" />}
                    onClick={() => props.viewModel.connectBitbucket()}
                    opacity={
                      props.viewModel.isGitHub || props.viewModel.isGitLab
                        ? 0
                        : 100
                    }
                    type="submit"
                    variant="secondary"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    {t('repositories.index.connect.bitbucket.buttonLabel')}
                  </Button>
                  <Button
                    cursor="pointer"
                    iconSpacing="3"
                    isLoading={
                      props.viewModel.isGitLab && props.viewModel.isLoading
                    }
                    leftIcon={<FaGitlab boxSize="5" />}
                    onClick={() => props.viewModel.connectGitLab()}
                    opacity={
                      props.viewModel.isBitbucket || props.viewModel.isGitHub
                        ? 0
                        : 100
                    }
                    type="submit"
                    variant="secondary"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    {t('repositories.index.connect.gitlab.buttonLabel')}
                  </Button>
                </Stack>
              </Center>
            </Stack>
          </Container>
        </>
      ) : (
        <>
          <Container width="100%" height="100%" maxW="5xl">
            <Skeleton isLoaded={!props.viewModel.isLoading}>
              <Stack
                spacing="4"
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text color="muted">
                    {!props.viewModel.repositories
                      ? t('repositories.index.header.empty')
                      : t('repositories.index.header.linkedWithCount', {
                          count: props.viewModel.repositories.length,
                        })}
                    {t('repositories.index.header.action.prefix')}
                    <code>
                      {t('repositories.index.header.action.role', {
                        accountId: props.viewModel.account?.account.id,
                        awsAccountId:
                          props.viewModel.account?.account.awsAccountId,
                      })}
                    </code>
                    <IconButton
                      aria-label="Copy AWS Role"
                      as={IoMdCopy}
                      onClick={copyRole}
                      minWidth={3}
                      width={4}
                      height={5}
                      background="none"
                      cursor="pointer"
                    />
                    {t('repositories.index.header.action.suffix')}
                  </Text>
                </Stack>
                <Stack direction="row" spacing="3">
                  <Button variant="primary" onClick={onOpenNewRepository}>
                    {t('repositories.index.header.buttonLabel')}
                  </Button>
                </Stack>
              </Stack>
            </Skeleton>
          </Container>
          <Container
            py={{ base: '4', md: '8' }}
            px={{ base: '0', md: 8 }}
            maxW="5xl"
          >
            <Skeleton isLoaded={!props.viewModel.isLoading}>
              <Box
                bg="bg-surface"
                boxShadow={{
                  base: 'none',
                  md: colorModeValue,
                }}
                borderRadius={breakpointValue}
              >
                <Stack spacing="6">
                  <Box px={{ base: '4', md: '6' }} pt="5">
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      justify="space-between"
                    >
                      <Text fontSize="lg" fontWeight="medium">
                        {t('instances.index.table.title')}
                      </Text>
                      <InputGroup maxW="xs">
                        <InputLeftElement pointerEvents="none">
                          <Icon as={FiSearch} color="muted" boxSize="5" />
                        </InputLeftElement>
                        <Input
                          placeholder="Search repositories"
                          value={globalFilter}
                          onChange={event =>
                            setGlobalFilter(String(event.target.value))
                          }
                        />
                      </InputGroup>
                    </Stack>
                  </Box>
                  <Box overflowX="auto">
                    <Table>
                      <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                          <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                              const meta: any = header.column.columnDef.meta
                              return (
                                <Th
                                  key={header.id}
                                  onClick={header.column.getToggleSortingHandler()}
                                  isNumeric={meta?.isNumeric}
                                >
                                  <Flex direction={'row'}>
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    <Spacer />
                                    <chakra.span pl="4">
                                      {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() ===
                                        'desc' ? (
                                          <Icon
                                            aria-label="sorted descending"
                                            as={IoArrowDown}
                                            color="muted"
                                            boxSize="4"
                                          />
                                        ) : (
                                          <Icon
                                            aria-label="sorted ascending"
                                            as={IoArrowUp}
                                            color="muted"
                                            boxSize="4"
                                          />
                                        )
                                      ) : null}
                                    </chakra.span>
                                  </Flex>
                                </Th>
                              )
                            })}
                          </Tr>
                        ))}
                      </Thead>
                      <Tbody>
                        {table.getRowModel().rows.map(row => (
                          <Tr key={row.id}>
                            {row.getVisibleCells().map(cell => {
                              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                              const meta: any = cell.column.columnDef.meta
                              return (
                                <Td key={cell.id} isNumeric={meta?.isNumeric}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Td>
                              )
                            })}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                  <Flex
                    alignItems="right"
                    justifyContent="space-between"
                    pb={4}
                  >
                    <Spacer />
                    <Tooltip label="Previous Page">
                      <IconButton
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        icon={<ChevronLeftIcon h={6} w={6} />}
                      />
                    </Tooltip>
                    <Flex alignItems="center">
                      <Text flexShrink="0" mx={4}>
                        Page{' '}
                        <Text fontWeight="bold" as="span">
                          {table.getState().pagination.pageIndex + 1}
                        </Text>{' '}
                        of{' '}
                        <Text fontWeight="bold" as="span">
                          {table.getPageCount()}
                        </Text>
                      </Text>
                    </Flex>
                    <Tooltip label="Next Page">
                      <IconButton
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        icon={<ChevronRightIcon h={6} w={6} />}
                      />
                    </Tooltip>
                    <Spacer />
                  </Flex>
                </Stack>
              </Box>
              <Alert
                onClose={onCloseDelete}
                onConfirm={onConfirmDelete}
                onOpen={onOpenDelete}
                isOpen={isOpenDelete}
                body="repositories.index.table.actions.delete.alert.body"
                confirm="repositories.index.table.actions.delete.alert.confirm"
                dismiss="repositories.index.table.actions.delete.alert.dismiss"
                header="repositories.index.table.actions.delete.alert.header"
              />
            </Skeleton>
          </Container>
          <Drawer
            isOpen={isOpenNewRepository}
            onClose={onCloseNewRepository}
            size="xl"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody>
                <NewRepository {...newRepositoryProps} {...props} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </Box>
  )
})
