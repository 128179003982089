import { configure } from 'mobx'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import { ChakraProvider } from '@chakra-ui/react'
import { Navigate, ReactLocation, Router } from '@tanstack/react-location'

import {
  AccountBillingProvider,
  AccountSettingsProvider,
  AccountTeamProvider,
} from './app/providers/Account'
import { ApplicationProvider } from './app/providers/Application'
import {
  CallbackProvider,
  SignInProvider,
  SignUpProvider,
} from './app/providers/Auth'
import {
  PrivateRouteProvider,
  ScrollProvider,
} from './app/providers/Components'
import { DocumentIndexProvider } from './app/providers/Document'
import {
  ExternalAuthenticateProvider,
  ExternalCompleteProvider,
  ExternalInvitationProvider,
  ExternalPairProvider,
} from './app/providers/External'
import { InstanceIndexProvider } from './app/providers/Instance'
import {
  AboutPageProvider,
  AgencyPageProvider,
  CalculatorPageProvider,
  CollaborationPageProvider,
  EnterprisePageProvider,
  LegalPrivacyPageProvider,
  LegalTermsPageProvider,
  PricingPageProvider,
  ProductivityPageProvider,
  SecurityPageProvider,
  StartupPageProvider,
} from './app/providers/Pages'
import {
  EmailPosterProvider,
  LandingPosterProvider,
} from './app/providers/Posters'
import { RepositoryIndexProvider } from './app/providers/Repository'
import { SessionDeleteProvider } from './app/providers/Session'
import { StoresProvider } from './hooks/useStores'
import { venvApplicationTheme, venvMarketingTheme } from './themes'

configure({
  computedRequiresReaction: true,
  disableErrorBoundaries: false,
  enforceActions: 'never', //for react-hook-form
  observableRequiresReaction: true,
  reactionRequiresObservable: true,
})

const location = new ReactLocation()

export const App = observer(() => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          http-equiv="Content-Security-Policy"
          content="
            default-src 'self' https:;
            connect-src 'self' https://27a136c6a5b244599408322b7d27af44.auth.us-east-1.amazoncognito.com https://97f1c97e387e4fb5ab22739aae14629a.auth.us-east-1.amazoncognito.com https://cognito-idp.us-east-1.amazonaws.com https://api.stripe.com https://fonts.googleapis.com https://fonts.gstatic.com https://maps.googleapis.com https://plausible.io https://*.pairspaces.com;
            frame-src 'self' https://js.stripe.com https://hooks.stripe.com;
            img-src 'self' https:;
            script-src 'self' https: https://js.stripe.com https://maps.googleapis.com 'sha256-RV6I4HWPb71LvA27WVD3cEz8GsJrHlfcM/2X2Q5gV00=';
            style-src 'self' https: 'unsafe-inline';"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap"
          rel="stylesheet"
        />
        <meta name="apple-mobile-web-app-title" content="PairSpaces" />
        <meta name="application-name" content="PairSpaces" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#f8f8f8" />
      </Helmet>

      <StoresProvider>
        <Router
          location={location}
          routes={[
            {
              path: '/',
              element: (
                <ChakraProvider theme={venvMarketingTheme}>
                  <ScrollProvider />
                  <Helmet>
                    <link rel="canonical" href="https://www.pairspaces.com" />
                    <link rel="stylesheet" href="themes/prism.css" />
                    <meta
                      name="description"
                      content="PairSpaces is for teams that work together."
                    ></meta>
                    <script
                      type="text/javascript"
                      src="leader-line.min.js"
                      defer
                    />
                    <script
                      type="text/javascript"
                      src="scripts/prism.js"
                      defer
                    />
                    <title>
                      The first time you can code together in real time. |
                      PairSpaces
                    </title>
                  </Helmet>
                  <LandingPosterProvider />
                </ChakraProvider>
              ),
            },
            {
              path: 'about',
              element: (
                <ChakraProvider theme={venvMarketingTheme}>
                  <ScrollProvider />
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.pairspaces.com/about"
                    />
                    <meta
                      name="description"
                      content="PairSpaces is for the teams that work together."
                    ></meta>
                    <title>Work together in the cloud | PairSpaces</title>
                  </Helmet>
                  <AboutPageProvider />
                </ChakraProvider>
              ),
            },
            {
              path: 'accounts',
              children: [
                {
                  path: ':accountId/invitations/:invitationId',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>Creating your Account | PairSpaces</title>
                      </Helmet>
                      <ExternalInvitationProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'billing',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>Billing | PairSpaces</title>
                      </Helmet>
                      <PrivateRouteProvider>
                        <ApplicationProvider>
                          <AccountBillingProvider />
                        </ApplicationProvider>
                      </PrivateRouteProvider>
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'settings',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>Settings | PairSpaces</title>
                      </Helmet>
                      <PrivateRouteProvider>
                        <ApplicationProvider>
                          <AccountSettingsProvider />
                        </ApplicationProvider>
                      </PrivateRouteProvider>
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'team',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>Team | PairSpaces</title>
                      </Helmet>
                      <PrivateRouteProvider>
                        <ApplicationProvider>
                          <AccountTeamProvider />
                        </ApplicationProvider>
                      </PrivateRouteProvider>
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'auth',
              children: [
                {
                  path: 'callback',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      {/* <Helmet>
                        <meta name="theme-color" content="#FFF" />
                        <title>Communicating with OAuth Provider | PairSpaces</title>
                      </Helmet> */}
                      <CallbackProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'signin',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <Helmet>
                        <meta name="theme-color" content="#FFF" />
                        <title>Sign in to PairSpaces | PairSpaces</title>
                      </Helmet>
                      <SignInProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'signup',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <Helmet>
                        <meta name="theme-color" content="#FFF" />
                        <title>Sign up to PairSpaces | PairSpaces</title>
                      </Helmet>
                      <SignUpProvider />
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'connect',
              element: (
                <ChakraProvider theme={venvMarketingTheme}>
                  <ScrollProvider />
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.pairspaces.com/connect"
                    />
                    <meta
                      name="description"
                      content="PairSpaces is for teams that work together."
                    ></meta>
                    <title>
                      The first time you can code together in real time. |
                      PairSpaces
                    </title>
                  </Helmet>
                  <EmailPosterProvider />
                </ChakraProvider>
              ),
            },
            {
              path: 'documents',
              children: [
                {
                  path: '/',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>
                          Create documents that configure your spaces |
                          PairSpaces
                        </title>
                      </Helmet>
                      <PrivateRouteProvider>
                        <ApplicationProvider>
                          <DocumentIndexProvider />
                        </ApplicationProvider>
                      </PrivateRouteProvider>
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'repos',
              children: [
                {
                  path: '/',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>
                          Connect repositories to spaces | PairSpaces
                        </title>
                      </Helmet>
                      <PrivateRouteProvider>
                        <ApplicationProvider>
                          <RepositoryIndexProvider />
                        </ApplicationProvider>
                      </PrivateRouteProvider>
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'external',
              children: [
                {
                  path: 'authenticate',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#FFF" />
                        <title>
                          Communicating with External Services | PairSpaces
                        </title>
                      </Helmet>
                      <ExternalAuthenticateProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'complete/:hash',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#FFF" />
                        <title>Completed Stripe Transaction | PairSpaces</title>
                      </Helmet>
                      <ExternalCompleteProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'complete',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#FFF" />
                        <title>Completed Stripe Transaction | PairSpaces</title>
                      </Helmet>
                      <ExternalCompleteProvider />
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'spaces',
              children: [
                {
                  path: '/',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>
                          Create, view, and manage spaces | PairSpaces
                        </title>
                      </Helmet>
                      <PrivateRouteProvider>
                        <ApplicationProvider>
                          <InstanceIndexProvider />
                        </ApplicationProvider>
                      </PrivateRouteProvider>
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'legal',
              children: [
                {
                  path: 'privacy',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/legal/privacy"
                        />
                        <meta
                          name="description"
                          content="PairSpaces is for teams that work together. Read our privacy policy to learn how we protect your data."
                        ></meta>
                        <title>Our Privacy Policy | PairSpaces</title>
                      </Helmet>
                      <LegalPrivacyPageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'terms',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/legal/terms"
                        />
                        <meta
                          name="description"
                          content="PairSpaces is for teams that work together. Read our terms to learn how we work."
                        ></meta>
                        <title>Our Terms | PairSpaces</title>
                      </Helmet>
                      <LegalTermsPageProvider />
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'pages',
              children: [
                {
                  path: 'collaboration',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/pages/collaboration"
                        />
                        <meta
                          name="description"
                          content="PairSpaces is the first time your team can collaborate in real-time."
                        ></meta>
                        <title>Collaborate in real-time | PairSpaces</title>
                      </Helmet>
                      <CollaborationPageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'pricing',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/pages/pricing"
                        />
                        <meta
                          name="description"
                          content="PairSpaces saves you time and money."
                        ></meta>
                        <title>Pricing | PairSpaces</title>
                      </Helmet>
                      <PricingPageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'productivity',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/pages/productivity"
                        />
                        <meta
                          name="description"
                          content="Teams that use PairSpaces build better software, faster."
                        ></meta>
                        <title>
                          Save days and thousands every year. | PairSpaces
                        </title>
                      </Helmet>
                      <ProductivityPageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'savings',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/pages/savings"
                        />
                        <meta
                          name="description"
                          content="PairSpaces saves you time and money."
                        ></meta>
                        <title>Estimate Your Savings | PairSpaces</title>
                      </Helmet>
                      <CalculatorPageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'security',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/pages/security"
                        />
                        <meta
                          name="description"
                          content="PairSpaces monitors security for you."
                        ></meta>
                        <title>Security | PairSpaces</title>
                      </Helmet>
                      <SecurityPageProvider />
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'sessions',
              children: [
                {
                  path: ':session',
                  element: (
                    <ChakraProvider theme={venvApplicationTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <meta name="theme-color" content="#F7FAFC" />
                        <title>Manage your session | PairSpaces</title>
                      </Helmet>
                      <SessionDeleteProvider />
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'solutions',
              children: [
                {
                  path: 'agencies',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/solutions/agencies"
                        />
                        <meta
                          name="description"
                          content="PairSpaces makes your team more agile, more collaborative, and more productive."
                        ></meta>
                        <title>Agencies | PairSpaces</title>
                      </Helmet>
                      <AgencyPageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'enterprise',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/solutions/enterprise"
                        />
                        <meta
                          name="description"
                          content="Increase productivity by 60X with PairSpaces."
                        ></meta>
                        <title>
                          Increase productivity by collaborating securely |
                          PairSpaces
                        </title>
                      </Helmet>
                      <EnterprisePageProvider />
                    </ChakraProvider>
                  ),
                },
                {
                  path: 'startups',
                  element: (
                    <ChakraProvider theme={venvMarketingTheme}>
                      <ScrollProvider />
                      <Helmet>
                        <link
                          rel="canonical"
                          href="https://www.pairspaces.com/solutions/startups"
                        />
                        <meta
                          name="description"
                          content="PairSpaces helps startups collaborate securely and ship features faster."
                        ></meta>
                        <title>Startups | PairSpaces</title>
                      </Helmet>
                      <StartupPageProvider />
                    </ChakraProvider>
                  ),
                },
              ],
            },
            {
              path: 'pair',
              element: (
                <ChakraProvider theme={venvMarketingTheme}>
                  <ScrollProvider />
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.pairspaces.com/pair"
                    />
                    <meta
                      name="description"
                      content="Connect your code and your team"
                    ></meta>
                    <title>Pair | PairSpaces</title>
                  </Helmet>
                  <ExternalPairProvider />
                </ChakraProvider>
              ),
            },
            {
              element: <Navigate to="/" replace={true} />,
            },
          ]}
        />
      </StoresProvider>
    </>
  )
})
