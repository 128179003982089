import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FiInfo } from 'react-icons/fi'

import {
  Flex,
  HStack,
  Icon,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'

import { AccountBillingViewProps } from '../../app/@types/account'
import { Feature } from './Feature'

type ProductFeaturesTableProps = Record<string, unknown> &
  AccountBillingViewProps &
  TableProps

export const ProductFeaturesTable = observer(
  (props: ProductFeaturesTableProps) => {
    const { viewModel, ...tableProps } = props
    const { t } = useTranslation()

    return (
      <>
        {viewModel.features.map((feature, featureId) => (
          <Table
            key={featureId}
            sx={{ tableLayout: 'fixed' }}
            variant="striped"
            {...tableProps}
          >
            <Thead>
              <Tr>
                <Th
                  colSpan={
                    viewModel.plans.slice(1, viewModel.plans.length).length + 1
                  }
                  color="accent"
                  fontSize="sm"
                >
                  {t(
                    `accounts.billing.change.pricing.categories.${feature.category}.name`
                  )}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {feature.items.map((item, id) => (
                <Tr key={id}>
                  <Td fontWeight="semibold">
                    <HStack spacing="1">
                      <Text fontWeight="semibold">
                        {t(
                          `accounts.billing.change.pricing.categories.${feature.category}.${item.name}.name`
                        )}
                      </Text>
                      {item.tooltip && (
                        <Tooltip
                          label={t(
                            `accounts.billing.change.pricing.categories.${feature.category}.${item.name}.tooltip`
                          )}
                          placement="bottom-start"
                        >
                          <Flex justify="center">
                            <Icon
                              as={FiInfo}
                              boxSize="4"
                              color="muted"
                              alignSelf="bottom"
                            />
                          </Flex>
                        </Tooltip>
                      )}
                    </HStack>
                  </Td>
                  {viewModel.plans
                    .slice(1, viewModel.plans.length)
                    .map((plan, id) => (
                      <Td
                        key={id}
                        textAlign={
                          viewModel.plans.slice(1, viewModel.plans.length)
                            .length > 1
                            ? 'center'
                            : 'right'
                        }
                        height="16"
                        color="muted"
                      >
                        <Feature value={plan.features[item.name]} />
                      </Td>
                    ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ))}
      </>
    )
  }
)
