import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { ApplicationViewModel } from '../../viewModels/Application'
import { Layout } from './../../../components/Layout'
import { useStores } from './../../../hooks/useStores'

export const ApplicationProvider = observer(({ children }) => {
  const { store } = useStores()
  const viewModel = new ApplicationViewModel(
    store.accountStore,
    store.applicationStore,
    store.authStore
  )

  return <Layout viewModel={viewModel}>{children}</Layout>
})
