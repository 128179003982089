import { v4 as uuidv4 } from 'uuid'

import { Identity } from '../@types/external'
import { Invitation, SubscriptionPlan } from './../@types/account'
import { ApplicationConstants as Constants } from './../constants'

type IAccountService = {
  createInvitation: (
    accountId: string,
    invitation: Invitation,
    token: string
  ) => Promise<Response>
  createSubscription: (
    accountId: string,
    plan: SubscriptionPlan,
    token: string
  ) => Promise<Response>
  deleteAccount: (accountId: string, token: string) => Promise<Response>
  deleteSubscription: (accountId: string, token: string) => Promise<Response>
  deleteUser: (
    accountId: string,
    memberId: string,
    token: string
  ) => Promise<Response>
  editAccount: (accountId: string, token: string) => Promise<Response>
  getAccount: (accountId: string, token: string) => Promise<Response>
  getUsers: (accountId: string, token: string) => Promise<Response>
  newSubscription: (accountId: string, token: string) => Promise<Response>
  updateAccount: (
    accountId: string,
    payload: { 'custom:repositories': string; identities: Identity[] },
    token: string
  ) => Promise<Response>
  updateInvitation: (
    accountId: string,
    invitationId: string
  ) => Promise<Response>
  updateSubscription: (
    accountId: string,
    plan: SubscriptionPlan,
    token: string
  ) => Promise<Response>
}

class AccountServiceClass implements IAccountService {
  // Accounts
  async deleteAccount(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async editAccount(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}/edit`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async getAccount(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async getAccountMembers(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}/members`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async getAccountUsage(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}/usage`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async getPlans(): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/plans`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async updateAccount(
    accountId: string,
    payload: { 'custom:repositories': string; identities: Identity[] },
    token: string
  ): Promise<Response> {
    const body = {
      account: {
        identity: payload.identities[0],
        repositories: payload['custom:repositories'],
      },
    }

    return fetch(`${Constants.API_URI}/accounts/${accountId}`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  // Subscriptions
  async createSubscription(
    accountId: string,
    plan: SubscriptionPlan,
    token: string
  ): Promise<Response> {
    const body = {
      period: plan.period,
      paymentMethod: plan.paymentMethod,
      item: { price: plan.priceId },
    }
    return fetch(`${Constants.API_URI}/accounts/${accountId}/subscriptions`, {
      method: Constants.HTTP_METHODS.POST,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async deleteSubscription(
    accountId: string,
    token: string
  ): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}/subscriptions`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async newSubscription(accountId: string, token: string): Promise<Response> {
    return fetch(
      `${Constants.API_URI}/accounts/${accountId}/subscriptions/new`,
      {
        method: Constants.HTTP_METHODS.GET,
        headers: {
          [Constants.HEADERS.AUTHORIZATION]: token,
          [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
          [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
          [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
        },
      }
    )
  }

  async updateSubscription(
    accountId: string,
    plan: SubscriptionPlan,
    token: string
  ): Promise<Response> {
    const body = {
      period: plan.period,
      item: { price: plan.priceId },
    }
    return fetch(`${Constants.API_URI}/accounts/${accountId}/subscriptions`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  // Teams
  async createInvitation(
    accountId: string,
    invitation: Invitation,
    token: string
  ): Promise<Response> {
    const body = {
      accountId: accountId,
      email: invitation.email,
      role: invitation.role,
    }
    return fetch(`${Constants.API_URI}/invitations`, {
      method: Constants.HTTP_METHODS.POST,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async deleteInvitation(
    invitationId: string,
    token: string
  ): Promise<Response> {
    return fetch(`${Constants.API_URI}/invitations/${invitationId}`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async updateInvitation(
    accountId: string,
    invitationId: string
  ): Promise<Response> {
    const body = {
      accountId: accountId,
      invitationId: invitationId,
    }
    return fetch(`${Constants.API_URI}/invitations/${invitationId}`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }

  async deleteUser(
    accountId: string,
    userId: string,
    token: string
  ): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}/users/${userId}`, {
      method: Constants.HTTP_METHODS.DELETE,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async getUsers(accountId: string, token: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/accounts/${accountId}/users`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async updateUser(
    accountId: string,
    userId: string,
    role: string,
    token: string
  ): Promise<Response> {
    const body = {
      accountId: accountId,
      id: userId,
      role: role,
    }
    return fetch(`${Constants.API_URI}/accounts/${accountId}/users/${userId}`, {
      method: Constants.HTTP_METHODS.PUT,
      headers: {
        [Constants.HEADERS.AUTHORIZATION]: token,
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
      body: JSON.stringify(body),
    })
  }
}

export const AccountService = new AccountServiceClass()
