import { observer } from 'mobx-react-lite'
import React from 'react'

import { Navigate, useRouter } from '@tanstack/react-location'

import { PrivateRouteViewModel } from '../app/viewModels/Components'
import { LoggerProvider } from '../hooks/useLogger'
import { PrivateRouteProps } from './../app/@types/components'
import { Spinner } from './../components/Spinner'

export const PrivateRoute: React.FC<{
  children: React.ReactElement
  viewModel: PrivateRouteViewModel
}> = observer((props: PrivateRouteProps) => {
  const { state } = useRouter()

  props.viewModel.authenticate()

  if (!props.viewModel.isAuthenticated && props.viewModel.isLoading) {
    return <Spinner />
  }

  if (props.viewModel.isAuthenticated) {
    return <LoggerProvider>{props.children}</LoggerProvider>
  } else {
    props.viewModel.redirectPath = state.location.href
    return <Navigate to="/auth/signin" />
  }
})
