import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Navigate } from '@tanstack/react-location'

import { AccountSettingsViewProps } from '../../app/@types/account'
import { Alert } from '../AlertDialog'

export const AccountDelete = observer((props: AccountSettingsViewProps) => {
  const { t } = useTranslation()

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmDelete = (event: unknown) => {
    onCloseDelete()
    props.viewModel.deleteAccount()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDeleteInstance = (event: unknown) => {
    onOpenDelete()
  }

  // After deleting account, the user is signed out; this
  // redirects to sign in page
  if (!props.viewModel.authStore.isAuthenticated) {
    return <Navigate to="/auth/signin" />
  }

  return (
    <Box as="section" py={{ base: '4', md: '4' }}>
      <Container maxW="full">
        <Box bg="bg-surface" borderRadius="lg" py={{ base: '4', md: '6' }}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '5', md: '6' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text color="red" fontSize="lg" fontWeight="medium">
                {t(
                  `accounts.settings.delete.plans.${props.viewModel.planName}.header`
                )}
              </Text>
              <Text fontSize="sm" color="muted">
                {t(
                  `accounts.settings.delete.plans.${props.viewModel.planName}.detail`
                )}
              </Text>
            </Stack>
            <Box>
              <Button colorScheme="red" onClick={onDeleteInstance}>
                {t(
                  `accounts.settings.delete.plans.${props.viewModel.planName}.actions.primary`
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Container>
      <Alert
        onClose={onCloseDelete}
        onConfirm={onConfirmDelete}
        onOpen={onOpenDelete}
        isOpen={isOpenDelete}
        body="accounts.settings.delete.alert.body"
        confirm="accounts.settings.delete.alert.confirm"
        dismiss="accounts.settings.delete.alert.dismiss"
        header="accounts.settings.delete.alert.header"
      />
    </Box>
  )
})
