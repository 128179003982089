import { action, makeObservable, observable } from 'mobx'

import { RootStore } from '.'
import { ApplicationError } from '../@types/application'
import { Document, NewDocument, NewDocumentFormInput } from '../@types/document'
import { CognitoSession } from '../@types/external'
import { ApplicationConstants as Constants } from '../constants'
import { DocumentService } from '../services/documentService'
import { BaseStore } from './baseStore'

export class DocumentStore extends BaseStore {
  rootStore: RootStore
  documents: Document[] = []

  constructor(store: RootStore) {
    super()

    this.rootStore = store
    makeObservable(this, {
      createDocument: action,
      deleteDocument: action,
      getDocuments: action,
      runDocument: action,
      setDocuments: action,
      updateDocument: action,
      rootStore: observable,
      documents: observable,
    })
  }

  async createDocument(values: NewDocumentFormInput): Promise<unknown> {
    const document: NewDocument = {
      action: 'create',
      content: values.content,
      name: values.name,
      onBuild: values.onBuild,
      tags: values.tags,
      userId: null,
    }

    return this.rootStore.authStore.currentSession().then(session => {
      const {
        idToken: { payload },
        accessToken: { jwtToken },
      } = session as CognitoSession

      const { [Constants.COGNITO.USERNAME]: username } = payload

      document.userId = username

      return DocumentService.create(document, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.documents.create.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async deleteDocument(id: string): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return DocumentService.delete(id, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.documents.delete.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async getDocuments(): Promise<unknown> {
    const session = await this.rootStore.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    return await DocumentService.list(jwtToken).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.documents.get.error.message',
          })
          reject(error)
        }
      }).then((documents: unknown) => {
        this.setDocuments(documents as Document[])
      })
    })
  }

  setDocuments(value: Document[]) {
    this.documents = value
  }

  async runDocument(id: string, values: string[]): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession
      return DocumentService.run(id, values, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.documents.run.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async updateDocument(
    id: string,
    values: NewDocumentFormInput
  ): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return DocumentService.update(id, values, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.documents.update.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }
}
