import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { AccountBillingViewProps } from '../../app/@types/account'
import { Alert } from '../AlertDialog'
import { PlanChange } from './PlanChange'
import { PlanCreate } from './PlanCreate'
import { PlanStatus } from './PlanStatus'

export const PlanSection = observer((props: AccountBillingViewProps) => {
  const { t } = useTranslation()

  const {
    isOpen: isOpenChange,
    onOpen: onOpenChange,
    onClose: onCloseChange,
  } = useDisclosure()

  const {
    isOpen: isOpenCancel,
    onOpen: onOpenCancel,
    onClose: onCloseCancel,
  } = useDisclosure()

  props.viewModel.onCloseAction = onCloseChange

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmCancel = (event: unknown) => {
    onCloseCancel()
    props.viewModel.deleteSubscription()
  }

  return (
    <Box as="section" bg="bg-surface" py={{ base: '4', md: '8' }}>
      <Container maxW="full">
        <Stack spacing="5">
          <Stack
            spacing="4"
            direction={{ base: 'column', sm: 'row' }}
            justify="space-between"
          >
            <Skeleton isLoaded={!props.viewModel.isLoading}>
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  {t('accounts.billing.plan.header')}
                </Text>
                <Text color="muted" fontSize="sm">
                  {props.viewModel.isLoading
                    ? "You're on the ***** plan."
                    : t(
                        `accounts.billing.plan.${props.viewModel.planName}.header`
                      )}
                </Text>
              </Box>
            </Skeleton>
            {props.viewModel.hasAdminScope && (
              <Skeleton isLoaded={!props.viewModel.isLoading}>
                <Stack direction="row" spacing="3">
                  {props.viewModel.planName !== 'free' && (
                    <Button variant="secondary" onClick={onOpenCancel}>
                      {props.viewModel.isLoading
                        ? ''
                        : t(
                            `accounts.billing.plan.${props.viewModel.planName}.actions.secondary`
                          )}
                    </Button>
                  )}
                  <Button variant="primary" onClick={onOpenChange}>
                    {props.viewModel.isLoading
                      ? ''
                      : t(
                          `accounts.billing.plan.${props.viewModel.planName}.actions.primary`
                        )}
                  </Button>
                </Stack>
              </Skeleton>
            )}
          </Stack>
          <Skeleton isLoaded={!props.viewModel.isLoading}>
            {props.viewModel.planName !== 'free' && <PlanStatus {...props} />}
          </Skeleton>
        </Stack>
      </Container>
      <Alert
        onClose={onCloseCancel}
        onConfirm={onConfirmCancel}
        onOpen={onOpenCancel}
        isOpen={isOpenCancel}
        body="accounts.billing.change.plan.delete.alert.body"
        confirm="accounts.billing.change.plan.delete.alert.confirm"
        dismiss="accounts.billing.change.plan.delete.alert.dismiss"
        header="accounts.billing.change.plan.delete.alert.header"
      />
      <Drawer onClose={onCloseChange} isOpen={isOpenChange} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            {props.viewModel.lastFour == null && <PlanCreate {...props} />}
            {props.viewModel.lastFour !== null && <PlanChange {...props} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
})
