import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { PageProps } from '../app/@types/page'
import { Header } from '../components/Blog'
import { Footer, NavBar } from '../components/Posters'
import { SlidingCalculator } from '../components/SlidingCalculator'

export const CalculatorPage = observer((props: PageProps) => {
  return (
    <>
      <NavBar
        {...props.pageViewModel}
        plans={[]}
        viewModel={props.posterViewModel}
      />
      <Header viewModel={props.posterViewModel} index={0} />
      <SlidingCalculator />
      <Header
        index={1}
        primaryLink="/auth/signup"
        secondaryLink="/pages/security"
        viewModel={props.posterViewModel}
      />
      <Footer viewModel={props.posterViewModel} plans={[]} />
    </>
  )
})
