import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { DocumentViewModel } from '../../viewModels/Document'
import { IndexDocuments } from '../../views/Code'

export const DocumentIndexProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new DocumentViewModel(
    store.accountStore,
    store.applicationStore,
    store.documentStore,
    store.instanceStore
  )

  return <IndexDocuments viewModel={viewModel} />
})
