import { computed, makeObservable, observable } from 'mobx'

import { RootStore } from '.'
import { Account } from '../@types/account'
import { ApplicationError } from '../@types/application'
import { CognitoPayloadAccount, CognitoSession } from '../@types/external'
import { WatchService } from '../services/watchService'
import { AuthStore } from './authStore'
import { BaseStore } from './baseStore'

export class WatchStore extends BaseStore {
  public authStore: AuthStore

  public rootStore: RootStore
  _accountHasOrganization = false

  constructor(rootStore: RootStore, authStore: AuthStore) {
    super()

    this.authStore = authStore
    this.rootStore = rootStore
    makeObservable(this, {
      accountHasOrganization: computed,
      authStore: observable,
      rootStore: observable,
      _accountHasOrganization: observable,
      accountFromSession: false,
      watchOrganization: false,
    })
  }

  public get accountHasOrganization(): boolean {
    return this._accountHasOrganization
  }

  public set accountHasOrganization(value: boolean) {
    this._accountHasOrganization = value
  }

  public async watchOrganization() {
    await this.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      const account = this.accountFromSession(session as CognitoSession)

      if (typeof account.organization != 'undefined') {
        this.accountHasOrganization = true
        return
      }

      const id = setInterval(() => {
        WatchService.watchOrganization(account.id, jwtToken).then(response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = this._errorFor(response, {
                message: 'requests.watches.organization.error.message',
              })
              reject(error)
            }
          })
            .then((data: unknown) => {
              if ((data as Account).account.organization) {
                this.accountHasOrganization = true
              }
            })
            .catch(() => {
              this.accountHasOrganization = false
            })
        })
      }, 15000)

      return () => clearInterval(id)
    })
  }

  accountFromSession(session: CognitoSession): CognitoPayloadAccount {
    const {
      idToken: {
        payload: { account: accountString },
      },
    } = session as CognitoSession

    return JSON.parse(accountString)
  }
}
