import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Box, BoxProps, Stack, Text } from '@chakra-ui/react'

type StepProps = {
  description: string
  isActive: boolean
  isCompleted: boolean
  title: string
} & BoxProps

export const Step = observer((props: StepProps) => {
  const { title, description, isActive, isCompleted, ...boxProps } = props
  return (
    <Box
      flex="1"
      py={{ base: '2', md: '3' }}
      ps={{ base: '3', md: '0' }}
      borderTopWidth={{ base: '0', md: '4px' }}
      borderLeftWidth={{ base: '4px', md: '0' }}
      borderColor={isActive || isCompleted ? 'accent' : 'inherit'}
      {...boxProps}
    >
      <Stack spacing="0.5">
        <Text color="emphasized" fontWeight="medium">
          {title}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
    </Box>
  )
})
