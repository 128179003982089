/* eslint-disable no-useless-escape */
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaBitbucket, FaGithub, FaGitlab } from 'react-icons/fa'

import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import { Navigate } from '@tanstack/react-location'
import { Link as LocationLink } from '@tanstack/react-location'

import { Logo } from '../../../images/Logo'
import { ViewProps } from '../../@types/auth'
import { ApplicationConstants as Constants } from '../../constants'
import { Quotee } from './../../../components/Posters/Quotee'

const isBrowser = typeof window !== 'undefined'

type IFormInput = {
  code?: string
  email?: string | undefined
}

export const SignUp = observer((props: ViewProps) => {
  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm<IFormInput>()

  const breakpointValue = useBreakpointValue({
    base: 'transparent',
    sm: 'bg-surface',
  })

  const { t } = useTranslation()
  const toast = useToast()

  if (props.viewModel.error) {
    toast({
      description: t(props.viewModel.error.message as string),
      duration: 9000,
      isClosable: true,
      position: 'top',
      status: props.viewModel.error.status || 'error',
      variant: 'subtle',
      onCloseComplete: () => {
        props.viewModel.error = undefined
      },
    })
  }

  const [challengeAttempts, setChallengeAttempts] = React.useState(2)
  const onSubmitEmail: SubmitHandler<IFormInput> = ({ email }) => {
    if (isBrowser) {
      document.activeElement?.blur()
      if (typeof email != 'undefined') {
        props.viewModel
          .signUp(email)
          .then(() => {
            if (
              props.viewModel.error &&
              props.viewModel.error.action &&
              [Constants.PROMPT_USER].includes(props.viewModel.error.action)
            ) {
              props.viewModel.confirmGuest()
            } else {
              props.viewModel.signIn(email)
            }
          })
          .catch(() => {
            props.viewModel.isLoading = false
            setError('email', {
              message: t('auth.signIn.email.form.error') as string,
              type: 'value',
            })
          })
      }
    }
  }

  const onSubmitCode: SubmitHandler<IFormInput> = ({ code }) => {
    if (isBrowser) {
      document.activeElement?.blur()
      if (typeof code != 'undefined') {
        props.viewModel.confirmCode(code).catch(() => {
          props.viewModel.isLoading = false
          setChallengeAttempts(challengeAttempts - 1)

          if (challengeAttempts == 0) {
            props.viewModel.user = undefined
          } else {
            setError('code', {
              message: t('auth.signIn.errors.code.incorrect', {
                count: challengeAttempts,
              }) as string,
              type: 'value',
            })
          }

          reset(
            { code: '', email: '' },
            {
              keepErrors: true,
            }
          )
        })
      }
    }
  }

  if (props.viewModel.isAuthenticated) {
    return <Navigate to="/spaces"></Navigate>
  }

  return (
    <Box py={{ base: '12', md: '24' }} maxW="5xl" mx="auto">
      <Flex
        alignItems="center"
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Container
          maxW="md"
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={breakpointValue}
          boxShadow={{ base: 'none', sm: 'xl' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
          id="pw-signup"
        >
          <Stack spacing="8" bg="white">
            <Stack spacing="6" align="center">
              <Logo height="16" />
              <Stack spacing="3" textAlign="center">
                <Heading size="m">
                  {props.viewModel.user
                    ? t('auth.signUp.confirmCode.heading')
                    : props.viewModel.isUpgrading
                    ? t('auth.signUp.confirmUpgrade.heading')
                    : t('auth.signUp.email.heading')}
                </Heading>
                {props.viewModel.user ? (
                  <HStack spacing="1" justify="center">
                    <Text color="muted">
                      {t('auth.signUp.confirmCode.subheading')}
                    </Text>
                  </HStack>
                ) : props.viewModel.isUpgrading ? (
                  <HStack spacing="1" justify="center">
                    <Text color="muted">
                      {t('auth.signUp.confirmUpgrade.subheading')}
                    </Text>
                  </HStack>
                ) : (
                  <HStack spacing="1" justify="center">
                    <Text color="muted">
                      {t('auth.signUp.email.subheading')}
                    </Text>
                    <Link as={LocationLink} to="/auth/signin">
                      <Button
                        isDisabled={props.viewModel.isLoading}
                        variant="link"
                        colorScheme="blue"
                      >
                        {t('auth.signUp.email.action')}
                      </Button>
                    </Link>
                  </HStack>
                )}
              </Stack>
            </Stack>
            <form
              onSubmit={
                props.viewModel.user
                  ? handleSubmit(onSubmitCode)
                  : handleSubmit(onSubmitEmail)
              }
            >
              <Stack spacing="6">
                <Stack spacing="4" pt="4">
                  {props.viewModel.user ? (
                    <FormControl isInvalid={!!errors.code}>
                      <Controller
                        control={control}
                        name="code"
                        render={({ field: { ref, ...restField } }) => (
                          <HStack width="70%" margin="0 auto">
                            <PinInput
                              {...restField}
                              onComplete={handleSubmit(onSubmitCode)}
                              isDisabled={props.viewModel.isLoading}
                            >
                              <PinInputField ref={ref} />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                        )}
                      />
                      <Center>
                        <FormErrorMessage>
                          {errors.code?.message}
                        </FormErrorMessage>
                      </Center>
                    </FormControl>
                  ) : (
                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel htmlFor="email"></FormLabel>
                      <Input
                        id="email"
                        isDisabled={
                          props.viewModel.isBitbucket ||
                          props.viewModel.isGitHub ||
                          props.viewModel.isGitLab ||
                          props.viewModel.isLoading
                        }
                        placeholder={
                          t('auth.signUp.form.placeholder') as string
                        }
                        type="email"
                        {...register('email', {
                          required: t('auth.signUp.form.required') as string,
                          pattern: {
                            value: Constants.EMAIL_REGEX,
                            message: t('auth.signUp.form.error'),
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  <Button
                    isLoading={
                      props.viewModel.isCognito && props.viewModel.isLoading
                    }
                    variant="secondary"
                    opacity={
                      props.viewModel.isBitbucket ||
                      props.viewModel.isGitHub ||
                      props.viewModel.isGitLab ||
                      props.viewModel.user
                        ? 0
                        : 100
                    }
                    cursor={props.viewModel.user ? 'default' : 'pointer'}
                    type={props.viewModel.user ? undefined : 'submit'}
                  >
                    {props.viewModel.user
                      ? t('')
                      : props.viewModel.isUpgrading
                      ? t('auth.upgrade.email.buttonLabel')
                      : t('auth.signIn.email.buttonLabel')}
                  </Button>
                </Stack>
              </Stack>
            </form>
            {props.viewModel.applicationStore.featureFlagFor('willShowOAuth') &&
            !props.viewModel.isUpgrading ? (
              <Stack>
                <Button
                  cursor={props.viewModel.user ? 'default' : 'pointer'}
                  iconSpacing="3"
                  isLoading={
                    props.viewModel.isGitHub && props.viewModel.isLoading
                  }
                  leftIcon={<FaGithub />}
                  onClick={() => props.viewModel.signUpGitHub()}
                  opacity={
                    props.viewModel.isBitbucket ||
                    props.viewModel.isGitLab ||
                    props.viewModel.isCognito ||
                    props.viewModel.user
                      ? 0
                      : 100
                  }
                  type={
                    !props.viewModel.isGitHub && props.viewModel.user
                      ? undefined
                      : 'submit'
                  }
                  variant="secondary"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  {t('auth.signUp.github.buttonLabel')}
                </Button>
                <Button
                  cursor={props.viewModel.user ? 'default' : 'pointer'}
                  iconSpacing="3"
                  isLoading={
                    props.viewModel.isBitbucket && props.viewModel.isLoading
                  }
                  leftIcon={<FaBitbucket />}
                  onClick={() => props.viewModel.signUpBitbucket()}
                  opacity={
                    props.viewModel.isGitHub ||
                    props.viewModel.isGitLab ||
                    props.viewModel.isCognito ||
                    props.viewModel.user
                      ? 0
                      : 100
                  }
                  type={
                    !props.viewModel.isBitbucket && props.viewModel.user
                      ? undefined
                      : 'submit'
                  }
                  variant="secondary"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  {t('auth.signUp.bitbucket.buttonLabel')}
                </Button>
                <Button
                  cursor={props.viewModel.user ? 'default' : 'pointer'}
                  iconSpacing="3"
                  isLoading={
                    props.viewModel.isGitLab && props.viewModel.isLoading
                  }
                  leftIcon={<FaGitlab />}
                  onClick={() => props.viewModel.signUpGitLab()}
                  opacity={
                    props.viewModel.isBitbucket ||
                    props.viewModel.isGitHub ||
                    props.viewModel.isCognito ||
                    props.viewModel.user
                      ? 0
                      : 100
                  }
                  type={props.viewModel.user ? undefined : 'submit'}
                  variant="secondary"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  {t('auth.signUp.gitlab.buttonLabel')}
                </Button>
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </Container>
        <Container py={{ base: '0', sm: '8' }} px={{ base: '4', sm: '10' }}>
          <Flex direction="column" align="center" textAlign="center">
            <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="medium">
              "Write all production programs with two people sitting at one
              machine."
            </Text>
            <Quotee
              name="Kent Beck"
              jobTitle="Creator of Extreme Programming"
              mt="*"
            />
          </Flex>
        </Container>
      </Flex>
    </Box>
  )
})
