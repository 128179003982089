import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Container,
  Flex,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'

import { AccountBillingViewProps } from '../../app/@types/account'
import { useStep } from '../../hooks/useStep'
import { AccountPayment } from '../Account/AccountPayment'
import { Address } from '../Address/Address'
import { Spinner } from '../Spinner'
import { Step } from '../Steps/Step'
import { Plans } from './Plans'

export const PlanCreate = observer((props: AccountBillingViewProps) => {
  React.useEffect(() => {
    props.viewModel.editAccount()
  }, [])

  const { t } = useTranslation()
  const [currentStep, { setStep }] = useStep({
    maxStep: props.viewModel.steps.length,
  })

  const onStep = (id: number) => {
    setStep(id)
  }

  const colorMode = useColorModeValue('white', 'gray.700')

  return props.viewModel.clientSecret ? (
    <Container id="pw-account-upgrade" maxW="full">
      <Box bg="bg-surface">
        <Container my={{ base: '4', md: '8' }} py={{ base: '4', md: '8' }}>
          <Stack direction={{ base: 'row' }} spacing="4">
            {props.viewModel.steps.map((step, id) => (
              <Step
                key={id}
                title={t(step.title)}
                description={t(step.description)}
                isActive={currentStep === id}
                isCompleted={currentStep > id}
              />
            ))}
          </Stack>
        </Container>
      </Box>
      <Box mb={{ base: '4', md: '8' }} bg="bg-surface">
        <Container>
          <Elements
            stripe={props.viewModel.stripePromise}
            options={{
              appearance: { theme: 'stripe' },
              clientSecret: props.viewModel.clientSecret,
            }}
          >
            {currentStep == 0 && <Plans {...props} onStep={onStep} />}
            {currentStep == 1 && (
              <Flex
                direction={{ base: 'column', lg: 'row' }}
                maxW={{ base: '560px', lg: 'unset' }}
                mx="auto"
                bg={colorMode}
                rounded="xl"
              >
                <Address {...props} onStep={onStep} />
              </Flex>
            )}
            {currentStep == 2 && (
              <Flex
                direction={{ base: 'column', lg: 'row' }}
                maxW={{ base: '560px', lg: 'unset' }}
                mx="auto"
                bg={colorMode}
                rounded="xl"
              >
                <AccountPayment {...props} onStep={onStep} />
              </Flex>
            )}
          </Elements>
        </Container>
      </Box>
    </Container>
  ) : (
    <Spinner />
  )
})
