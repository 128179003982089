import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AbsoluteCenter,
  Button,
  Center,
  Container,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useMatch } from '@tanstack/react-location'
import { Link } from '@tanstack/react-location'

import { Spinner } from '../../../components/Spinner'
import { AccountTeamViewProps } from '../../@types/account'

export const Invitation = observer((props: AccountTeamViewProps) => {
  const {
    params: { accountId, invitationId },
  } = useMatch()

  React.useEffect(() => {
    props.viewModel.updateInvitation(accountId, invitationId)
  }, [])

  const { t } = useTranslation()
  const toast = useToast()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          // props.viewModel.error = undefined
        },
      })
    }
  })

  return props.viewModel.isLoading ? (
    <>
      <Spinner />
      <Text color="muted">{t('users.create.loading.message')}</Text>
    </>
  ) : typeof props.viewModel.error == 'undefined' ? (
    <Container width="100%" height="100vh">
      <AbsoluteCenter>
        <Text color="muted">{t('users.create.success.message')}</Text>
        <Center py={4}>
          <Button as={Link} to="/auth/signin" variant="primary">
            {t('users.create.signIn.message')}
          </Button>
        </Center>
      </AbsoluteCenter>
    </Container>
  ) : (
    <Container width="100%" height="100vh">
      <AbsoluteCenter>
        <Text color="muted">{t('users.create.error.message')}</Text>
        <Center py={4}>
          <Button as={Link} to="/" variant="primary">
            {t('users.create.error.buttonLabel')}
          </Button>
        </Center>
      </AbsoluteCenter>
    </Container>
  )
})
