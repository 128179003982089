import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { PageProps } from '../../app/@types/page'
import { NavBar } from '../../components/Blog'
import { PageContent } from '../../components/Pages/Content'
import { Footer } from '../../components/Posters'

export const PrivacyPage = observer((props: PageProps) => {
  return (
    <>
      <NavBar {...props} />
      <PageContent {...props} />
      <Footer viewModel={props.posterViewModel} />
    </>
  )
})
