import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Box, Container, Stack, Text } from '@chakra-ui/react'

import { AccountBillingViewProps } from '../../app/@types/account'
import { AddressCard } from './AddressCard'

export const Address = observer((props: AccountBillingViewProps) => {
  return (
    <Container id="pw-account-address" py={{ base: '4', md: '8' }}>
      <Stack spacing="5">
        <Stack
          direction={{ base: 'column', lg: 'column' }}
          spacing={{ base: '5', lg: '8' }}
          justify="space-between"
        >
          <Box flexShrink={0}>
            <Text fontSize="lg" fontWeight="medium">
              Billing Address
            </Text>
            <Text color="muted" fontSize="sm">
              We use this to confirm your credit card.
            </Text>
          </Box>
          <AddressCard {...props} />
        </Stack>
      </Stack>
    </Container>
  )
})
