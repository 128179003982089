import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FiCreditCard, FiLogOut, FiSettings } from 'react-icons/fi'
import { GrDocumentConfig } from 'react-icons/gr'
import { HiOutlineClipboardList } from 'react-icons/hi'
import { IoMdGitBranch } from 'react-icons/io'
import { MdOutlinePeopleAlt, MdWorkspacesOutline } from 'react-icons/md'

import { Box, Spacer, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-location'

import { ApplicationViewProps } from './../../app/@types/application'
import { NavigationButton } from './NavigationButton'

export const MenuActive = observer((props: ApplicationViewProps) => {
  const navigate = useNavigate()

  const isFreeTier =
    typeof props.viewModel.accountStore.plan == 'undefined' ||
    props.viewModel.accountStore.plan == 'free'
  const willShowDocuments =
    props.viewModel.applicationStore.featureFlagFor('willShowFiles')
  const willShowOAuth =
    props.viewModel.applicationStore.featureFlagFor('willShowOAuth')
  const willShowTeams =
    props.viewModel.applicationStore.featureFlagFor('willShowTeams')

  const displayBreakpointValue = useBreakpointValue({
    base: 'none',
    md: 'display',
  })
  const heightBreakpointValue = useBreakpointValue({
    base: '2em',
    md: '0',
  })
  const mlBreakpointValue = useBreakpointValue({
    base: '14px',
    md: '0',
  })
  const plBreakpointValue = useBreakpointValue({
    base: '6',
    md: '4',
  })
  const widthBreakpointValue = useBreakpointValue({
    base: '2em',
    md: 'full',
  })

  return (
    <Stack
      justify="space-between"
      spacing="1"
      width={widthBreakpointValue}
      py={{ base: '4', md: '8' }}
    >
      <Stack ml={mlBreakpointValue} spacing="8" shouldWrapChildren>
        {(props.viewModel.hasAdminScope || props.viewModel.hasMemberScope) && (
          <Stack spacing="1">
            <Text
              display={displayBreakpointValue}
              fontSize="sm"
              color="subtle"
              fontWeight="medium"
            >
              Create
            </Text>
            <Box height={heightBreakpointValue} />
            <NavigationButton
              onClick={() => {
                props.viewModel.navigateTo('/spaces', navigate)
              }}
              label="Spaces"
              icon={MdWorkspacesOutline}
              aria-current={props.viewModel.isCurrentMenuItem('/spaces')}
              pl={plBreakpointValue}
            />
            {willShowDocuments && !isFreeTier && (
              <NavigationButton
                onClick={() => {
                  props.viewModel.navigateTo('/documents', navigate)
                }}
                label="Documents"
                icon={GrDocumentConfig}
                aria-current={props.viewModel.isCurrentMenuItem('/documents')}
                pl={plBreakpointValue}
              />
            )}
            {willShowOAuth && !isFreeTier && (
              <NavigationButton
                onClick={() => {
                  props.viewModel.navigateTo('/repos', navigate)
                }}
                label="Repositories"
                icon={IoMdGitBranch}
                aria-current={props.viewModel.isCurrentMenuItem('/repos')}
                pl={plBreakpointValue}
              />
            )}
          </Stack>
        )}
        {props.viewModel.hasBillingScope && (
          <Stack spacing="1">
            <Text
              display={displayBreakpointValue}
              fontSize="sm"
              color="subtle"
              fontWeight="medium"
            >
              Account
            </Text>
            <Box height={heightBreakpointValue} />
            {props.viewModel.hasBillingScope && (
              <NavigationButton
                onClick={() => {
                  props.viewModel.navigateTo('/accounts/billing', navigate)
                }}
                label="Billing"
                icon={FiCreditCard}
                aria-current={props.viewModel.isCurrentMenuItem(
                  '/accounts/billing'
                )}
              />
            )}
            {props.viewModel.hasAdminScope && !isFreeTier && willShowTeams && (
              <NavigationButton
                onClick={() => {
                  props.viewModel.navigateTo('/accounts/team', navigate)
                }}
                label="Team"
                icon={MdOutlinePeopleAlt}
                aria-current={props.viewModel.isCurrentMenuItem(
                  '/accounts/teams'
                )}
              />
            )}
            {props.viewModel.hasAdminScope && (
              <NavigationButton
                onClick={() => {
                  props.viewModel.navigateTo('/accounts/settings', navigate)
                }}
                label="Settings"
                icon={FiSettings}
                aria-current={props.viewModel.isCurrentMenuItem(
                  '/accounts/settings'
                )}
              />
            )}
          </Stack>
        )}
        <Stack spacing="1">
          <Text
            display={displayBreakpointValue}
            fontSize="sm"
            color="subtle"
            fontWeight="medium"
          >
            Help
          </Text>
          <Box height={heightBreakpointValue} />
          <NavigationButton
            onClick={() => {
              props.viewModel.documentation()
            }}
            label="Documentation"
            icon={HiOutlineClipboardList}
          />
        </Stack>
      </Stack>
      <Spacer />
      <NavigationButton
        onClick={() => {
          props.viewModel.signOut(navigate)
        }}
        label="Sign out"
        icon={FiLogOut}
      />
    </Stack>
  )
})
