import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Badge,
  Button,
  HStack,
  Heading,
  Link,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react'

import { PosterProps } from '../../../app/@types/poster'
import { DurationSwitcher } from '../DurationSwitcher'

type PlanOverviewTableProps = PosterProps & TableProps

export const PlanOverviewTable = observer((props: PlanOverviewTableProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { ...tableProps } = props
  const { t } = useTranslation()

  return (
    <Table id="pricing" sx={{ tableLayout: 'fixed' }} {...tableProps}>
      <Thead>
        <Tr verticalAlign={isMobile ? 'inherit' : 'text-top'}>
          <Td>
            {isMobile ? (
              <></>
            ) : (
              <HStack>
                <DurationSwitcher {...props} />
              </HStack>
            )}
          </Td>
          {props.plans &&
            props.plans.map((plan, id) => (
              <Td key={id} fontSize="3xl" fontWeight="regular">
                <HStack>
                  <Text width="full" textAlign="center">
                    {t(`posters.common.pricing.plans.${plan.name}.title`)}
                  </Text>
                  {plan.isPopular && (
                    <Badge bg="gray.500" color="gray.100">
                      {t(`posters.common.pricing.plans.popular`)}
                    </Badge>
                  )}
                </HStack>
              </Td>
            ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          {props.plans &&
            props.plans.slice(1, props.plans.length).length > 1 && (
              <Td borderBottomWidth="0px" />
            )}
          {props.plans?.map(plan => (
            <Td
              key={plan.product.id}
              whiteSpace="normal"
              borderBottomWidth="0px"
            >
              <Stack spacing="6">
                <Stack spacing="4">
                  {plan.name == 'enterprise' ? (
                    <Button
                      as={Link}
                      href="mailto:scale@pairspaces.com"
                      variant="primary"
                      _hover={{
                        textDecoration: 'none',
                      }}
                    >
                      Contact Us
                    </Button>
                  ) : (
                    <Stack direction="row" align="baseline" spacing="1">
                      <Heading size="lg" color="default">
                        {props.viewModel.currencySymbol}
                        {props.viewModel.priceForPeriod(plan)}
                      </Heading>
                      <Text fontWeight="medium" fontSize="medium" color="muted">
                        /{t(`posters.common.pricing.plans.user`)}/
                        {t(`posters.common.pricing.plans.periods.month`)}
                      </Text>
                    </Stack>
                  )}
                  <Text
                    color="muted"
                    fontSize="sm"
                    whiteSpace="normal"
                    minH={24}
                  >
                    {t(`posters.common.pricing.plans.${plan.name}.description`)}
                  </Text>
                </Stack>
              </Stack>
            </Td>
          ))}
        </Tr>
      </Tbody>
    </Table>
  )
})
