/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'

import { ViewProps } from '../../@types/instance'
import { Alert } from './../../../components/AlertDialog'
import { Instance } from './../../@types/instance'

type INetworkFormInput = {
  status: boolean
}

type IScheduleFormInput = {
  endTime: string
  startTime: string
  status: boolean
}

type IStatusFormInput = {
  status: boolean
}

export const ShowInstance = observer((props: ViewProps) => {
  React.useEffect(() => {
    setHasNetwork(props.viewModel.currentInstance?.states.Network.status)
    setHasSchedule(props.viewModel.currentInstance?.states.Schedule.status)
    setIsRunning(props.viewModel.currentInstance?.states.Instance.status)
    const [startTime, endTime] = props.viewModel.scheduleForInstance(
      props.viewModel.currentInstance?.states.Schedule.value as string
    )
    scheduleReset({
      endTime,
      startTime,
      status: props.viewModel.currentInstance?.states.Schedule.status,
    })
  }, [])

  const { t } = useTranslation()

  const [pendingData, setPendingData] = React.useState({})

  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  const [hasNetwork, setHasNetwork] = React.useState(
    props.viewModel.currentInstance?.states.Network.status
  )
  const [hasSchedule, setHasSchedule] = React.useState(
    props.viewModel.currentInstance?.states.Schedule.status
  )
  const [isRunning, setIsRunning] = React.useState(
    props.viewModel.currentInstance?.states.Instance.status
  )

  const {
    isOpen: isOpenNetwork,
    onOpen: onOpenNetwork,
    onClose: onCloseNetwork,
  } = useDisclosure()

  const onChangeNetworkForm: SubmitHandler<IStatusFormInput> = data => {
    setPendingData(data)

    if (!data.status) {
      onConfirmNetwork(null, data)
    } else {
      onOpenNetwork()
    }
  }

  const onConfirmNetwork = (event: unknown, data?: INetworkFormInput) => {
    onCloseNetwork()

    if (typeof data !== 'undefined') {
      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          network: {
            status: data.status,
          },
        }
      )
      setHasNetwork(data.status)
    } else {
      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          network: {
            status: (pendingData as INetworkFormInput).status,
          },
        }
      )
      setHasNetwork((pendingData as INetworkFormInput).status)
    }

    setPendingData({})
  }

  const onCancelNetwork = () => {
    onCloseNetwork()
    setPendingData({})
  }

  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure()

  const onConfirmStatus = (event: unknown, data?: IStatusFormInput) => {
    onCloseStatus()

    if (typeof data !== 'undefined') {
      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          instance: {
            status: data.status,
          },
        }
      )
      setIsRunning(data.status)
    } else {
      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          instance: {
            status: (pendingData as IStatusFormInput).status,
          },
        }
      )
      setIsRunning((pendingData as IStatusFormInput).status)
    }

    setPendingData({})
  }

  const onCancelStatus = () => {
    onCloseStatus()
    setPendingData({})
  }

  const {
    formState: {
      errors: statusFormErrors,
      isSubmitting: statusFormIsSubmitting,
    },
    handleSubmit: statusFormHandleSubmit,
    register: statusFormRegister,
  } = useForm<IStatusFormInput>({})

  const onSubmitStatusForm: SubmitHandler<IStatusFormInput> = data => {
    setPendingData(data)

    if (data.status) {
      onConfirmStatus(null, data)
    } else {
      onOpenStatus()
    }
  }

  const {
    formState: {
      errors: networkFormErrors,
      isSubmitting: networkFormIsSubmitting,
    },
    handleSubmit: networkHandleSubmit,
    register: networkRegister,
  } = useForm<INetworkFormInput>({
    defaultValues: {
      status: hasNetwork,
    },
  })

  const {
    formState: {
      defaultValues: scheduleDefaultValues,
      errors: scheduleFormErrors,
      isSubmitting: scheduleIsSubmitting,
    },
    handleSubmit: scheduleHandleSubmit,
    register: scheduleRegister,
    reset: scheduleReset,
  } = useForm<IScheduleFormInput>({
    defaultValues: props.viewModel.currentInstance?.states.Schedule,
  })

  const {
    isOpen: isOpenSchedule,
    onOpen: onOpenSchedule,
    onClose: onCloseSchedule,
  } = useDisclosure()

  const onConfirmSchedule = (event: unknown, data?: IScheduleFormInput) => {
    onCloseSchedule()

    if (typeof data !== 'undefined') {
      const schedule = props.viewModel.hoursToSchedule(
        data.startTime,
        data.endTime
      )

      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          instance: {
            schedule: {
              status: data.status,
              value: schedule,
            },
          },
        }
      )
      setHasSchedule(data.status)
    } else {
      const schedule = props.viewModel.hoursToSchedule(
        (pendingData as IScheduleFormInput).startTime,
        (pendingData as IScheduleFormInput).endTime
      )

      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          instance: {
            schedule: {
              status: (pendingData as IScheduleFormInput).status,
              value: schedule,
            },
          },
        }
      )
      setHasSchedule((pendingData as IScheduleFormInput).status)
    }

    setPendingData({})
  }

  const onCancelSchedule = () => {
    onCloseSchedule()
    setPendingData({})
  }

  const onChangeScheduleForm = (data: IScheduleFormInput) => {
    setPendingData(data)

    const schedule = props.viewModel.hoursToSchedule(
      data.startTime,
      data.endTime
    )

    if (data.status && data.startTime && data.endTime) {
      onOpenSchedule()
    } else {
      props.viewModel.updateInstance(
        (props.viewModel.currentInstance as Instance)._id,
        {
          instance: {
            schedule: {
              status: data.status,
              value: schedule,
            },
          },
        }
      )
      setHasSchedule(data.status)
    }
  }

  const onSubmitScheduleForm: SubmitHandler<IScheduleFormInput> = data => {
    const schedule = props.viewModel.hoursToSchedule(
      data.startTime,
      data.endTime
    )

    props.viewModel.updateInstance(
      (props.viewModel.currentInstance as Instance)._id,
      {
        instance: {
          schedule: {
            status: data.status,
            value: schedule,
          },
        },
      }
    )
  }

  return (
    <Box id="pw-edit-instance">
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: '4', md: '8' }}
        pb={{ base: '12', md: '24' }}
      >
        <Container maxW="full">
          <Stack
            spacing="4"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {props.viewModel.currentInstance?.name}
              </Heading>
              <Text color="muted">{props.viewModel.instanceType}</Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        bg="bg-surface"
        boxShadow={colorModeValue}
        borderRadius="lg"
        p={{ base: '4', md: '6' }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              {t('instances.show.configuration.heading')}
            </Text>
            <Text fontSize="sm" color="muted">
              {t('instances.show.configuration.subheading')}
            </Text>
          </Stack>
          {Object.entries(props.viewModel.currentInstance?.states).map(
            ([name, state]) => {
              return (
                <Stack
                  key={name}
                  justify="space-between"
                  direction="row"
                  spacing="4"
                >
                  <Stack spacing="0.5" fontSize="sm">
                    <Text color="emphasized" fontWeight="medium">
                      {t(`instances.show.states.${name}.label`)}
                    </Text>
                    <Text color="muted">
                      {name == 'Network'
                        ? props.viewModel.plan == 'private'
                          ? t(
                              `instances.show.states.${name}.description.private`
                            )
                          : t(
                              `instances.show.states.${name}.description.notPrivate`
                            )
                        : name == 'Schedule'
                        ? props.viewModel.plan == 'free'
                          ? t(
                              `instances.show.states.${name}.description.notPrivate`
                            )
                          : t(
                              `instances.show.states.${name}.description.private`,
                              {
                                timezone: props.viewModel.timezone,
                              }
                            )
                        : t(`instances.show.states.${name}.description`)}
                    </Text>
                  </Stack>
                  {name == 'Schedule' ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <HStack>
                        <FormControl
                          onChange={scheduleHandleSubmit(onChangeScheduleForm)}
                        >
                          <Switch
                            isDisabled={['free'].includes(
                              props.viewModel.plan as string
                            )}
                            size="lg"
                            {...scheduleRegister('status')}
                            isChecked={hasSchedule}
                          />
                        </FormControl>
                      </HStack>
                      <form
                        onSubmit={scheduleHandleSubmit(onSubmitScheduleForm)}
                      >
                        <Box>
                          <Stack
                            spacing="6"
                            direction={{ base: 'column', md: 'row' }}
                            mt={{ base: '4', md: '6' }}
                          >
                            <FormControl
                              isDisabled={!hasSchedule}
                              onChange={statusFormHandleSubmit(
                                onSubmitStatusForm
                              )}
                            >
                              <FormLabel>Start Time</FormLabel>
                              <Input
                                id="startTime"
                                type="time"
                                {...scheduleRegister('startTime')}
                              />
                            </FormControl>
                            <FormControl isDisabled={!hasSchedule}>
                              <FormLabel>End Time</FormLabel>
                              <Input
                                id="endTime"
                                type="time"
                                {...scheduleRegister('endTime')}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel> </FormLabel>
                              <Button
                                isDisabled={!hasSchedule}
                                mt={5}
                                type="submit"
                                variant="outline"
                              >
                                {t(`instances.show.states.${name}.buttonLabel`)}
                              </Button>
                            </FormControl>
                          </Stack>
                        </Box>
                      </form>
                    </Box>
                  ) : name == 'Instance' ? (
                    <HStack>
                      <FormControl
                        onChange={statusFormHandleSubmit(onSubmitStatusForm)}
                      >
                        <Switch
                          size="lg"
                          {...statusFormRegister('status')}
                          isChecked={isRunning}
                        />
                      </FormControl>
                    </HStack>
                  ) : (
                    <HStack>
                      <FormControl
                        isDisabled={['free', 'shared'].includes(
                          props.viewModel.plan as string
                        )}
                        onChange={networkHandleSubmit(onChangeNetworkForm)}
                      >
                        <Switch
                          size="lg"
                          {...networkRegister('status')}
                          isChecked={hasNetwork}
                        />
                      </FormControl>
                    </HStack>
                  )}
                </Stack>
              )
            }
          )}
        </Stack>
      </Box>
      <Alert
        onClose={onCancelNetwork}
        onConfirm={onConfirmNetwork}
        onOpen={onOpenNetwork}
        isOpen={isOpenNetwork}
        body="instances.show.states.Network.alert.body"
        confirm="instances.show.states.Network.alert.confirm"
        dismiss="instances.show.states.Network.alert.dismiss"
        header="instances.show.states.Network.alert.header"
      />
      <Alert
        onClose={onCancelStatus}
        onConfirm={onConfirmStatus}
        onOpen={onOpenStatus}
        isOpen={isOpenStatus}
        body="instances.show.states.Instance.alert.body"
        confirm="instances.show.states.Instance.alert.confirm"
        dismiss="instances.show.states.Instance.alert.dismiss"
        header="instances.show.states.Instance.alert.header"
      />
      <Alert
        onClose={onCancelSchedule}
        onConfirm={onConfirmSchedule}
        onOpen={onOpenSchedule}
        isOpen={isOpenSchedule}
        body="instances.show.states.Schedule.alert.body"
        confirm="instances.show.states.Schedule.alert.confirm"
        dismiss="instances.show.states.Schedule.alert.dismiss"
        header="instances.show.states.Schedule.alert.header"
      />
    </Box>
  )
})
