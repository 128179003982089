import { AccountTeamViewModel } from '../viewModels/Account/AccountTeam'
import {
  AccountBillingViewModel,
  AccountSettingsViewModel,
} from './../viewModels/Account'
import { Instance, InstanceMember } from './instance'

// Account

export type Account = {
  account: {
    awsAccountId: string
    id: string
    nextAction?: StripeAction
    organization: boolean
    status: string
  }
  paymentMethod: PaymentMethod
  plans: Plan[]
  subscription: Subscription
}

export type Download = {
  date: string
  url: string
}

export type Invoice = {
  licensedPayment: number
  meteredPayment: number
  periodEnd: number
}

export type PaymentMethod = {
  last4?: string
}

// is Methods

export function isSubscriptionPlan(
  plan: SubscriptionPlan
): plan is SubscriptionPlan {
  return (plan as SubscriptionPlan).productId !== undefined
}

// Navigation

export type Step = {
  description: string
  id: string
  title: string
}

// Plans

export type Recurring = {
  aggregateUsage?: string
  interval: string
  usageType?: string
}

export type Price = {
  currency: string
  id: string
  product: string
  recurring: Recurring
  unitAmount: string
}

export type Product = {
  description: string
  id: string
  name: string
  unitLabel?: string
}

export type Plan = {
  features: { [key: string]: string | boolean }
  isPopular?: boolean
  name: string
  prices: Price[]
  product: Product
}

// Responses

export type DeleteSubscriptionResponse = {
  instances: Instance[]
  subscription: SubscriptionResponse
}

export type EditAccountResponse = {
  stripe: {
    clientSecret: string
    publishableKey: string
  }
}

export type GetAccountMembersResponse = {
  users: InstanceMember[]
}

export type SubscriptionResponse = {
  id: string
}

export type UsageResponse = {
  invoice: Invoice
  invoices: Download[]
}

// Stripe

export type StripeAction = {
  redirect_to_url?: {
    return_url: string
    url: string
  }
  type: string
  use_stripe_sdk?: {
    source: string
    stripe_js: string
    type: string
  }
}

export type StripeConfiguration = {
  clientSecret: string
  publishableKey: string
}

// Subscriptions

export type SubscriptionPlan = {
  paymentMethod?: string
  period: string
  priceId?: string
  productId?: string
}

export type Subscription = {
  plan: SubscriptionPlan
  status: string
}

// Team

export type EditUserModalProps = AccountTeamViewProps & {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onSubmit?: (role: string) => void
}

export type NewUserModalProps = AccountTeamViewProps & {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onSubmit?: (values: Invitation) => void
}

export type Invitation = {
  email: string
  role: string
}

export type Member = {
  _id: string
  email: string
  role: 'admin' | 'billing' | 'member'
  roles: ('admin' | 'billing' | 'member')[]
  status: string
}

export type Role = {
  name: string
  value: 'admin' | 'billing' | 'member'
}

export type User = {
  email: string
  role: string
}

// ViewModels

export type AccountBillingViewProps = {
  onStep?: (id: number) => void
  viewModel: AccountBillingViewModel
}

export type AccountSettingsViewProps = {
  viewModel: AccountSettingsViewModel
}

export type AccountTeamViewProps = {
  viewModel: AccountTeamViewModel
}
