import { observer } from 'mobx-react-lite'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { EditUserModalProps, User } from '../../@types/account'

export const EditUser = observer((props: EditUserModalProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<User>({
    defaultValues: {
      email: props.viewModel.currentUser?.email,
      role: props.viewModel.currentUser?.roles[0],
    },
  })
  const { t } = useTranslation()

  return (
    <Box id="pw-edit-team">
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: '4', md: '8' }}
        pb={{ base: '12', md: '24' }}
      >
        <Container maxW="full">
          <Stack
            spacing="4"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {t('users.edit.header.title')}
              </Heading>
              <Text color="muted">{t('users.edit.header.subheading')}</Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container maxW="full">
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" variant="floating" size="lg">
                  {t('users.edit.form.email.label')}
                </FormLabel>
                <Input
                  disabled={true}
                  id="email"
                  size="lg"
                  placeholder=" "
                  {...register('email', {
                    required: t('users.edit.form.email.required') as string,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t(
                        'users.edit.form.email.error.message'
                      ) as string,
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.role}>
                <FormLabel>{t('users.edit.form.role.label')}</FormLabel>
                <Select
                  id="role"
                  placeholder="Select a role"
                  {...register('role', {
                    required: t('users.edit.form.role.error.message') as string,
                  })}
                >
                  {props.viewModel.roles.map(role => {
                    return <option value={role.value}>{role.name}</option>
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.role && errors.role.message}
                </FormErrorMessage>
              </FormControl>
              <ButtonGroup alignSelf="end">
                <Button variant="secondary" onClick={props.onClose}>
                  {t('users.edit.form.action.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('users.edit.form.action.invite')}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </Container>
      </Box>
    </Box>
  )
})
