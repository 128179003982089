import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FaLinkedin, FaTwitter } from 'react-icons/fa'

import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Divider,
  IconButton,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Link as LocationLink } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'
import { Logo } from '../../images/Logo'

export const Footer = observer((props: PosterProps) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Container
        as="footer"
        bgColor="gray.100"
        maxW="full"
        mt={48}
        role="contentinfo"
      >
        <Stack
          spacing="8"
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          px={{ base: '12', md: '16' }}
          py={{ base: '12', md: '16' }}
        >
          <Stack
            spacing={{ base: '6', md: '8' }}
            align={{ base: 'center', md: 'start' }}
          >
            <Button
              as={Link}
              fontWeight="regular"
              href="/"
              _hover={{ background: 'none' }}
            >
              <Logo height="64px" />
            </Button>
            <Text color="on-accent-muted">
              {t(`posters.common.footer.tag`)}
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'column-reverse', md: 'column', lg: 'row' }}
            spacing={{ base: '12', md: '8' }}
            textAlign={{ base: 'center', md: 'initial ' }}
          >
            <Stack direction={{ base: 'column', md: 'row' }} spacing="8">
              <Stack
                spacing={{ base: '1', md: '4' }}
                minW="36"
                flex="1"
                alignItems={{ base: 'center', md: 'initial' }}
              >
                <Text
                  color="on-accent-subtle"
                  fontSize="sm"
                  fontWeight="semibold"
                  pl={{ base: 0, md: 4 }}
                >
                  {t(`posters.common.footer.features.title`)}
                </Text>
                <Stack spacing={{ base: '0', md: '3' }} shouldWrapChildren>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/pages/collaboration"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.features.collaboration`)}
                  </Button>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/pages/productivity"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.features.productivity`)}
                  </Button>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/pages/security"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.features.security`)}
                  </Button>
                </Stack>
              </Stack>
              <Stack
                alignItems={{ base: 'center', md: 'initial' }}
                spacing="4"
                minW="36"
                flex="1"
              >
                <Text
                  color="on-accent-subtle"
                  fontSize="sm"
                  fontWeight="semibold"
                  pl={{ base: 0, md: 4 }}
                >
                  {t(`posters.common.footer.solutions.title`)}
                </Text>
                <Stack spacing={{ base: '0', md: '3' }} shouldWrapChildren>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/solutions/agencies"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.solutions.agency`)}
                  </Button>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/solutions/enterprise"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.solutions.enterprise`)}
                  </Button>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/solutions/startups"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.solutions.startup`)}
                  </Button>
                </Stack>
              </Stack>
              <Stack
                alignItems={{ base: 'center', md: 'initial' }}
                spacing="4"
                minW="36"
                flex="1"
              >
                <Text
                  color="on-accent-subtle"
                  fontSize="sm"
                  fontWeight="semibold"
                  pl={{ base: 0, md: 4 }}
                >
                  {t(`posters.common.footer.resources.title`)}
                </Text>
                <Stack spacing={{ base: '0', md: '3' }} shouldWrapChildren>
                  <Button
                    fontWeight="regular"
                    onClick={() => props.viewModel.documentation()}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.resources.documentation`)}
                  </Button>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="/pages/pricing"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.resources.pricing`)}
                  </Button>
                  <Button
                    as={Link}
                    fontWeight="regular"
                    href="mailto:support@getvenv.com"
                    rel="nofollow"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.resources.support`)}
                  </Button>
                </Stack>
              </Stack>
              <Stack
                alignItems={{ base: 'center', md: 'initial' }}
                spacing="4"
                minW="36"
                flex="1"
              >
                <Text
                  color="on-accent-subtle"
                  fontSize="sm"
                  fontWeight="semibold"
                  pl={{ base: 0, md: 4 }}
                >
                  {t(`posters.common.footer.about.title`)}
                </Text>
                <Stack spacing={{ base: '0', md: '3' }} shouldWrapChildren>
                  <Button
                    as={LocationLink}
                    fontWeight="regular"
                    to="/about"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.about.company`)}
                  </Button>
                  <Button
                    as={LocationLink}
                    fontWeight="regular"
                    to="/legal/terms"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.about.terms`)}
                  </Button>
                  <Button
                    as={LocationLink}
                    fontWeight="regular"
                    to="/legal/privacy"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.footer.about.privacy`)}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            {/* <Stack spacing="4">
              <Text
                fontSize="sm"
                fontWeight="semibold"
                color="on-accent-subtle"
              >
                {t(`posters.${props.viewModel.name}.footer.subscribe.cta`)}
              </Text>
              <Stack
                spacing="4"
                direction={{ base: 'column', sm: 'row' }}
                maxW={{ lg: '360px' }}
              >
                <Input
                  placeholder={
                    t(
                      `posters.${props.viewModel.name}.footer.subscribe.placeholder`
                    ) as string
                  }
                  type="email"
                  required
                  variant="on-accent"
                />
                <Button
                  variant="primary-on-accent"
                  type="submit"
                  flexShrink={0}
                >
                  {t(
                    `posters.${props.viewModel.name}.footer.subscribe.button.label`
                  )}
                </Button>
              </Stack>
            </Stack> */}
          </Stack>
        </Stack>
        <Divider borderColor="bg-accent-subtle" />
        <Stack
          pt="8"
          pb="12"
          px={{ base: '12', md: '16' }}
          justify="space-between"
          direction={{ base: 'column-reverse', md: 'row' }}
          align="center"
        >
          <Text fontSize="sm" color="on-accent-subtle"></Text>
          <ButtonGroup variant="ghost-on-accent">
            <IconButton
              as="a"
              href="https://www.linkedin.com/company/getvenv"
              aria-label="LinkedIn"
              icon={<FaLinkedin fontSize="1.25rem" />}
              target="_blank"
            />
            <IconButton
              as="a"
              aria-label="Twitter"
              href="https://twitter.com/getvenv"
              icon={<FaTwitter fontSize="1.25rem" />}
              target="_blank"
            />
          </ButtonGroup>
        </Stack>
        <Center pb={4}>
          <Text fontSize="3xs" color="on-accent-subtle">
            &copy; 2022-{new Date().getFullYear()} Interaction, LLC. All rights
            reserved.
          </Text>
        </Center>
      </Container>
    </Box>
  )
})
