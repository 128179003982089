import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { RepositoryViewModel } from '../../viewModels/Repository'
import { IndexRepositories } from '../../views/Repository/IndexRepositories'

export const RepositoryIndexProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new RepositoryViewModel(
    store.accountStore,
    store.applicationStore,
    store.repositoryStore
  )

  return <IndexRepositories viewModel={viewModel} />
})
