// src/ChevronLeft.tsx
import { createIcon } from "@chakra-ui/icon";
var ChevronLeftIcon = createIcon({
  d: "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z",
  displayName: "ChevronLeftIcon"
});

export {
  ChevronLeftIcon
};
