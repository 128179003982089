import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Container, Link, Stack, Text } from '@chakra-ui/react'

import { BlogProps } from '../../app/@types/blog'

type ParagraphProps = BlogProps & {
  index?: number
  linked?: boolean
}

export const Paragraph = observer((props: ParagraphProps) => {
  const { t } = useTranslation()

  let index = props.index
  if (typeof props.index == 'undefined') {
    index = 0
  }

  return (
    <Box as="section">
      <Container py={{ base: '6', md: '8' }} maxW="5xl">
        <Stack spacing={{ base: '6', md: '6' }}>
          {t(`blog.${props.viewModel.name}.paragraph.${index}.title`) && (
            <Text
              fontSize={{ base: 'lg', md: '3xl' }}
              fontWeight="bold"
              lineHeight="1.5"
            >
              {t(`blog.${props.viewModel.name}.paragraph.${index}.title`)}
            </Text>
          )}
          <Text fontSize={{ base: 'md', md: '2xl' }} lineHeight="1.25">
            {props.linked ? (
              <Trans
                i18nKey={`blog.${props.viewModel.name}.paragraph.${index}.text`}
              >
                {props.viewModel.linkedText(index as number)['text']}
                <Link textDecoration={'underline'}>here</Link>.
              </Trans>
            ) : (
              t(`blog.${props.viewModel.name}.paragraph.${index}.text`)
            )}
          </Text>
        </Stack>
      </Container>
    </Box>
  )
})
