import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Center, Divider, Heading, Text } from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PageProps } from '../../app/@types/page'

export const MoreContent = observer((props: PageProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box as="section" py="6" position="relative" bgPosition="center">
        {[...Array(props.pageViewModel.numberOfSections)].map((_, i) => (
          <Box
            maxW={{ base: 'xl', md: '7xl' }}
            mx="auto"
            px={{ base: '16', md: '24' }}
            py={{ base: '6', md: '8' }}
            h="full"
          >
            <Heading as="h3" size="xs">
              {t(
                `pages.${props.pageViewModel.name}.content.sections.${i}.heading`
              )}
            </Heading>
            <Text fontSize="xl">
              {t(
                `pages.${props.pageViewModel.name}.content.sections.${i}.text`
              )}
            </Text>
          </Box>
        ))}
      </Box>
      <Box as="section" py="6">
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '16', md: '24' }}
          h="full"
        >
          <Divider />
          <Text fontSize="3xl" py={{ base: '6', md: '8' }}>
            {t(`pages.${props.pageViewModel.name}.cta.text`)}
          </Text>
          <Center
            flexDirection="column"
            textAlign="center"
            color="white"
            h="full"
            my={8}
          >
            <Button as={Link} to="/auth/signup" variant="primary" size="lg">
              {t(`pages.${props.pageViewModel.name}.cta.primary`)}
            </Button>
          </Center>
        </Box>
      </Box>
    </>
  )
})

export const PageContent = observer((props: PageProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box
        as="section"
        py="6"
        position="relative"
        bgSize="cover"
        bgPosition="center"
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center
            flexDirection="column"
            textAlign="center"
            color="black"
            h="full"
          >
            <Heading as="h2" size="md" fontWeight="bold">
              {t(`pages.${props.pageViewModel.name}.heading`)}
            </Heading>
          </Center>
        </Box>
        {[...Array(props.pageViewModel.numberOfSections)].map((_, i) => (
          <Box
            maxW={{ base: 'xl', md: '7xl' }}
            mx="auto"
            px={{ base: '16', md: '24' }}
            py={{ base: '6', md: '8' }}
            h="full"
          >
            <Heading as="h3" size="xs">
              {t(
                `pages.${props.pageViewModel.name}.content.sections.${i}.heading`
              )}
            </Heading>
            <Text>
              {t(
                `pages.${props.pageViewModel.name}.content.sections.${i}.text`
              )}
            </Text>
          </Box>
        ))}
      </Box>
    </>
  )
})
