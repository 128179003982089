import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FiMenu } from 'react-icons/fi'

import {
  Box,
  Button,
  ButtonGroup,
  Link as ChakraLink,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { BlogProps } from '../../app/@types/blog'
import { Logo } from './../../images/Logo'

export const NavBar = observer((props: BlogProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const { t } = useTranslation()
  return (
    <Box as="section" bgSize="cover">
      <Box as="nav">
        <Container maxW="1200px" py={{ base: '6', md: '8' }}>
          <HStack spacing="10" justify="space-between">
            <Logo height="75px" />
            {isDesktop ? (
              <>
                <ButtonGroup variant="link" spacing="8">
                  <Button
                    fontSize="1.5rem"
                    onClick={() => props.viewModel.documentation()}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.navbar.documentation`)}
                  </Button>
                  <Button
                    as={ChakraLink}
                    fontSize="1.5rem"
                    href="/pages/pricing"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {t(`posters.common.navbar.pricing`)}
                  </Button>
                </ButtonGroup>
                <HStack spacing="3">
                  <Button
                    as={Link}
                    fontSize="1.125rem"
                    to="/auth/signin"
                    variant="secondary"
                  >
                    {t(`posters.common.navbar.signIn`)}
                  </Button>
                  <Button
                    as={Link}
                    fontSize="1.125rem"
                    to="/auth/signup"
                    variant="primary"
                  >
                    {t(`posters.common.navbar.signUp`)}
                  </Button>
                </HStack>
              </>
            ) : (
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<FiMenu fontSize="1.25rem" />}
                  variant="secondary"
                  aria-label="Open Menu"
                />
                <MenuList>
                  <MenuItem
                    as="a"
                    onClick={() => props.viewModel.documentation()}
                  >
                    {t(`posters.common.navbar.documentation`)}
                  </MenuItem>
                  <MenuItem as="a" href="/#pricing">
                    {t(`posters.common.navbar.pricing`)}
                  </MenuItem>
                  <MenuItem as="a" href="/auth/signin">
                    {t(`posters.common.navbar.signIn`)}
                  </MenuItem>
                  <MenuItem as="a" href="/auth/signup">
                    {t(`posters.common.navbar.signUp`)}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  )
})
