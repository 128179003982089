import LeaderLine from 'leader-line-new'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlay } from 'react-icons/fa'

import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Heading,
  Highlight,
  Stack,
  Text,
  VisuallyHidden,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'
import { CodeEditor } from './CodeEditor'

const featureBranch = [
  {
    padding: 0,
    value: 'class LinkedListNode:',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: 'def __init__(self, value):',
  },
  {
    padding: 80,
    value: 'self._next = None',
  },
  {
    padding: 80,
    value: 'self._value = value',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: '@property',
  },
  {
    padding: 40,
    value: 'def next(self):',
  },
  {
    padding: 80,
    value: 'return self._next',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: '@next.setter',
  },
  {
    padding: 40,
    value: 'def next(self, value):',
  },
  {
    padding: 80,
    value: 'self._next = value',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: '@property',
  },
  {
    padding: 40,
    value: 'def value(self):',
  },
  {
    padding: 80,
    value: 'return self._value',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: ' ',
  },
]

const mainBranch = [
  {
    padding: 0,
    value: 'from linked_list_node import LinkedListNode',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: 'class LinkedList:',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: 'def __init__(self):',
  },
  {
    padding: 80,
    value: 'self._head = None',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: 'def insert(self, value):',
  },
  {
    padding: 80,
    value: 'new_node = LinkedListNode(value)',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 80,
    value: 'if self._head is None:',
  },
  {
    padding: 120,
    value: 'self._head = new_node',
  },
  {
    padding: 120,
    value: 'return',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 80,
    value: 'current = self._head',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 80,
    value: 'while current.next:',
  },
  {
    padding: 120,
    value: 'current = current.next',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 80,
    value: 'current.next = new_node',
  },
]

export const HeroWithImage = observer((props: PosterProps) => {
  const [isPlaying, setIsPlaying] = React.useState(false)

  const { t } = useTranslation()

  const branchLeftPositionBreakpointValues = useBreakpointValue({
    base: '50%',
    md: '75%',
  })
  const branchTopPositionBreakpointValues = useBreakpointValue({
    base: '75%',
    md: '50%',
  })
  const mainLeftPositionBreakpointValues = useBreakpointValue({
    base: '50%',
    md: '25%',
  })
  const mainTopPositionBreakpointValues = useBreakpointValue({
    base: '40%',
    md: '50%',
  })

  React.useEffect(() => {
    setTimeout(() => {
      try {
        new LeaderLine(
          document.getElementById('text'),
          document.getElementById('main'),
          {
            color: 'teal', //'#2d3748'
            path: 'arc',
            startPlug: 'disc',
            startSocket: 'left',
            endSocket: 'left',
          }
        )
        new LeaderLine(
          document.getElementById('text'),
          document.getElementById('branch'),
          {
            color: 'teal', //'#2d3748'
            startPlug: 'disc',
            startSocket: 'right',
            endSocket: 'right',
          }
        )
      } catch {
        const lines = document.getElementsByClassName('leader-line')
        lines.length > 0 && lines[0].remove()
        lines.length > 0 && lines[0].remove()
      }
    }, 500)

    return () => {
      const lines = document.getElementsByClassName('leader-line')
      lines.length > 0 && lines[0].remove()
      lines.length > 0 && lines[0].remove()
    }
  }, [])

  const onCode = () => {
    setIsPlaying(true)

    props.viewModel.didViewHero()
  }

  return (
    <Box>
      <Box as="section">
        <Box
          maxW={{ base: 'xl', md: '6xl' }}
          mx="auto"
          my={{ base: '6', md: '8' }}
          px={{ base: '6', md: '8' }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
              fontWeight="bold"
              mx="auto"
              lineHeight="1.25"
              letterSpacing="tight"
            >
              <Highlight
                query={['days', 'thousands of dollars']}
                styles={{ px: '1', py: '0', rounded: '8px', bg: 'red.200' }}
              >
                {t(`posters.${props.viewModel.name}.hero.heading`) as string}
              </Highlight>
            </Heading>
            <Text fontSize="3xl" lineHeight="1.25" mx="auto">
              {t(`posters.${props.viewModel.name}.hero.subheading`)}
            </Text>
            <Center>
              <HStack my={12} spacing="3">
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/pages/productivity"
                  variant="secondary"
                >
                  {t(`posters.${props.viewModel.name}.hero.actions.secondary`)}
                </Button>
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/auth/signup"
                  variant="primary"
                >
                  {t(`posters.${props.viewModel.name}.hero.actions.primary`)}
                </Button>
              </HStack>
            </Center>
          </Box>
          <Box
            maxW="4xl"
            className="group"
            position="relative"
            overflow="hidden"
            mx="auto"
            px="2"
          >
            <Box
              id="main"
              position="absolute"
              top={mainTopPositionBreakpointValues}
              left={mainLeftPositionBreakpointValues}
            />
            <Box
              id="branch"
              position="absolute"
              top={branchTopPositionBreakpointValues}
              left={branchLeftPositionBreakpointValues}
            />
            <Stack
              alignItems="initial"
              direction={{ base: 'column', md: 'row' }}
            >
              <CodeEditor
                branch="feat/linked-list"
                filename="linked_list.py"
                isLast={true}
                lines={mainBranch}
                prefix="ll"
                setStatus={setIsPlaying}
                status={isPlaying}
              />
              <CodeEditor
                branch="feat/linked-list-node"
                filename="linked_list_node.py"
                isLast={false}
                lines={featureBranch}
                prefix="lln"
                setStatus={setIsPlaying}
                status={isPlaying}
              />
            </Stack>
            <Circle
              hidden={isPlaying}
              size="20"
              as="button"
              bg="white"
              shadow="lg"
              color="gray.600"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate3d(-50%, -50%, 0)"
              fontSize="xl"
              transition="all 0.2s"
              onClick={onCode}
              _groupHover={{
                transform: 'translate3d(-50%, -50%, 0) scale(1.05)',
              }}
            >
              <VisuallyHidden>Play video</VisuallyHidden>
              <FaPlay />
            </Circle>
          </Box>
        </Box>
        <Text
          id="text"
          fontSize="md"
          fontFamily="Comic Neue, cursive"
          lineHeight={5}
          maxW="sm"
          mt={8}
          mx="auto"
          textAlign="center"
        >
          <Highlight query={['same time']} styles={{ fontStyle: 'italic' }}>
            {t(`posters.${props.viewModel.name}.hero.image.text`) as string}
          </Highlight>
        </Text>
      </Box>
    </Box>
  )
})
