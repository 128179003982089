import { observer } from 'mobx-react-lite'
import React from 'react'
import {
  Children,
  ReactElement,
  cloneElement,
  isValidElement,
  useMemo,
} from 'react'

import {
  Box,
  BoxProps,
  Checkbox,
  Stack,
  StackProps,
  UseCheckboxGroupProps,
  UseCheckboxProps,
  useCheckbox,
  useCheckboxGroup,
  useId,
  useStyleConfig,
} from '@chakra-ui/react'

type CheckboxCardGroupProps = StackProps & UseCheckboxGroupProps

export const CheckboxCardGroup = observer((props: CheckboxCardGroupProps) => {
  const { children, defaultValue, value, onChange, ...rest } = props
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue,
    value,
    onChange,
  })

  const cards = useMemo(
    () =>
      Children.toArray(children)
        .filter<ReactElement<RadioCardProps>>(isValidElement)
        .map(card => {
          return cloneElement(card, {
            checkboxprops: getCheckboxProps({
              value: card.props.value,
            }),
          })
        }),
    [children, getCheckboxProps]
  )

  return (
    <Stack overflowY="scroll" {...rest}>
      {cards}
    </Stack>
  )
})

type RadioCardProps = {
  checkboxprops?: UseCheckboxProps
  value: string
} & BoxProps

export const CheckboxCard = observer((props: RadioCardProps) => {
  const { checkboxprops, children, ...rest } = props
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useCheckbox(checkboxprops)
  const id = useId(undefined, 'checkbox-card')
  const styles = useStyleConfig('RadioCard', props)

  return (
    <Box
      as="label"
      cursor="pointer"
      {...getLabelProps()}
      sx={{
        '.focus-visible + [data-focus]': {
          boxShadow: 'outline',
          zIndex: 1,
        },
      }}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <Box sx={styles} {...getCheckboxProps()} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          <Checkbox
            colorScheme="gray"
            pointerEvents="none"
            isFocusable={false}
            isChecked={state.isChecked}
            alignSelf="start"
          />
        </Stack>
      </Box>
    </Box>
  )
})
