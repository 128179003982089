import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { ExternalViewProps } from '../../@types/external'

export const Authenticate = observer((props: ExternalViewProps) => {
  const address: string = props.viewModel.accountStore.account?.account
    .nextAction?.redirect_to_url?.url as string

  window.location = address

  return <></>
})
