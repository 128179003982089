import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  As,
  Button,
  ButtonProps,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

type NavigationButtonProps = {
  icon: As
  label: string
} & ButtonProps

export const NavigationButton = observer((props: NavigationButtonProps) => {
  const { icon, label, ...buttonProps } = props
  const displayBreakpointValue = useBreakpointValue({
    base: 'none',
    md: 'display',
  })
  const justifyBreakpointValue = useBreakpointValue({
    base: 'center',
    md: 'start',
  })

  return (
    <Button
      variant="ghost"
      justifyContent={justifyBreakpointValue}
      {...buttonProps}
      leftIcon={<Icon as={icon} boxSize="6" />}
    >
      <Text display={displayBreakpointValue}>{label}</Text>
    </Button>
  )
})
