import { TFunction } from 'i18next'
import _ from 'lodash'
import { computed, makeObservable, observable } from 'mobx'
import * as React from 'react'
import {
  FiBox,
  FiCpu,
  FiEdit2,
  FiRepeat,
  FiShare,
  FiTrash2,
} from 'react-icons/fi'
import { IoMdCopy } from 'react-icons/io'
import { VscBrowser } from 'react-icons/vsc'

import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { GetAccountMembersResponse } from '../../@types/account'
import { ApplicationError, ApplicationNotice } from '../../@types/application'
import {
  Instance,
  InstanceMember,
  InstanceTemplate,
  InstanceUpdate,
  NewInstanceFormInput,
  ShareInstanceFormInput,
  ShareInstanceResponse,
} from '../../@types/instance'
import { AccountStore } from '../../stores/accountStore'
import { ApplicationStore } from '../../stores/applicationStore'
import { ContainerStore } from '../../stores/containerStore'
import { InstanceStore } from '../../stores/instanceStore'
import { WatchStore } from '../../stores/watchStore'

export class InstanceViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  containerStore: ContainerStore
  instanceStore: InstanceStore
  templates: InstanceTemplate[]
  watchStore: WatchStore
  _instanceDetails?: string
  _instanceType?: string
  _currentInstance?: Instance = undefined
  _error: ApplicationError | undefined
  _isLoading = false
  _isProgressing = false
  _members: InstanceMember[] = []
  _notice: ApplicationNotice | undefined
  _onCloseAction: () => void = () => {
    return null
  }
  _timezone: string | undefined
  _timezoneOffset: number | undefined
  _users: string[] = []

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    containerStore: ContainerStore,
    instanceStore: InstanceStore,
    watchStore: WatchStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.containerStore = containerStore
    this.instanceStore = instanceStore
    this.watchStore = watchStore

    this.templates = []
    makeObservable(this, {
      accountHasOrganization: computed,
      activeInstances: computed,
      currentInstance: computed,
      error: computed,
      instanceDetails: computed,
      instances: computed,
      instanceType: computed,
      isLoading: computed,
      isProgressing: computed,
      members: computed,
      notice: computed,
      onCloseAction: computed,
      plan: computed,
      timezone: computed,
      timezoneOffset: computed,
      users: computed,
      accountStore: observable,
      applicationStore: observable,
      containerStore: observable,
      instanceStore: observable,
      watchStore: observable,
      _currentInstance: observable,
      _error: observable,
      _instanceDetails: observable,
      _instanceType: observable,
      _isLoading: observable,
      _isProgressing: observable,
      _members: observable,
      _notice: observable,
      _onCloseAction: observable,
      _timezone: observable,
      _timezoneOffset: observable,
      _users: observable,
      baseImages: false,
      containerTypes: false,
      getAccountMembers: false,
      createInstance: false,
      deleteInstance: false,
      deleteInstanceFromSession: false,
      getInstances: false,
      getInstanceState: false,
      restoreInstance: false,
      hoursFromSchedule: false,
      hoursToSchedule: false,
      operatingSystems: false,
      hasAddress: false,
      hasSnapshot: false,
      instanceTypes: false,
      isActive: false,
      scheduleForInstance: false,
      setInstanceState: false,
      shareInstance: false,
      tableColumns: false,
      tableData: false,
      templates: false,
      updateInstance: false,
      watchInstances: false,
    })
  }

  get accountHasOrganization(): boolean {
    return this.watchStore.accountHasOrganization
  }

  get activeInstances(): Instance[] {
    return _.filter(this.instanceStore.instances, instance => {
      return ['paused', 'running', 'stopped'].includes(instance.status)
    })
  }

  get baseImages(): { name: string; value: string }[] {
    return [
      {
        name: 'Ubuntu',
        value: 'ubuntu:jammy',
      },
    ]
  }

  get containerTypes(): { name: string; value: string }[] {
    let containerTypes = [
      {
        name: '1 CPU, 2GB RAM, 128GB',
        value: '1:2:128',
      },
      {
        name: '2 CPU, 4GB RAM, 128GB',
        value: '2:4:128',
      },
    ]
    const planType = this.plan
    if (typeof planType != 'undefined') {
      if (planType == 'free') {
        containerTypes = [
          {
            name: '0.25 CPU, 0.5GB RAM, 20GB',
            value: '0.5:1:20',
          },
        ]
      }
    }

    return containerTypes
  }

  get currentInstance(): Instance | undefined {
    return this._currentInstance
  }

  set currentInstance(instanceId: string | Instance | undefined) {
    this.instanceStore.instances.forEach(instance => {
      if (instance._id == instanceId) {
        this._currentInstance = instance

        this.currentInstance?.users.forEach(user => {
          this.users.push(user.id)
        })

        this.instanceTypes.forEach(instanceType => {
          if (instanceType.value == this.currentInstance?.instanceType) {
            this.instanceType = instanceType.name
          }
        })
      }
    })
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get instanceDetails(): string | undefined {
    return this._instanceDetails
  }

  public set instanceDetails(value: string | undefined) {
    this._instanceDetails = value
  }

  get instances(): Instance[] {
    return this.instanceStore.instances.filter(value => {
      // Remove deleted instances that do not have snaphots to restore from
      if (['deleted'].includes(value.status) && !value.snapshotId) {
        return false
      }

      return true
    })
  }

  get instanceType(): string | undefined {
    return this._instanceType
  }

  set instanceType(value: string | undefined) {
    this._instanceType = value
  }

  get instanceTypes(): { name: string; value: string }[] {
    let instanceTypes = [
      {
        name: '1 CPU, 2GB RAM, 16GB SSD',
        value: 't2.micro',
      },
      {
        name: '2 CPU, 4GB RAM, 256GB SSD',
        value: 't3.medium',
      },
    ]
    const planType = this.plan
    if (typeof planType != 'undefined') {
      if (planType == 'free') {
        instanceTypes = [
          {
            name: '1 CPU, 2GB RAM, 16GB SSD',
            value: 't2.micro',
          },
        ]
      }
    }

    return instanceTypes
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  get isProgressing() {
    return this._isProgressing
  }

  set isProgressing(value: boolean) {
    this._isProgressing = value
  }

  get members(): InstanceMember[] {
    return this._members
  }

  set members(value: InstanceMember[]) {
    this._members = value
  }

  get notice(): ApplicationNotice | undefined {
    return this._notice
  }

  set notice(value: ApplicationNotice | undefined) {
    this._notice = value
  }

  get onCloseAction(): () => void | undefined {
    return this._onCloseAction
  }

  set onCloseAction(value: () => void | undefined) {
    this._onCloseAction = value
  }

  get operatingSystems(): { name: string; value: string }[] {
    return [
      {
        name: 'Amazon Linux 2023',
        value: 'amzn_linux_2023',
      },
      {
        name: 'Ubuntu 22.04 LTS',
        value: 'ubuntu_22_04_lts',
      },
    ]
  }

  get plan(): string | undefined {
    return this.applicationStore.accountStore.plan
  }

  public get timezone(): string | undefined {
    if (typeof this._timezone == 'undefined') {
      this._timezone = Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.split('/')[1]
        .split('_')
        .join(' ')
    }
    return this._timezone
  }

  public get timezoneOffset(): number | undefined {
    if (typeof this._timezoneOffset == 'undefined') {
      this._timezoneOffset = new Date().getTimezoneOffset()
    }
    return this._timezoneOffset
  }

  public get users(): string[] {
    return this._users
  }

  getInstanceState(
    instanceId: string
  ):
    | 'deleting'
    | 'deploying'
    | 'pending_usage'
    | 'running'
    | 'stopped'
    | 'terminated' {
    this.instanceStore.instances.forEach(instance => {
      if (instance._id == instanceId) {
        return instance.status
      }
    })

    return 'running'
  }

  hasAddress(instance: Instance): boolean {
    return instance.states && (instance.states.Network.status as boolean)
  }

  hasSnapshot(instance: Instance): boolean {
    return !!instance.snapshotId
  }

  hoursFromSchedule(schedule: string): [string, string] {
    if (schedule == null) {
      return ['08:00', '17:00']
    }

    const scheduleComponents = schedule.split('')
    if (scheduleComponents.length == 0) {
      return ['08:00', '17:00']
    }
    let startTime = -1
    let endTime = -1
    let currentValue = '0'

    for (let i = 0; i <= scheduleComponents.length; i++) {
      const currentIndexValue = scheduleComponents[i]
      if (currentValue != currentIndexValue) {
        if (startTime < 0) {
          startTime = i
        } else {
          endTime = i
          break
        }

        currentValue = scheduleComponents[i]
      }
    }

    const startTimeString =
      startTime > 9 ? `${startTime}:00` : `0${startTime}:00`

    const endTimeString = endTime > 9 ? `${endTime}:00` : `0${endTime}:00`

    return [startTimeString, endTimeString]
  }

  hoursToSchedule(startTime: string, endTime: string) {
    const startTimeComponents = startTime.split(':')
    const endTimeComponents = endTime.split(':')
    const startHour = +startTimeComponents[0]
    const endHour = +endTimeComponents[0]

    const scheduleComponents = []
    for (let i = 0; i < 24; i++) {
      if (i < startHour || i >= endHour) {
        scheduleComponents.push('0')
      }
      if (i >= startHour && i < endHour) {
        scheduleComponents.push('1')
      }
    }

    const offsetIndex = this.timezoneOffset && -this.timezoneOffset / 60
    const offsetScheduleComponents = [
      ...scheduleComponents.slice(offsetIndex, scheduleComponents.length),
      ...scheduleComponents.slice(0, offsetIndex),
    ]

    const schedule = offsetScheduleComponents.join('')
    return schedule
  }

  isActive(instance: Instance): boolean {
    return ['paused', 'running', 'stopped'].includes(instance.status)
  }

  scheduleForInstance(schedule: string): [string, string] {
    const scheduleComponents = schedule?.split('') || []

    const offsetIndex = this.timezoneOffset && this.timezoneOffset / 60
    const offsetScheduleComponents = [
      ...scheduleComponents.slice(offsetIndex, scheduleComponents.length),
      ...scheduleComponents.slice(0, offsetIndex),
    ]

    const [startTime, endTime] = this.hoursFromSchedule(
      offsetScheduleComponents.join('')
    )
    return [startTime, endTime]
  }

  setInstanceState(
    instanceId: string,
    status:
      | 'deleting'
      | 'deploying'
      | 'pending_usage'
      | 'running'
      | 'stopped'
      | 'terminated'
  ) {
    this.instanceStore.instances.forEach(instance => {
      if (instance._id == instanceId) {
        instance.status = status
      }
    })
  }

  watchInstances() {
    if (
      _.some(this.instanceStore.instances, ['status', 'deleting']) ||
      _.some(this.instanceStore.instances, ['status', 'deploying'])
    ) {
      const id = setInterval(() => {
        this.getInstances(true)
      }, 15000)

      return () => clearInterval(id)
    }
  }

  // Accounts

  async getAccountMembers() {
    this.isLoading = true
    return await this.accountStore
      .getAccountMembers()
      .then((data: unknown) => {
        this.members = (data as GetAccountMembersResponse).users
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  // Instances

  async createInstance(values: NewInstanceFormInput) {
    this.isLoading = true

    if (values.spaceType == 'fargate') {
      return await this.containerStore
        .createContainer(values)
        .then(() => {
          this.isProgressing = true
          this.getInstances(true)
        })
        .catch((error: ApplicationError) => {
          this.isLoading = false
          this.error = error
        })
    }

    return await this.instanceStore
      .createInstance(values)
      .then(() => {
        this.isProgressing = true
        this.getInstances(true)
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  async deleteInstance(id: string) {
    if (this.currentInstance?.spaceType == 'fargate') {
      return await this.containerStore
        .deleteContainer(id)
        .then(() => {
          this.setInstanceState(id, 'deleting')
          this.getInstances(true)
        })
        .catch((error: ApplicationError) => {
          this.error = error
        })
    }

    return await this.instanceStore
      .deleteInstance(id)
      .then(() => {
        this.getInstances(true)
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async deleteInstanceFromSession(session: string) {
    this.notice = {
      message: 'requests.instances.deleteSession.notice.message',
    }
    return await this.instanceStore
      .deleteInstanceFromSession(session)
      .then(() => {
        this.getInstances(true)
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async getInstances(isProgressing = false) {
    if (isProgressing) {
      this.isProgressing = isProgressing
    } else {
      this.isLoading = true
      this.isProgressing = false
    }
    await this.instanceStore
      .getInstances()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async shareInstance(
    instanceId: string,
    values: ShareInstanceFormInput
  ): Promise<void> {
    this.setInstanceState(instanceId, 'deploying') // Trigger progress indicator

    if (
      ['devcontainer', 'ec2'].includes(
        this.currentInstance?.spaceType as string
      )
    ) {
      this.instanceStore
        .shareInstance(instanceId, values.members)
        .then((data: unknown) => {
          if (values.members.length) {
            this.notice = {
              message: 'requests.instances.share.notice.message',
            }
          } else {
            this.notice = {
              message: 'requests.instances.unshare.notice.message',
            }
          }

          this.instances.forEach((instance: Instance) => {
            if (instance._id == instanceId) {
              instance.users = (data as ShareInstanceResponse).users
            }
          })

          this.getInstances(true)
        })
        .catch((error: ApplicationError) => {
          this.error = error
        })
    } else {
      this.containerStore
        .shareContainer(instanceId, values.members)
        .then((data: unknown) => {
          if (values.members.length) {
            this.notice = {
              message: 'requests.instances.share.notice.message',
            }
          } else {
            this.notice = {
              message: 'requests.instances.unshare.notice.message',
            }
          }

          this.instances.forEach((instance: Instance) => {
            if (instance._id == instanceId) {
              instance.users = (data as ShareInstanceResponse).users
            }
          })

          this.getInstances(true)
        })
        .catch((error: ApplicationError) => {
          this.error = error
        })
    }
  }

  async updateInstance(
    instanceId: string,
    values: InstanceUpdate
  ): Promise<void> {
    const closeAction = this.onCloseAction
    closeAction()

    this.setInstanceState(instanceId, 'deploying')

    await this.instanceStore
      .updateInstance(instanceId, values)
      .then(() => {
        this.notice = {
          message: 'requests.instances.update.notice.message',
        }

        this.watchInstances()
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async restoreInstance(instanceId: string): Promise<void> {
    await this.instanceStore
      .restoreInstance(instanceId)
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  // UI

  tableColumns(
    t: TFunction<'translation', undefined>,
    onCopyInstanceDetails: (
      event: unknown,
      instanceDetails: {
        ipAddress?: string
        port?: string
        spaceId?: string
        spaceType?: string
      }
    ) => void,
    onRestoreInstance: (event: unknown, instanceId: string) => void,
    onShareInstance: (event: unknown, instanceId: string) => void,
    onShowInstance: (event: unknown, instanceId: string) => void,
    onDeleteInstance: (event: unknown, instanceId: string) => void
  ): ColumnDef<Instance, string | any>[] {
    const willShareInstances =
      this.applicationStore.featureFlagFor('willShareInstances')

    const columnHelper = createColumnHelper<Instance>()
    return [
      columnHelper.accessor('spaceType', {
        header: () => '',
        cell: props => (
          <Icon
            as={
              props.row.original.spaceType == 'devcontainer'
                ? FiBox
                : props.row.original.spaceType == 'ec2'
                ? FiCpu
                : VscBrowser
            }
          />
        ),
        size: 16,
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: props => (
          <Stack>
            <Text fontWeight="medium">{props.row.original.name}</Text>
            {!['deleted', 'deploying', 'pending_usage'].includes(
              props.row.original.status
            ) ? (
              <VStack align="left" spacing={1}>
                <HStack>
                  <Text color="muted" fontSize="xs">
                    {t('instances.index.table.instance.cli')}
                  </Text>
                  <IconButton
                    aria-label="Connect using CLI"
                    as={IoMdCopy}
                    onClick={event =>
                      onCopyInstanceDetails(event, {
                        spaceId: props.row.original._id,
                        spaceType: props.row.original.spaceType,
                      })
                    }
                    minWidth={3}
                    width={3}
                    height={4}
                    background="none"
                    cursor="pointer"
                  />
                </HStack>
                {this.hasAddress(props.row.original) &&
                  props.row.original.spaceType != 'devcontainer' && (
                    <HStack>
                      <Text color="muted" fontSize="xs">
                        {t('instances.index.table.instance.browser')}
                      </Text>
                      <IconButton
                        aria-label="Connect using Browser"
                        as={IoMdCopy}
                        onClick={event =>
                          onCopyInstanceDetails(event, {
                            ipAddress: props.row.original.ipAddress,
                            port: props.row.original.port,
                            spaceType: props.row.original.spaceType,
                          })
                        }
                        minWidth={3}
                        width={3}
                        height={4}
                        background="none"
                        cursor="pointer"
                      />
                    </HStack>
                  )}
              </VStack>
            ) : (
              <></>
            )}
          </Stack>
        ),
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: props =>
          ['deploying', 'deleting'].includes(props.row.original.status) ? (
            <>
              <Badge size="sm" colorScheme="orange">
                {t(`instances.index.table.status.${props.row.original.status}`)}
              </Badge>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray" ml={2}>
                  {t(`instances.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ) : ['deleted'].includes(props.row.original.status) &&
            !!props.row.original.snapshotId ? (
            <>
              <Badge size="sm" colorScheme="gray">
                {t(`instances.index.table.status.${props.row.original.status}`)}
              </Badge>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray" ml={2}>
                  {t(`instances.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ) : !['deleted'].includes(props.row.original.status) ? (
            <>
              <Badge
                size="sm"
                colorScheme={
                  props.row.original.status === 'running'
                    ? 'green'
                    : ['paused', 'stopped'].includes(props.row.original.status)
                    ? 'blue'
                    : 'red'
                }
              >
                {t(`instances.index.table.status.${props.row.original.status}`)}
              </Badge>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray" ml={2}>
                  {t(`instances.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ) : (
            <>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray">
                  {t(`instances.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ),
      }),
      columnHelper.accessor('status', {
        id: 'status2',
        header: () => '',
        cell: props =>
          props.row.original.spaceType == 'fargate' &&
          !['deploying', 'deleting'].includes(props.row.original.status) ? (
            <HStack justify="flex-end" spacing="1">
              {willShareInstances && (
                <Tooltip label={t('instances.index.table.buttons.share.label')}>
                  <IconButton
                    id="pw-share-button"
                    icon={<FiShare fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Share instance"
                    onClick={event =>
                      onShareInstance(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              )}
              <Tooltip label={t('instances.index.table.buttons.delete.label')}>
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete instance"
                  onClick={event =>
                    onDeleteInstance(event, props.row.original._id)
                  }
                />
              </Tooltip>

              {!props.row.original.isShared &&
              ['deploying', 'deleting'].includes(props.row.original.status) ? (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              ) : (
                <></>
              )}
            </HStack>
          ) : !props.row.original.isShared &&
            this.isActive(props.row.original) ? (
            <HStack justify="flex-end" spacing="1">
              <Tooltip label={t('instances.index.table.buttons.edit.label')}>
                <IconButton
                  id="pw-edit-button"
                  icon={<FiEdit2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Edit instance"
                  onClick={event =>
                    onShowInstance(event, props.row.original._id)
                  }
                />
              </Tooltip>
              {willShareInstances && (
                <Tooltip label={t('instances.index.table.buttons.share.label')}>
                  <IconButton
                    id="pw-share-button"
                    icon={<FiShare fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Share instance"
                    onClick={event =>
                      onShareInstance(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              )}
              {this.hasSnapshot(props.row.original) ? (
                <Tooltip
                  label={t('instances.index.table.buttons.restore.label')}
                >
                  <IconButton
                    icon={<FiRepeat fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Restore instance"
                    onClick={event =>
                      onRestoreInstance(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  label={t(
                    'instances.index.table.buttons.restore.pending.label'
                  )}
                >
                  <IconButton
                    aria-label="Restore instance"
                    bgColor="white"
                    color="gray.500"
                    cursor="not-allowed"
                    disabled={true}
                    icon={<FiRepeat fontSize="1.25rem" />}
                    _hover={{
                      background: 'none',
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip label={t('instances.index.table.buttons.delete.label')}>
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete instance"
                  onClick={event =>
                    onDeleteInstance(event, props.row.original._id)
                  }
                />
              </Tooltip>
            </HStack>
          ) : !props.row.original.isShared &&
            ['deploying', 'deleting'].includes(props.row.original.status) ? (
            <HStack justify="flex-end" spacing="1">
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            </HStack>
          ) : !props.row.original.isShared &&
            ['pending_usage', 'deleted'].includes(props.row.original.status) &&
            !!props.row.original.snapshotId ? (
            <HStack justify="flex-end" spacing="1">
              <Tooltip label={t('instances.index.table.buttons.restore.label')}>
                <IconButton
                  icon={<FiRepeat fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Restore instance"
                  onClick={event =>
                    onRestoreInstance(event, props.row.original._id)
                  }
                />
              </Tooltip>
            </HStack>
          ) : (
            <></>
          ),
        enableSorting: false,
      }),
    ]
  }

  get tableData(): Record<string, unknown>[] {
    return this.instances
  }
}
