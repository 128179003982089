import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'
import { useMatch, useNavigate } from '@tanstack/react-location'

import { Spinner } from '../../../components/Spinner'
import { ExternalViewProps } from '../../@types/external'

// http://localhost:8000/?setup_intent=seti_1MOpu4GlFuk7Zz4BYHQ9Fz79&setup_intent_client_secret=seti_1MOpu4GlFuk7Zz4BYHQ9Fz79_secret_N98AOdMCvZUTiCMtcsP1hcvKmupbzDi&redirect_status=succeeded#/external/complete
// http://localhost:8000/?payment_intent=pi_3MOxvwGlFuk7Zz4B1y9iDUxx&payment_intent_client_secret=pi_3MOxvwGlFuk7Zz4B1y9iDUxx_secret_WOpWk78NwfMaFSCK8WqKaDU1T&source_redirect_slug=test_YWNjdF8xTGtqSTdHbEZ1azdaejRCLF9OOUdUaFBIRlNHWldlT1pWa3JRaXJrc0ZKUEJiZHpT0100Hkyz8qfI#/external/complete

export const Complete = observer((props: ExternalViewProps) => {
  const {
    params: { hash },
  } = useMatch()

  const navigate = useNavigate()
  const searchParameters = window.location.search
  const searchEntries = new URLSearchParams(searchParameters)
  const searchObject = Object.fromEntries(searchEntries)

  if (searchObject.setup_intent_client_secret) {
    props.viewModel.confirmSetupIntent(
      searchObject.setup_intent_client_secret,
      hash,
      navigate
    )
  }

  if (searchObject.payment_intent_client_secret) {
    props.viewModel.confirmPaymentIntent(
      searchObject.payment_intent_client_secret,
      navigate
    )
  }

  const { t } = useTranslation()
  const toast = useToast()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
  })

  return <Spinner />
})
