import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Container,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import { AccountBillingViewProps } from '../../app/@types/account'
import { Statistic } from '../Statistic'

export const PlanStatus = observer((props: AccountBillingViewProps) => {
  const { viewModel, ...viewProps } = props
  const [statistics, setStatistics] = React.useState([{}])
  const { t } = useTranslation()

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  React.useEffect(() => {
    if (typeof viewModel.invoice == 'undefined') {
      return
    }
    const values = []
    for (const [key, value] of Object.entries(viewModel.invoice)) {
      if (key != 'periodEnd') {
        values.push({
          label: t(`accounts.billing.plan.status.${key}`),
          value: currencyFormatter.format(value / 100),
        })
      } else {
        const nextPaymentDate = new Date(value * 1000).toLocaleString(
          'default',
          {
            month: 'long',
            day: '2-digit',
          }
        )
        values.push({
          label: t(`accounts.billing.plan.status.${key}`),
          value: nextPaymentDate,
        })
      }
    }

    setStatistics(values)
  }, [viewModel.invoice])

  return (
    <Box as="section" py={{ base: '4', md: '8' }}>
      <Container maxW="full">
        <Box
          bg="bg-surface"
          borderRadius="lg"
          boxShadow={useColorModeValue('sm', 'sm-dark')}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            divider={<StackDivider />}
            spacing="0"
          >
            {statistics.map((statistic, id) => (
              <Statistic key={id} {...viewProps} {...statistic} />
            ))}
          </Stack>
        </Box>
        <Text pt={{ base: '4', md: '8' }} color="muted" fontSize="xs">
          {t('accounts.billing.plan.status.note', {
            planName: viewModel.planName,
          })}
        </Text>
      </Container>
    </Box>
  )
})
