import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Box, HStack, Text } from '@chakra-ui/react'

import { useTimer } from '../../hooks/useTimer'

const expiresInSeconds = new Date(2023, 2, 31, 16).getTime()

export const Timer = observer(() => {
  const { seconds, minutes, hours, days } = useTimer({
    expiresInSeconds,
  })

  return (
    <HStack spacing="4">
      <TimeUnit value={days} label="Days" />
      <TimeUnit value={hours} label="Hours" />
      <TimeUnit value={minutes} label="Minutes" />
      <TimeUnit value={seconds} label="Seconds" />
    </HStack>
  )
})

type Props = {
  label: string
  value: number
}

const TimeUnit = observer((props: Props) => {
  const { value, label } = props
  return (
    <Box textAlign="center">
      <Text
        fontWeight="semibold"
        fontSize={{ base: 'xl', md: '2xl' }}
        lineHeight="1"
      >
        {value.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
      </Text>
      <Text fontSize="xs" color="gray.400">
        {label}
      </Text>
    </Box>
  )
})
