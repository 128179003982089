import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, Stack, useToast } from '@chakra-ui/react'

import { Invoices } from '../../../components/Payment/Invoices'
import { PaymentSection } from '../../../components/Payment/PaymentSection'
import { PlanSection } from '../../../components/Plan/PlanSection'
import { AccountBillingViewProps } from './../../@types/account'

export const AccountBilling = observer((props: AccountBillingViewProps) => {
  React.useEffect(() => {
    props.viewModel.getAccount(true)
    props.viewModel.getAccountUsage()
  }, [])

  const toast = useToast()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
  })

  return (
    <Stack id="pw-account-billing" spacing={4}>
      <PlanSection {...props} />
      <Divider />
      {props.viewModel.hasAdminScope && (
        <>
          <PaymentSection {...props} />
          <Divider />
        </>
      )}
      <Invoices {...props} />
    </Stack>
  )
})
