import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Stack, useColorModeValue } from '@chakra-ui/react'
import { AddressElement, useElements } from '@stripe/react-stripe-js'

import { AccountBillingViewProps } from '../../app/@types/account'
import { StripeBillingInfo } from '../../app/@types/external'
import { ApplicationConstants as Constants } from '../../app/constants'

export const AddressCard = observer((props: AccountBillingViewProps) => {
  const colorModeValue = useColorModeValue('sm', 'sm-dark')
  const elements = useElements()
  const { t } = useTranslation()

  const onAddressChange = async () => {
    if (!elements) {
      return
    }
    const addressElement = elements.getElement('address')

    if (!addressElement) {
      return
    }
    const { complete, value } = await (addressElement as any).getValue()

    if (complete) {
      props.viewModel.address = value as StripeBillingInfo
    } else {
      props.viewModel.address = undefined
    }
  }

  const onSubmit = () => {
    props.onStep && props.onStep(2)
  }

  return (
    <>
      <Box
        as="form"
        bg="bg-surface"
        p={{ base: '4', md: '8' }}
        boxShadow={colorModeValue}
        borderRadius="lg"
      >
        <AddressElement
          onChange={() => onAddressChange()}
          options={{
            autocomplete: {
              mode: 'google_maps_api',
              apiKey: Constants.GOOGLE_MAPS_API_KEY as string,
            },
            blockPoBox: true,
            defaultValues: {
              address: {
                line2: ' ',
                city: ' ',
                country: 'US',
                postal_code: '0',
                state: ' ',
              },
            },
            display: {
              name: 'split',
            },
            mode: 'billing',
          }}
        />
      </Box>
      <Stack direction="row" align="right" justify="flex-end">
        <Button onClick={() => props.onStep && props.onStep(0)} variant="ghost">
          {t('accounts.billing.new.address.form.actions.previous')}
        </Button>
        <Button
          isDisabled={!props.viewModel.address}
          onClick={onSubmit}
          variant="primary"
        >
          {t('accounts.billing.new.address.form.actions.next')}
        </Button>
      </Stack>
    </>
  )
})
