import { Plan } from './@types/account'

export const ApplicationConstants = {
  API_HEADER: `application/vnd.venv.${process.env.API_ENVIRONMENT}v1+json`,
  API_URI: `https://api.${process.env.API_ENVIRONMENT}pairspaces.com/v1`,
  APPLICATION_ERROR: 'application_error',
  COGNITO: {
    USERNAME: 'cognito:username',
  },
  CONFIRM_CODE: 'CONFIRM_CODE' as string,
  DOCUMENTATION_URL: `https://${process.env.ENVIRONMENT}docs.pairspaces.com`,
  EMAIL_REGEX: /^[\w\-+.]+@([\w-]+\.)+[\w-]{2,4}$/,
  EVENTS: {
    CREATE_INSTANCE: 'Instance',
    DEFAULT_EVENT_SOURCE: 'venv.web',
  },
  FAQ_LENGTH: 4,
  FEATURES: [
    {
      category: 'features',
      items: [
        { name: 'zerotrust' },
        {
          name: 'collaboration',
          tooltip: true,
        },
        {
          name: 'integration',
          tooltip: true,
        },
        {
          name: 'compute',
        },
        {
          name: 'ips',
          tooltip: true,
        },
        // {
        //   name: 'network',
        // },
        {
          name: 'schedule',
          tooltip: true,
        },
        {
          name: 'regions',
          tooltip: true,
        },
        {
          name: 'cloud',
        },
        {
          name: 'onboard',
          tooltip: true,
        },
        {
          name: 'sso',
          tooltip: true,
        },
      ],
    },
    {
      category: 'support',
      items: [{ name: 'documentation' }, { name: 'email' }],
    },
  ],
  GITHUB_OAUTH_CLIENT_ID: process.env.GITHUB_OAUTH_CLIENT_ID,
  GITHUB_OAUTH_REDIRECT_URI: process.env.GITHUB_OAUTH_REDIRECT_URI,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  HEADERS: {
    AUTHORIZATION: 'Authorization',
    CONTENT_TYPE: 'content-type',
    CORRELATION_ID: 'X-Correlation-Id',
    X_VENV_API_VERSION: 'X-Venv-ApiVersion',
  },
  HTTP_ERROR: 'http_error',
  HTTP_METHODS: {
    DELETE: 'DELETE',
    GET: 'GET',
    HEAD: 'HEAD',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT',
  },
  LOG_LEVEL: {
    DEBUG: 'debug',
    ERROR: 'error',
    EXCEPTION: 'exception',
    INFO: 'info',
    WARN: 'warn',
  },
  PLANS: [
    {
      features: {
        backups: false,
        cloud: false,
        collaboration: true,
        compute: '1CPU, 2GB RAM, 16GB SSD',
        documentation: true,
        email: false,
        integration: true,
        ips: false,
        network: 'Private',
        onboard: false,
        regions: false,
        schedule: false,
        security: false,
        sso: false,
        zerotrust: true,
      },
      name: 'free',
      prices: [
        {
          currency: 'USD',
          id: '',
          product: '',
          unitAmount: '0',
          recurring: { interval: 'month' },
        },
        {
          currency: 'USD',
          id: '',
          product: '',
          unitAmount: '0',
          recurring: { interval: 'year' },
        },
      ],
      product: {
        id: '',
        name: 'PairSpaces Free',
        description: 'Pair securely in your own development environment.',
      },
    },
    {
      features: {
        backups: 'Up to 7 days',
        cloud: false,
        collaboration: true,
        compute: 'All the compute you need',
        documentation: true,
        email: true,
        integration: true,
        ips: true,
        network: 'Private',
        onboard: false,
        regions: false,
        schedule: true,
        security: false,
        sso: false,
        zerotrust: true,
      },
      isPopular: true,
      name: 'organization',
      prices: [
        {
          currency: 'USD',
          id: 'price_1NS1KQGlFuk7Zz4BUCUfuDmT',
          product: 'prod_OEUJdULQCtjyMv',
          recurring: {
            aggregateUsage: null,
            interval: 'month',
            usageType: 'licensed',
          },
          unitAmount: '4000',
        },
        {
          currency: 'USD',
          id: 'price_1NS1KRGlFuk7Zz4BFByIksFp',
          product: 'prod_OEUJdULQCtjyMv',
          recurring: {
            aggregateUsage: null,
            interval: 'year',
            usageType: 'licensed',
          },
          unitAmount: '38400',
        },
      ],
      product: {
        description: 'Pair in a private network with all the compute you need',
        id: 'prod_OEUJdULQCtjyMv',
        name: 'PairSpaces Organization',
      },
    },
    {
      features: {
        backups: 'Up to 7 days',
        cloud: true,
        collaboration: true,
        compute: 'All the compute you need',
        documentation: true,
        email: true,
        integration: true,
        ips: true,
        network: 'Private',
        onboard: true,
        regions: true,
        schedule: true,
        security: 'Private network',
        sso: true,
        zerotrust: true,
      },
      name: 'enterprise',
      prices: [
        {
          currency: 'USD',
          id: 'price_1NS1KPGlFuk7Zz4BQFA0KIym',
          product: 'prod_OEUJfdJMN7Y9Fa',
          recurring: {
            aggregateUsage: null,
            interval: 'month',
            usageType: 'licensed',
          },
          unitAmount: '4500',
        },
        {
          currency: 'USD',
          id: 'price_1NS1KQGlFuk7Zz4BVupnJA7J',
          product: 'prod_OEUJfdJMN7Y9Fa',
          recurring: {
            aggregateUsage: null,
            interval: 'year',
            usageType: 'licensed',
          },
          unitAmount: '43200',
        },
      ],
      product: {
        description:
          'Collaborate in a dedicated cloud account with complete control.',
        id: 'prod_OEUJfdJMN7Y9Fa',
        name: 'PairSpaces Enterprise',
      },
    },
  ] as Plan[],
  'PLANS.build': [
    {
      features: {
        backups: false,
        cloud: false,
        collaboration: true,
        compute: '1CPU, 2GB RAM, 16GB SSD',
        documentation: true,
        email: false,
        integration: true,
        ips: false,
        network: 'Private',
        onboard: false,
        regions: false,
        schedule: false,
        security: false,
        sso: false,
        zerotrust: true,
      },
      name: 'free',
      prices: [
        {
          currency: 'USD',
          id: '',
          product: '',
          unitAmount: '0',
          recurring: { interval: 'month' },
        },
        {
          currency: 'USD',
          id: '',
          product: '',
          unitAmount: '0',
          recurring: { interval: 'year' },
        },
      ],
      product: {
        id: '',
        name: 'PairSpaces Free',
        description: 'Pair securely in your own development environment.',
      },
    },
    {
      features: {
        backups: 'Up to 7 days',
        cloud: false,
        collaboration: true,
        compute: 'All the compute you need',
        documentation: true,
        email: true,
        integration: true,
        ips: true,
        network: 'Private',
        onboard: false,
        regions: false,
        schedule: true,
        security: false,
        sso: false,
        zerotrust: true,
      },
      isPopular: true,
      name: 'organization',
      prices: [
        {
          currency: 'USD',
          id: 'price_1NS1C9GlFuk7Zz4Bb0Ivjh5C',
          product: 'prod_OEUAUXZJqEhGmx',
          recurring: {
            aggregateUsage: null,
            interval: 'month',
            usageType: 'licensed',
          },
          unitAmount: '4000',
        },
        {
          currency: 'USD',
          id: 'price_1NS1CAGlFuk7Zz4BNcpQdTE4',
          product: 'prod_OEUAUXZJqEhGmx',
          recurring: {
            aggregateUsage: null,
            interval: 'year',
            usageType: 'licensed',
          },
          unitAmount: '38400',
        },
      ],
      product: {
        description: 'Pair in a private network with all the compute you need',
        id: 'prod_OEUAUXZJqEhGmx',
        name: 'PairSpaces Organization',
      },
    },
    {
      features: {
        backups: 'Up to 7 days',
        cloud: true,
        collaboration: true,
        compute: 'All the compute you need',
        documentation: true,
        email: true,
        integration: true,
        ips: true,
        network: 'Private',
        onboard: true,
        regions: true,
        schedule: true,
        security: 'Private network',
        sso: true,
        zerotrust: true,
      },
      name: 'enterprise',
      prices: [
        {
          currency: 'USD',
          id: 'price_1NS1C8GlFuk7Zz4BmEJjCBBP',
          product: 'prod_OEUAot7brIoJEz',
          recurring: {
            aggregateUsage: null,
            interval: 'month',
            usageType: 'licensed',
          },
          unitAmount: '4500',
        },
        {
          currency: 'USD',
          id: 'price_1NS1C9GlFuk7Zz4BaEw4kHdx',
          product: 'prod_OEUAot7brIoJEz',
          recurring: {
            aggregateUsage: null,
            interval: 'year',
            usageType: 'licensed',
          },
          unitAmount: '43200',
        },
      ],
      product: {
        description:
          'Collaborate in a dedicated cloud account with complete control.',
        id: 'prod_OEUAot7brIoJEz',
        name: 'PairSpaces Enterprise',
      },
    },
  ] as Plan[],
  PLAUSIBLE_DOMAIN: `${process.env.ENVIRONMENT}pairspaces.com`,
  PROMPT_USER: 'PROMPT_USER' as string,
  STEPS: [
    {
      description: 'accounts.billing.new.steps.0.description',
      id: '1',
      title: 'accounts.billing.new.steps.0.title',
    },
    {
      description: 'accounts.billing.new.steps.1.description',
      id: '2',
      title: 'accounts.billing.new.steps.1.title',
    },
    {
      description: 'accounts.billing.new.steps.2.description',
      id: '3',
      title: 'accounts.billing.new.steps.2.title',
    },
  ],
  STRIPE_API_KEY: process.env.STRIPE_API_KEY as string,
  TESTIMONIAL_LENGTH: 3,
} as const
