import { action, makeObservable, observable } from 'mobx'

import { RootStore } from '.'
import { ApplicationError } from '../@types/application'
import { CognitoSession } from '../@types/external'
import { NewRepositoryRequest, Repository } from '../@types/repository'
import { RepositoryService } from '../services/repositoryService'
import { BaseStore } from './baseStore'

export class RepositoryStore extends BaseStore {
  rootStore: RootStore
  repositories: Repository[] = []

  constructor(store: RootStore) {
    super()

    this.rootStore = store
    makeObservable(this, {
      createRepository: action,
      deleteRepository: action,
      getRepositories: action,
      setRepositories: action,
      rootStore: observable,
      repositories: observable,
    })
  }

  async createRepository(values: NewRepositoryRequest): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return RepositoryService.create(values, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.repositories.create.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async deleteRepository(id: string): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return RepositoryService.delete(id, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.repositories.delete.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async getRepositories(): Promise<unknown> {
    const session = await this.rootStore.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    return await RepositoryService.list(jwtToken).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.repositories.get.error.message',
          })
          reject(error)
        }
      }).then((instances: unknown) => {
        this.setRepositories(instances as Repository[])
      })
    })
  }

  setRepositories(value: Repository[]) {
    this.repositories = value
  }
}
