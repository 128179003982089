import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Navigate } from '@tanstack/react-location'

import { Spinner } from '../../../components/Spinner'
import { ViewProps } from '../../@types/auth'

export const Callback = observer((props: ViewProps) => {
  props.viewModel.authStore.currentSession()

  if (props.viewModel.isAuthenticated) {
    props.viewModel.accountStore.updateAccount()
  }

  return !props.viewModel.isAuthenticated ? (
    <Spinner />
  ) : (
    <Navigate to="/instances" />
  )
})
