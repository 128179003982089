import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  CloseButton,
  Container,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-location'

import { ApplicationViewProps } from '../app/@types/application'

export const Banner = observer((props: ApplicationViewProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onUpgradeClick = (event: React.SyntheticEvent) => {
    props.viewModel.closeBanner(navigate)
    event.preventDefault()
  }

  if (!props.viewModel.willShowBanner) {
    return <></>
  }

  return (
    <Box as="section">
      <Box bg="bg-accent" color="on-accent" position="relative">
        <Container py={{ base: '4', md: '3.5' }}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="center"
            spacing={{ base: '0.5', md: '1.5' }}
            pe={{ base: '4', sm: '0' }}
          >
            <Text fontWeight="medium">{t('banner.upgrade.message')}</Text>
            <Text color="on-accent-muted">
              {t('banner.upgrade.action')}{' '}
              <Link onClick={onUpgradeClick}>{t('banner.upgrade.link')}</Link>{' '}
              {t('banner.upgrade.end')}
            </Text>
          </Stack>
          <CloseButton
            position="absolute"
            right="2"
            top="2.5"
            onClick={() => props.viewModel.closeBanner()}
          />
        </Container>
      </Box>
    </Box>
  )
})
