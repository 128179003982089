import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { SessionViewModel } from '../../viewModels/Session'
import { DeleteSession } from '../../views/Session'

export const SessionDeleteProvider = observer(() => {
  const { store } = useStores()
  const viewModel = new SessionViewModel(store.authStore)

  return <DeleteSession viewModel={viewModel} />
})
