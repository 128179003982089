import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import {
  AccountBillingViewModel,
  AccountSettingsViewModel,
} from '../../viewModels/Account'
import { AccountTeamViewModel } from '../../viewModels/Account/AccountTeam'
import { AccountBilling, AccountSettings } from '../../views/Account'
import { AccountTeam } from '../../views/Account/AccountTeam'

export const AccountBillingProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AccountBillingViewModel(
    store.accountStore,
    store.authStore,
    store.instanceStore
  )

  return <AccountBilling viewModel={viewModel} />
})

export const AccountSettingsProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AccountSettingsViewModel(
    store.accountStore,
    store.authStore
  )

  return <AccountSettings viewModel={viewModel} />
})

export const AccountTeamProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new AccountTeamViewModel(
    store.accountStore,
    store.authStore
  )

  return <AccountTeam viewModel={viewModel} />
})
