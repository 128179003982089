import { Amplify, Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import { AmplifyConfigAuth } from './../../amplify/config'
import { usePassword } from './../../hooks/usePassword'
import { CognitoSessionResponse, CognitoUser } from './../@types/external'

Amplify.configure({ ...AmplifyConfigAuth })

export type IAuthService = {
  confirmSignIn: (
    user: unknown,
    code: string
  ) => Promise<CognitoSessionResponse>
  currentSession: () => Promise<unknown>
  signIn: (userName: string) => Promise<unknown>
  signOut: () => Promise<void>
  signUp: (email: string, options: object) => Promise<unknown>
  signUpBitbucket: () => Promise<unknown>
  signUpGitHub: () => Promise<unknown>
  signUpGoogle: () => Promise<unknown>
}

class AuthServiceClass implements IAuthService {
  async confirmSignIn(
    user: unknown,
    code: string
  ): Promise<CognitoSessionResponse> {
    return await Auth.sendCustomChallengeAnswer(user, code)
  }

  async currentSession(): Promise<unknown> {
    return await Auth.currentSession()
  }

  async signIn(email: string): Promise<CognitoUser> {
    return await Auth.signIn(email)
    // try {
    //   return await Auth.signIn(email).then(response => {
    //     return new Promise((resolve, reject) => {
    //       if (response.username) {
    //         resolve(response)
    //       } else {
    //         reject()
    //       }
    //     })
    //   })
    // } catch (error) {
    //   const applicationError: ApplicationError = {
    //     message: 'requests.auth.signIn.error.message',
    //     type: 'application_error',
    //   }
    //   return new Promise((resolve, reject) => {
    //     reject(applicationError)
    //   })
    // }
  }

  async signOut(): Promise<void> {
    return await Auth.signOut()
  }

  async signUp(email: string, options: object): Promise<unknown> {
    const { generate } = usePassword()
    const password = generate(8)

    return await Auth.signUp({
      username: uuidv4(),
      password: password,
      attributes: {
        email,
      },
      ...options,
    })
  }

  async signUpBitbucket(): Promise<unknown> {
    return await Auth.federatedSignIn({
      customProvider: 'Bitbucket',
    })
  }

  async signUpGitHub(): Promise<unknown> {
    return await Auth.federatedSignIn({
      customProvider: 'GitHub',
    })
  }

  async signUpGitLab(): Promise<unknown> {
    return await Auth.federatedSignIn({
      customProvider: 'GitLab',
    })
  }

  async signUpGoogle(): Promise<unknown> {
    return await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
  }
}

export const AuthService = new AuthServiceClass()
