import { TFunction } from 'i18next'
import { action, computed, makeObservable, observable } from 'mobx'
import * as React from 'react'
import { IconType } from 'react-icons'
import { FaBitbucket, FaGithub, FaGitlab } from 'react-icons/fa'
import { FiTrash2 } from 'react-icons/fi'
import { IoMdCopy } from 'react-icons/io'

import {
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { Account } from '../../@types/account'
import { ApplicationError, ApplicationNotice } from '../../@types/application'
import { CognitoSession, Identity } from '../../@types/external'
import { NewRepositoryRequest, Repository } from '../../@types/repository'
import { AccountStore } from '../../stores/accountStore'
import { ApplicationStore } from '../../stores/applicationStore'
import { RepositoryStore } from '../../stores/repositoryStore'

export class RepositoryViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  repositoryStore: RepositoryStore
  _error: ApplicationError | undefined
  _identities: Identity[] = []
  _isBitbucket = false
  _isGitHub = false
  _isGitLab = false
  _isLoading = false
  _notice: ApplicationNotice | undefined

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    repositoryStore: RepositoryStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.repositoryStore = repositoryStore

    makeObservable(this, {
      connectBitbucket: action,
      connectGitHub: action,
      connectGitLab: action,
      readIdentities: action,
      account: computed,
      error: computed,
      identities: computed,
      isBitbucket: computed,
      isGitHub: computed,
      isGitLab: computed,
      isLoading: computed,
      notice: computed,
      repositories: computed,
      accountStore: observable,
      applicationStore: observable,
      repositoryStore: observable,
      _error: observable,
      _identities: observable,
      _isBitbucket: observable,
      _isGitHub: observable,
      _isGitLab: observable,
      _isLoading: observable,
      _notice: observable,
      createRepository: false,
      deleteRepository: false,
      getRepositories: false,
      iconForProvider: false,
      providers: false,
      setRepositoryState: false,
      tableColumns: false,
      tableData: false,
    })
  }

  public get account(): Account | undefined {
    return this.accountStore.account
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get identities(): Identity[] {
    return this._identities
  }

  set identities(value: Identity[]) {
    this._identities = value
  }

  get isBitbucket() {
    return this._isBitbucket
  }

  set isBitbucket(value: boolean) {
    this._isBitbucket = value

    this._isGitHub = false
    this._isGitLab = false
  }

  get isGitHub() {
    return this._isGitHub
  }

  set isGitHub(value: boolean) {
    this._isGitHub = value

    this._isBitbucket = false
    this._isGitLab = false
  }

  get isGitLab() {
    return this._isGitLab
  }

  set isGitLab(value: boolean) {
    this._isGitLab = value

    this._isBitbucket = false
    this._isGitHub = false
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  get notice(): ApplicationNotice | undefined {
    return this._notice
  }

  set notice(value: ApplicationNotice | undefined) {
    this._notice = value
  }

  get providers(): { name: string; value: string }[] {
    return [
      {
        name: 'Bitbucket',
        value: 'bitbucket',
      },
      {
        name: 'GitHub',
        value: 'github',
      },
      {
        name: 'GitLab',
        value: 'gitlab',
      },
    ]
  }

  get repositories(): Repository[] {
    return this.repositoryStore.repositories
  }

  iconForProvider(provider: string): IconType {
    if (provider == 'bitbucket') {
      return FaBitbucket
    }

    if (provider == 'github') {
      return FaGithub
    }

    return FaGitlab
  }

  public readIdentities() {
    return this.applicationStore.identities.then(session => {
      const {
        idToken: { payload },
      } = session as CognitoSession

      return new Promise(resolve => {
        resolve(payload.identities)
      }).then(identities => {
        if (typeof identities == 'undefined') {
          this.identities = []
        } else {
          this.identities = identities as Identity[]
        }
      })
    })
  }

  setRepositoryState(repositoryId: string, status: 'deleting' | 'ready') {
    this.repositoryStore.repositories.forEach(repository => {
      if (repository.repositoryId == repositoryId) {
        repository.status = status
      }
    })
  }

  // OAuth

  async connectBitbucket() {
    this.isBitbucket = true
    this.isLoading = true
    return this.applicationStore.authStore
      .signUpBitbucket()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  async connectGitHub() {
    this.isGitHub = true
    this.isLoading = true
    return this.applicationStore.authStore
      .signUpGitHub()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  async connectGitLab() {
    this.isGitLab = true
    this.isLoading = true
    return this.applicationStore.authStore
      .signUpGitLab()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  // Repositories

  async createRepository(values: NewRepositoryRequest) {
    this.isLoading = true

    return await this.repositoryStore
      .createRepository(values)
      .then(() => {
        this.getRepositories()
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  async deleteRepository(id: string) {
    this.setRepositoryState(id, 'deleting')

    return await this.repositoryStore
      .deleteRepository(id)
      .then(() => {
        this.getRepositories()
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async getRepositories() {
    this.isLoading = true

    await this.repositoryStore
      .getRepositories()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  // UI

  tableColumns(
    t: TFunction<'translation', undefined>,
    onCopyRegion: (event: unknown, region: string) => void,
    onDeleteRepository: (event: unknown, repositoryId: string) => void
  ): ColumnDef<Repository, string | any>[] {
    const columnHelper = createColumnHelper<Repository>()
    return [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: props => (
          <Text fontWeight="medium">{props.row.original.name}</Text>
        ),
      }),
      columnHelper.accessor('provider', {
        header: () => 'Source',
        cell: props => (
          <Tooltip
            label={t(
              `repositories.index.table.source.${props.row.original.provider}`
            )}
          >
            <span>
              <Icon as={this.iconForProvider(props.row.original.provider)} />
            </span>
          </Tooltip>
        ),
      }),
      columnHelper.accessor('operatingSystem', {
        header: () => 'Operating System',
        cell: props => (
          <Text>
            {t(
              `repositories.index.table.os.${props.row.original.operatingSystem}`
            )}
          </Text>
        ),
      }),
      columnHelper.accessor('region', {
        header: () => 'Region',
        cell: props => (
          <HStack>
            <Text>
              {t(
                `repositories.index.table.region.${props.row.original.region}`
              )}
            </Text>
            <IconButton
              aria-label="Copy Space ID"
              as={IoMdCopy}
              onClick={event => onCopyRegion(event, props.row.original.region)}
              minWidth={3}
              width={7}
              height={8}
              background="none"
              cursor="pointer"
            />
          </HStack>
        ),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () => '',
        cell: props =>
          ['deleting'].includes(props.row.original.status) ? (
            <HStack justify="flex-end" spacing="1">
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            </HStack>
          ) : (
            <HStack justify="flex-end" spacing="1">
              <Tooltip
                label={t('repositories.index.table.buttons.delete.label')}
              >
                <IconButton
                  id="pw-delete-button"
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Unlink repository"
                  onClick={event =>
                    onDeleteRepository(event, props.row.original.name)
                  }
                />
              </Tooltip>
            </HStack>
          ),
      }),
    ]
  }

  get tableData(): Record<string, unknown>[] {
    return this.repositories
  }
}
