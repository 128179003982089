import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Badge,
  Button,
  HStack,
  Heading,
  Link,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react'

import {
  AccountBillingViewProps,
  SubscriptionPlan,
} from '../../app/@types/account'
import { DurationSwitcher } from './DurationSwitcher'

type ProductOverviewTableProps = AccountBillingViewProps & TableProps

export const ProductOverviewTable = observer(
  (props: ProductOverviewTableProps) => {
    const { viewModel, ...tableProps } = props
    const { t } = useTranslation()

    const onChoosePlan = (plan: SubscriptionPlan) => {
      viewModel.subscriptionPlan = plan
      if (props.onStep) {
        props.onStep(1)
      } else {
        props.viewModel.updateSubscription()
      }
    }

    return (
      <Table sx={{ tableLayout: 'fixed' }} {...tableProps}>
        <Thead>
          <Tr>
            <Td>
              <HStack>
                <DurationSwitcher {...props} />
              </HStack>
            </Td>
            {viewModel.plans
              .slice(1, viewModel.plans.length)
              .map((plan, id) => (
                <Td key={id} fontSize="xl" fontWeight="semibold">
                  <HStack>
                    <Text width="full" textAlign="center">
                      {t(
                        `accounts.billing.change.pricing.plans.${plan.name}.title`
                      )}
                    </Text>
                    {plan.isPopular && (
                      <Badge bg="gray.500" color="gray.100">
                        {t('accounts.billing.change.pricing.plans.popular')}
                      </Badge>
                    )}
                  </HStack>
                </Td>
              ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            {viewModel.plans.slice(1, viewModel.plans.length).length > 1 && (
              <Td borderBottomWidth="0px" />
            )}
            {viewModel.plans
              .slice(1, viewModel.plans.length)
              .map((plan, idx) => (
                <Td
                  key={plan.product.id}
                  whiteSpace="normal"
                  borderBottomWidth="0px"
                >
                  <Stack spacing="6">
                    {plan.name == 'enterprise' ? (
                      <Stack spacing="4" mt={9}>
                        <Stack direction="row" align="baseline" spacing="1">
                          <Heading size="lg" color="default">
                            {' '}
                          </Heading>
                          <Text
                            fontWeight="medium"
                            fontSize="medium"
                            color="muted"
                          >
                            {' '}
                          </Text>
                        </Stack>
                        <Text
                          color="muted"
                          fontSize="sm"
                          whiteSpace="normal"
                          minH={24}
                        >
                          {t(
                            `accounts.billing.change.pricing.plans.${plan.name}.description`
                          )}
                        </Text>
                        <Button
                          as={Link}
                          href="mailto:scale@pairspaces.com"
                          variant="primary"
                          _hover={{
                            textDecoration: 'none',
                          }}
                        >
                          Contact Us
                        </Button>
                      </Stack>
                    ) : (
                      <Stack spacing="4">
                        <Stack direction="row" align="baseline" spacing="1">
                          <Heading size="lg" color="default">
                            {viewModel.currencySymbol}
                            {viewModel.priceForPeriod(plan)}
                          </Heading>
                          <Text
                            fontWeight="medium"
                            fontSize="medium"
                            color="muted"
                          >
                            /{t('accounts.billing.change.pricing.plans.user')}/
                            {t(
                              `accounts.billing.change.pricing.plans.periods.month`
                            )}
                          </Text>
                        </Stack>
                        <Text
                          color="muted"
                          fontSize="sm"
                          whiteSpace="normal"
                          minH={24}
                        >
                          {t(
                            `accounts.billing.change.pricing.plans.${plan.name}.description`
                          )}
                        </Text>
                        <Button
                          disabled={viewModel.isCurrentPlan(
                            viewModel.priceIdForPeriod(plan)
                          )}
                          id={`pw-select-plan-${idx}`}
                          onClick={() =>
                            onChoosePlan({
                              period: viewModel.period,
                              priceId: viewModel.priceIdForPeriod(plan),
                              productId: plan.product.id,
                            })
                          }
                          variant="primary"
                        >
                          {viewModel.isCurrentPlan(
                            viewModel.priceIdForPeriod(plan)
                          )
                            ? t(
                                'accounts.billing.change.pricing.plans.action.current'
                              )
                            : t(
                                'accounts.billing.change.pricing.plans.action.select'
                              )}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Td>
              ))}
          </Tr>
        </Tbody>
      </Table>
    )
  }
)
