import { TFunction } from 'i18next'
import { action, computed, makeObservable, observable } from 'mobx'
import * as React from 'react'
import { FiDownload } from 'react-icons/fi'

import { HStack, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react'
import { SetupIntent, Stripe, StripeElements } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import {
  Account,
  DeleteSubscriptionResponse,
  Download,
  Invoice,
  Plan,
  Price,
  SubscriptionPlan,
  UsageResponse,
  isSubscriptionPlan,
} from '../../@types/account'
import { ApplicationError } from '../../@types/application'
import {
  CognitoPayloadAccount,
  CognitoSession,
  StripeBillingInfo,
  StripeConfirmSetupResponse,
} from '../../@types/external'
import { ApplicationConstants as Constants } from '../../constants'
import { AnalyticsService } from '../../services/analyticsService'
import { AccountStore } from '../../stores/accountStore'
import { AuthStore } from '../../stores/authStore'
import { InstanceStore } from '../../stores/instanceStore'

export class AccountBillingViewModel {
  accountStore: AccountStore
  authStore: AuthStore
  instanceStore: InstanceStore
  page = 'change'
  _stripePromise: Promise<Stripe | null> | undefined = undefined

  _address: StripeBillingInfo | undefined
  _onCloseAction: () => void = () => {
    return null
  }
  _currencySymbol = '$'
  _error: ApplicationError | undefined
  _invoice: Invoice | undefined
  _invoices: Download[] = []
  _isChoosingPlan: boolean
  _isLoading = false
  _isSubscribed: boolean
  _period = 'month'
  _subscriptionPlan: SubscriptionPlan | undefined
  _willUpgradeState: boolean

  constructor(
    accountStore: AccountStore,
    authStore: AuthStore,
    instanceStore: InstanceStore
  ) {
    this.accountStore = accountStore
    this.authStore = authStore
    this.instanceStore = instanceStore
    this._isChoosingPlan = false
    this._isSubscribed = false
    this._willUpgradeState = true

    makeObservable(this, {
      togglePeriod: action,
      account: computed,
      accountStatus: computed,
      address: computed,
      clientSecret: computed,
      hasAdminScope: computed,
      hasBillingScope: computed,
      onCloseAction: computed,
      currencySymbol: computed,
      error: computed,
      invoice: computed,
      invoices: computed,
      isChoosingPlan: computed,
      isCurrentPlan: action,
      isLoading: computed,
      isSubscribed: computed,
      lastFour: computed,
      period: computed,
      planName: computed,
      scope: computed,
      subscriptionPlan: computed,
      accountStore: observable,
      authStore: observable,
      instanceStore: observable,
      _address: observable,
      _onCloseAction: observable,
      _error: observable,
      _invoice: observable,
      _invoices: observable,
      _isLoading: observable,
      _isSubscribed: observable,
      _subscriptionPlan: observable,
      confirmPaymentMethod: false,
      createSubscription: false,
      deleteSubscription: false,
      downloadInvoice: false,
      editAccount: false,
      features: false,
      getAccount: false,
      getAccountUsage: false,
      newSubscription: false,
      page: false,
      plans: false,
      priceForPeriod: false,
      priceIdForPeriod: false,
      steps: false,
      stripePromise: false,
      tableColumns: false,
      tableData: false,
      updateSubscription: false,
      _currencySymbol: false,
      _isChoosingPlan: false,
      _period: observable,
      _stripePromise: false,
      _willUpgradeState: false,
    })
  }

  public get account(): Account | undefined {
    return this.accountStore._account
  }

  public get accountStatus(): string | undefined {
    return this.accountStore.account?.account.status
  }

  public get address() {
    return this._address
  }

  public set address(value: StripeBillingInfo | undefined) {
    this._address = value
  }

  public get clientSecret(): string {
    return this.accountStore.stripe.clientSecret
  }

  public get currencySymbol(): string {
    return this._currencySymbol
  }

  public set currencySymbol(value: string) {
    if (value == 'USD') {
      this._currencySymbol = '$'
    }
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get hasAdminScope(): boolean {
    return ['admin.write'].includes(this.scope)
  }

  public get hasBillingScope(): boolean {
    return [
      'account.read',
      'admin.write',
      'billing.read',
      'billing.write',
    ].includes(this.scope)
  }

  public get invoice(): Invoice | undefined {
    return this._invoice
  }

  public set invoice(value: Invoice | undefined) {
    this._invoice = value
  }

  public get invoices(): Download[] {
    return this._invoices
  }

  public set invoices(value: Download[]) {
    this._invoices = value
  }

  public get isChoosingPlan(): boolean {
    return this._isChoosingPlan
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public set isLoading(value: boolean) {
    this._isLoading = value
  }

  public get lastFour(): string | undefined {
    return this.accountStore.lastFour
  }

  get onCloseAction(): () => void | undefined {
    return this._onCloseAction
  }

  set onCloseAction(value: () => void | undefined) {
    this._onCloseAction = value
  }

  public get period() {
    return this._period
  }

  public get planName(): string | undefined {
    return this.accountStore.plan
  }

  public get scope(): string {
    return this.authStore.scope
  }

  public get stripePromise(): Promise<Stripe | null> {
    if (typeof this._stripePromise == 'undefined') {
      this._stripePromise = loadStripe(Constants.STRIPE_API_KEY as string)
    }
    return this._stripePromise
  }

  public get subscriptionPlan(): SubscriptionPlan | undefined {
    return this._subscriptionPlan
  }

  public set subscriptionPlan(value: SubscriptionPlan | undefined) {
    this._subscriptionPlan = value

    if (value && isSubscriptionPlan(value)) {
      AnalyticsService.didSelectPlan(value)
    }
  }

  public async confirmPaymentMethod(
    stripe: Stripe,
    elements: StripeElements,
    navigate: (options: unknown) => void
  ) {
    let return_url = `${process.env.URI}/external/complete`
    if (typeof this.subscriptionPlan !== 'undefined') {
      const subscriptionPlan = JSON.stringify(this.subscriptionPlan)
      const hashedSubscriptionPlan = window.btoa(subscriptionPlan)
      return_url = `${process.env.URI}/external/complete/${hashedSubscriptionPlan}`
    }

    await stripe
      .confirmSetup({
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: this.address?.address,
              name: `${this.address?.firstName} ${this.address?.lastName}`,
            },
          },
          return_url: return_url,
        },
        // `Elements` instance that was used to create the Payment Element
        elements,
      })
      .then((response: StripeConfirmSetupResponse) => {
        const { error, setupIntent } = response
        if (error) {
          this.error = {
            message: error.message,
            type: 'application_error',
          }
        } else {
          if (typeof this.subscriptionPlan !== 'undefined') {
            AnalyticsService.didAddPaymentMethod()
            AnalyticsService.didAddBillingAddress()
            this.createSubscription(
              (setupIntent as SetupIntent).payment_method as string,
              navigate
            )
          }
        }
      })
      .catch(error => {
        this.error = {
          message: error.message,
          type: 'application_error',
        }
      })
  }

  public createSubscription(
    paymentMethod: string,
    navigate: (options: unknown) => void
  ) {
    if (typeof this.subscriptionPlan !== 'undefined') {
      this.subscriptionPlan.paymentMethod = paymentMethod
      this.accountStore
        .createSubscription(this.subscriptionPlan)
        .then(() => {
          navigate({ to: '/accounts/plans' })
        })
        .catch((error: ApplicationError) => {
          this.error = error
        })
    }
  }

  public deleteSubscription() {
    this.isLoading = true

    this.accountStore
      .deleteSubscription()
      .then((response: Response) => {
        // Pause instances that are now `pending_usage`
        const data: unknown = response.json()
        const { instances } = data as DeleteSubscriptionResponse
        instances &&
          instances.forEach(instance => {
            this.instanceStore.updateInstance(instance._id, {
              instance: {
                status: false,
              },
            })
          })
        // Update account's subscription (overrides that in the token)
        // TODO: Handle the case when the user refreshes their browser
        // and original token replaces this updated token
        this.getAccount()
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public newSubscription(): Promise<unknown> {
    this.isLoading = true
    return this.accountStore
      .newSubscription()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
        this.isLoading = false
      })
  }

  public updateSubscription() {
    this.isLoading = true

    const closeAction = this.onCloseAction
    closeAction()

    if (typeof this.subscriptionPlan !== 'undefined') {
      this.accountStore
        .updateSubscription(this.subscriptionPlan)
        .then(() => {
          // Update account's subscription (overrides that in the token)
          // TODO: Handle the case when the user refreshes their browser
          // and original token replaces this updated token
          AnalyticsService.didSubscribe(
            this.subscriptionPlan as SubscriptionPlan
          )

          this.getAccount()
        })
        .catch((error: ApplicationError) => {
          this.error = error
          this.isLoading = false
        })
    }
  }

  public editAccount() {
    this.accountStore.editAccount()
  }

  public isCurrentPlan(priceId: string | null): boolean {
    if (this.account?.subscription.status === null) {
      return false
    }
    if (
      ['canceled', 'incomplete_expired'].includes(
        this.account?.subscription.status as string
      )
    ) {
      if (priceId === null) {
        // Free plan
        return true
      }
      return false
    }
    return priceId == this.account?.subscription.plan.priceId
  }

  public get isSubscribed(): boolean {
    return this.accountStore.account?.subscription.status == 'active'
  }

  public async downloadInvoice(invoice: Download) {
    try {
      const downloadLink = document.createElement('a')
      downloadLink.href = invoice.url
      downloadLink.download = `PairSpaces Invoice (${invoice.date}).pdf`
      downloadLink.type = 'application/pdf'

      document.body.appendChild(downloadLink)
      downloadLink.click()

      setTimeout(() => {
        document.body.removeChild(downloadLink)
      }, 100)
    } catch (error) {
      console.log(error)
    }
  }

  public getAccount(force = false) {
    if (force) {
      this.isLoading = true
      this.accountStore
        .getAccount()
        .then(() => {
          this.isLoading = false
        })
        .catch((error: ApplicationError) => {
          this.error = error
          this.isLoading = false
        })
    } else if (typeof this.accountStore.account == 'undefined') {
      const {
        idToken: {
          payload: { account, subscription },
        },
      } = this.authStore.session as CognitoSession

      const parsedAccount: CognitoPayloadAccount = JSON.parse(account)
      const key = `PLANS${process.env.PLANS}` as keyof typeof Constants

      this.accountStore.account = {
        account: {
          awsAccountId: parsedAccount.awsAccountId,
          id: parsedAccount.id,
          organization: parsedAccount.organization,
          status: parsedAccount.status,
        },
        paymentMethod: {
          last4: undefined,
        },
        plans: Constants[key] as Plan[],
        subscription: JSON.parse(subscription as string),
      }

      this.isLoading = false
    } else {
      setTimeout(() => {
        this.accountStore.getAccount().catch((error: ApplicationError) => {
          this.error = error
        })
        this.getAccountUsage()
      }, 10000) // Wait for the Stripe hook to fire
    }
  }

  public getAccountUsage() {
    this.isLoading = true

    this.accountStore
      .getAccountUsage()
      .then((data: unknown) => {
        this.invoice = (data as UsageResponse).invoice
        this.invoices = (data as UsageResponse).invoices
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public get features() {
    return Constants.FEATURES
  }

  public get plans(): Plan[] {
    const key = `PLANS${process.env.PLANS}` as keyof typeof Constants
    return Constants[key] as Plan[]
  }

  public priceForPeriod(plan: Plan): number {
    const prices: Price[] = plan.prices
    const factor = this.period == 'year' ? 12 : 1
    let value = 0

    prices.forEach(price => {
      const period = price.recurring.interval
      if (period == this.period) {
        value = parseInt(price.unitAmount) / (factor * 100)
      }
    })

    return value
  }

  public priceIdForPeriod(plan: Plan): string {
    const prices: Price[] = plan.prices
    let priceId = ''

    prices.forEach(price => {
      const period = price.recurring.interval
      if (period == this.period) {
        priceId = price.id
      }
    })

    return priceId
  }

  public get steps() {
    return Constants.STEPS
  }

  tableColumns(
    t: TFunction<'translation', undefined>,
    onDownloadInvoice: (event: unknown, invoice: Download) => void
  ): ColumnDef<Download, string | any>[] {
    const columnHelper = createColumnHelper<Download>()
    return [
      columnHelper.accessor('date', {
        header: () => 'Date',
        cell: props => (
          <Stack>
            <Text fontWeight="medium">{props.row.original.date}</Text>
          </Stack>
        ),
      }),
      columnHelper.accessor('url', {
        // id: 'url',
        header: () => '',
        cell: props => (
          <HStack justify="flex-end" spacing="1">
            <Tooltip
              label={t(
                'accounts.billing.invoices.table.buttons.download.label'
              )}
            >
              <IconButton
                icon={<FiDownload fontSize="1.25rem" />}
                variant="ghost"
                aria-label="Download invoice"
                onClick={event => onDownloadInvoice(event, props.row.original)}
              />
            </Tooltip>
          </HStack>
        ),
        enableSorting: false,
      }),
    ]
  }

  get tableData(): Record<string, string>[] {
    return this.invoices
  }

  public togglePeriod() {
    if (this._period == 'month') {
      this._period = 'year'
    } else {
      this._period = 'month'
    }
  }
}
