import { action, makeObservable, observable } from 'mobx'

import { RootStore } from '.'
import { ApplicationError } from '../@types/application'
import { CognitoPayloadAccount, CognitoSession } from '../@types/external'
import {
  Instance,
  InstanceMember,
  NewInstance,
  NewInstanceFormInput,
} from '../@types/instance'
import { ApplicationConstants as Constants } from '../constants'
import { ContainerService } from '../services/containerService'
import { BaseStore } from './baseStore'

export class ContainerStore extends BaseStore {
  rootStore: RootStore
  containers: Instance[] = []

  constructor(store: RootStore) {
    super()

    this.rootStore = store
    makeObservable(this, {
      createContainer: action,
      deleteContainer: action,
      getContainers: action,
      shareContainer: action,
      setContainers: action,
      rootStore: observable,
      containers: observable,
      accountFromSession: false,
    })
  }

  async createContainer(values: NewInstanceFormInput): Promise<unknown> {
    const instance: NewInstance = {
      action: 'create',
      name: values.name,
      region: values.region,
      spaceType: values.spaceType,
      userId: null,
    }

    return this.rootStore.authStore.currentSession().then(session => {
      const {
        idToken: { payload },
        accessToken: { jwtToken },
      } = session as CognitoSession

      const { [Constants.COGNITO.USERNAME]: username } = payload

      instance.userId = username

      return ContainerService.create(instance, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.create.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async deleteContainer(id: string): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return ContainerService.delete(id, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.delete.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  async getContainers(): Promise<unknown> {
    const session = await this.rootStore.authStore.currentSession()
    const {
      accessToken: { jwtToken },
    } = session as CognitoSession

    return await ContainerService.list(jwtToken).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          const error: ApplicationError = this._errorFor(response, {
            message: 'requests.instances.get.error.message',
          })
          reject(error)
        }
      }).then((containers: unknown) => {
        this.setContainers(containers as Instance[])
      })
    })
  }

  setContainers(value: Instance[]) {
    this.containers = value
  }

  async shareContainer(id: string, values: InstanceMember[]): Promise<unknown> {
    return this.rootStore.authStore.currentSession().then(session => {
      const {
        accessToken: { jwtToken },
      } = session as CognitoSession

      return ContainerService.share(id, values, jwtToken).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = this._errorFor(response, {
              message: 'requests.instances.share.error.message',
            })
            reject(error)
          }
        })
      })
    })
  }

  accountFromSession(session: CognitoSession): CognitoPayloadAccount {
    const {
      idToken: {
        payload: { account: accountString },
      },
    } = session as CognitoSession

    return JSON.parse(accountString)
  }
}
