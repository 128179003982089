import { TFunction } from 'i18next'
import { computed, makeObservable, observable } from 'mobx'
import * as React from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import { HStack, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { Invitation, Member, Role } from '../../@types/account'
import { ApplicationError, ApplicationNotice } from '../../@types/application'
import { AccountStore } from '../../stores/accountStore'
import { AuthStore } from '../../stores/authStore'

export class AccountTeamViewModel {
  accountStore: AccountStore
  authStore: AuthStore
  page = 'team'
  _currentUser?: Member = undefined
  _error: ApplicationError | undefined
  _isLoading = false
  _notice: ApplicationNotice | undefined

  constructor(accountStore: AccountStore, authStore: AuthStore) {
    this.accountStore = accountStore
    this.authStore = authStore

    makeObservable(this, {
      currentUser: computed,
      error: computed,
      invitations: computed,
      isLoading: computed,
      members: computed,
      notice: computed,
      users: computed,
      accountStore: observable,
      authStore: observable,
      _currentUser: observable,
      _error: observable,
      _isLoading: observable,
      _notice: observable,
      createInvitation: false,
      deleteInvitation: false,
      updateInvitation: false,
      deleteUser: false,
      getUsers: false,
      updateUser: false,
      page: false,
      roles: false,
      tableColumns: false,
      tableData: false,
    })
  }

  get currentUser(): Member | undefined {
    return this._currentUser
  }

  set currentUser(value: Member | undefined) {
    this._currentUser = value
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get invitations(): Invitation[] {
    return this.accountStore.invitations
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public set isLoading(value: boolean) {
    this._isLoading = value
  }

  public get members(): (Invitation | Member)[] {
    return this.accountStore.members
  }

  get notice(): ApplicationNotice | undefined {
    return this._notice
  }

  set notice(value: ApplicationNotice | undefined) {
    this._notice = value
  }

  public get roles(): Role[] {
    return [
      {
        name: 'Admin',
        value: 'admin',
      },
      {
        name: 'Billing',
        value: 'billing',
      },
      {
        name: 'Member',
        value: 'member',
      },
    ]
  }

  public get users(): Member[] {
    return this.accountStore.users
  }

  public createInvitation(invitation: Invitation) {
    this.isLoading = true

    this.accountStore
      .createInvitation(invitation)
      .then(() => {
        this.isLoading = false
        this.notice = {
          message: 'requests.users.create.notice.message',
        }
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public deleteInvitation(invitationId: string) {
    this.isLoading = true

    this.accountStore
      .deleteInvitation(invitationId)
      .then(() => {
        this.isLoading = false
        this.notice = {
          message: 'requests.invitations.delete.notice.message',
        }
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public updateInvitation(accountId: string, invitationId: string) {
    this.isLoading = true

    this.accountStore
      .updateInvitation(accountId, invitationId)
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public deleteUser(userId: string) {
    this.isLoading = true

    this.accountStore
      .deleteUser(userId)
      .then(() => {
        this.isLoading = false
        this.notice = {
          message: 'requests.users.delete.notice.message',
        }
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public getUsers() {
    this.isLoading = true

    this.accountStore
      .getUsers()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  public updateUser(userId: string, role: string) {
    this.isLoading = true

    this.accountStore
      .updateUser(userId, role)
      .then(() => {
        this.isLoading = false
        this.notice = {
          message: 'requests.users.update.notice.message',
        }
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  tableColumns(
    t: TFunction<'translation', undefined>,
    onDeleteInvitation: (event: unknown, invitationId: string) => void,
    onDeleteUser: (event: unknown, userId: string) => void,
    onEditUser: (event: unknown, user: Member) => void
  ): ColumnDef<Member, string | any>[] {
    const columnHelper = createColumnHelper<Member>()
    return [
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: props => (
          <Stack>
            <Text fontWeight="medium">{props.row.original.email}</Text>
          </Stack>
        ),
      }),
      columnHelper.accessor('role', {
        header: () => 'Role',
        cell: props => (
          <Text fontWeight="medium">
            {props.row.original.role
              ? `${t(`users.index.table.status.invited`)} -
                ${t(`users.index.table.status.${props.row.original.role}`)}`
              : t(`users.index.table.status.${props.row.original.roles[0]}`)}
          </Text>
        ),
      }),
      columnHelper.accessor('status', {
        id: 'status2',
        header: () => '',
        cell: props => (
          <HStack justify="flex-end" spacing="1">
            {props.row.original.role ? (
              <Tooltip label={t('users.index.table.buttons.cancel.label')}>
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete user"
                  onClick={event =>
                    onDeleteInvitation(event, props.row.original._id)
                  }
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip label={t('users.index.table.buttons.edit.label')}>
                  <IconButton
                    id="pw-edit-button"
                    icon={<FiEdit2 fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Edit user"
                    onClick={event => onEditUser(event, props.row.original)}
                  />
                </Tooltip>
                <Tooltip label={t('users.index.table.buttons.delete.label')}>
                  <IconButton
                    icon={<FiTrash2 fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Delete user"
                    onClick={event =>
                      onDeleteUser(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              </>
            )}
          </HStack>
        ),
        enableSorting: false,
      }),
    ]
  }

  get tableData(): Record<string, unknown>[] {
    return this.members
  }
}
