import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiSearch } from 'react-icons/fi'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'

import {
  Box,
  Button, // Spinner as ChakraSpinner,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  useBreakpointValue,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {
  NewInstanceFormInput,
  ShareInstanceFormInput,
  ViewProps,
} from '../../@types/instance'
import { Alert } from './../../../components/AlertDialog'
import { NewInstance } from './NewInstance'
import { ShareInstance } from './ShareInstance'
import { ShowInstance } from './ShowInstance'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const IndexInstances = observer((props: ViewProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  const searchParameters = window.location.search
  const searchEntries = new URLSearchParams(searchParameters)
  const searchObject = Object.fromEntries(searchEntries)

  React.useEffect(() => {
    if (searchObject.session) {
      props.viewModel.deleteInstanceFromSession(searchObject.session)
    }
  }, [searchObject.session])

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
    if (props.viewModel.notice) {
      toast({
        description: t(props.viewModel.notice.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'success',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.notice = undefined
        },
      })
    }
  }, [props.viewModel.error, props.viewModel.notice])

  const [instanceId, setInstanceId] = React.useState('')

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmDelete = (event: unknown) => {
    onCloseDelete()
    props.viewModel.deleteInstance(instanceId)

    setInstanceId('')
  }

  const onDeleteInstance = (event: unknown, instanceId: string) => {
    setInstanceId(instanceId)
    props.viewModel.currentInstance = instanceId

    onOpenDelete()
  }

  const {
    isOpen: isOpenNewInstance,
    onOpen: onOpenNewInstance,
    onClose: onCloseNewInstance,
  } = useDisclosure()

  const {
    isOpen: isOpenShareInstance,
    onOpen: onOpenShareInstance,
    onClose: onCloseShareInstance,
  } = useDisclosure()

  const {
    isOpen: isOpenShowInstance,
    onOpen: onOpenShowInstance,
    onClose: onCloseShowInstance,
  } = useDisclosure()

  useEffect(() => {
    props.viewModel.getInstances()
  }, [])

  useEffect(() => {
    if (!props.viewModel.accountHasOrganization) {
      props.viewModel.watchStore.watchOrganization()
    }
  }, [props.viewModel.accountHasOrganization])

  useEffect(() => {
    table.setPageSize(50) // Arbitrary value
    return props.viewModel.watchInstances()
  }, [props.viewModel.instances])

  const breakpointValue = useBreakpointValue({ base: true, md: false })
  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  const { onCopy, setValue } = useClipboard('')
  const onCopyInstanceDetails = (
    event: unknown,
    detail: {
      ipAddress?: string
      port?: string
      spaceId?: string
      spaceType?: string
    }
  ) => {
    if (typeof detail.spaceId != 'undefined') {
      props.viewModel.instanceDetails = `pair space ${detail.spaceId}`
    } else {
      props.viewModel.instanceDetails = `http://${detail.ipAddress}`
      if (typeof detail.port != 'object') {
        props.viewModel.instanceDetails = `http://${detail.ipAddress}:${detail.port}`
      }
    }

    setValue(props.viewModel.instanceDetails as string)
  }

  React.useEffect(() => {
    if (props.viewModel.instanceDetails) {
      setValue(props.viewModel.instanceDetails)
      onCopy()
    }
  }, [props.viewModel.instanceDetails, setValue])

  const onCreateInstance = (values: NewInstanceFormInput) => {
    onCloseNewInstance()
    props.viewModel.createInstance(values)
  }

  const onRestoreInstance = (event: unknown, instanceId: string) => {
    props.viewModel.restoreInstance(instanceId)
  }

  const onShareInstance = (event: unknown, instanceId: string) => {
    setInstanceId(instanceId)
    props.viewModel.currentInstance = instanceId

    const instanceUsers: string[] = []
    props.viewModel.currentInstance?.users.forEach(user => {
      instanceUsers.push(user.id)
    })
    props.viewModel._users = instanceUsers

    onOpenShareInstance()
  }

  const onShowInstance = (event: unknown, instanceId: string) => {
    props.viewModel.onCloseAction = onCloseShowInstance
    props.viewModel.currentInstance = instanceId

    onOpenShowInstance()
  }

  const shareInstance = (values: ShareInstanceFormInput) => {
    onCloseShareInstance()
    props.viewModel.shareInstance(instanceId, values)

    setInstanceId('')
  }

  const newInstanceProps = {
    isOpen: isOpenNewInstance,
    onClose: onCloseNewInstance,
    onOpen: onOpenNewInstance,
    onSubmit: onCreateInstance,
  }

  const shareInstanceProps = {
    isOpen: isOpenShareInstance,
    onClose: onCloseShareInstance,
    onOpen: onOpenShareInstance,
    onSubmit: shareInstance,
  }

  const [globalFilter, setGlobalFilter] = React.useState('')
  const columns = props.viewModel.tableColumns(
    t,
    onCopyInstanceDetails,
    onRestoreInstance,
    onShareInstance,
    onShowInstance,
    onDeleteInstance
  )
  const data = props.viewModel.tableData

  const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <Box
      as="section"
      bg="bg-surface"
      p={{ base: '4', md: '8' }}
      w="100%"
      maxW="full"
      id="pw-instances"
    >
      <Container width="100%" height="100%" maxW="5xl">
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Stack
            spacing="4"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text color="muted">
                {!props.viewModel.instances
                  ? t('instances.index.header.empty')
                  : t('instances.index.header.availableWithCount', {
                      count: props.viewModel.activeInstances.length,
                    })}
              </Text>
            </Stack>
            <Stack direction="row" spacing="3">
              <Button
                isDisabled={
                  props.viewModel.instances.length > 3 ||
                  !props.viewModel.accountHasOrganization
                }
                variant="primary"
                onClick={onOpenNewInstance}
              >
                {!props.viewModel.accountHasOrganization ? (
                  <>
                    <Text mr={3}>{t('Setting up account...')}</Text>
                    <Spinner size="sm" />
                  </>
                ) : (
                  t('instances.index.header.buttonLabel')
                )}
              </Button>
            </Stack>
          </Stack>
        </Skeleton>
      </Container>
      <Container
        py={{ base: '4', md: '8' }}
        px={{ base: '0', md: 8 }}
        maxW="5xl"
      >
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Box
            bg="bg-surface"
            boxShadow={{
              base: 'none',
              md: colorModeValue,
            }}
            borderRadius={breakpointValue}
          >
            <Stack spacing="5">
              <Box px={{ base: '4', md: '6' }} pt="5">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  justify="space-between"
                >
                  <Text fontSize="lg" fontWeight="medium">
                    {t('instances.index.table.title')}
                  </Text>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search spaces"
                      value={globalFilter}
                      onChange={event =>
                        setGlobalFilter(String(event.target.value))
                      }
                    />
                  </InputGroup>
                </Stack>
              </Box>
              <Box overflowX="auto">
                <Table>
                  <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = header.column.columnDef.meta
                          return (
                            <Th
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              isNumeric={meta?.isNumeric}
                            >
                              <Flex direction={'row'}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                                <Spacer />
                                <chakra.span pl="4">
                                  {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === 'desc' ? (
                                      <Icon
                                        aria-label="sorted descending"
                                        as={IoArrowDown}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    ) : (
                                      <Icon
                                        aria-label="sorted ascending"
                                        as={IoArrowUp}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    )
                                  ) : null}
                                </chakra.span>
                              </Flex>
                            </Th>
                          )
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map(row => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = cell.column.columnDef.meta
                          return (
                            <Td key={cell.id} isNumeric={meta?.isNumeric}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          )
                        })}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              {/* <Box px={{ base: '4', md: '6' }} pb="5">
                <HStack spacing="3" justify="space-between">
                  {!isMobile && (
                    <Text color="muted" fontSize="sm">
                      Showing 1 to 5 of 42 results
                    </Text>
                  )}
                  <ButtonGroup
                    spacing="3"
                    justifyContent="space-between"
                    width={{ base: 'full', md: 'auto' }}
                    variant="secondary"
                  >
                    <Button>Previous</Button>
                    <Button>Next</Button>
                  </ButtonGroup>
                </HStack>
              </Box> */}
            </Stack>
          </Box>
          <Alert
            onClose={onCloseDelete}
            onConfirm={onConfirmDelete}
            onOpen={onOpenDelete}
            isOpen={isOpenDelete}
            body="instances.index.table.actions.delete.alert.body"
            confirm="instances.index.table.actions.delete.alert.confirm"
            dismiss="instances.index.table.actions.delete.alert.dismiss"
            header="instances.index.table.actions.delete.alert.header"
          />
        </Skeleton>
      </Container>
      <Drawer isOpen={isOpenNewInstance} onClose={onCloseNewInstance} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <NewInstance {...newInstanceProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={isOpenShareInstance}
        onClose={onCloseShareInstance}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody height="100%">
            <ShareInstance {...shareInstanceProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={isOpenShowInstance}
        onClose={onCloseShowInstance}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <ShowInstance {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
})
