/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Heading,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'

import {
  CheckboxCard,
  CheckboxCardGroup,
} from '../../../../components/CheckboxCardGroup'
import { ModalProps, RunDocumentFormInput } from '../../../@types/document'

export const RunDocument = observer((props: ModalProps) => {
  React.useEffect(() => {
    props.viewModel.getInstances()
  }, [])

  const { control, handleSubmit } = useForm<RunDocumentFormInput>({
    defaultValues: {},
  })
  const { t } = useTranslation()

  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  return (
    <Box id="pw-run-document">
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: '4', md: '8' }}
        pb={{ base: '12', md: '24' }}
        overflowY="hidden"
      >
        <Container maxW="full">
          <Stack
            spacing="4"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {t('documents.run.header.title', {
                  name: props.viewModel.currentDocument?.name,
                })}
              </Heading>
              <Text color="muted">{t('documents.run.header.description')}</Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        bg="bg-surface"
        boxShadow={colorModeValue}
        borderRadius="lg"
        p={{ base: '4', md: '6' }}
      >
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Stack spacing="5" divider={<StackDivider />}>
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                {t('documents.run.instances.heading')}
              </Text>
              <Text fontSize="sm" color="muted">
                {t('documents.run.instances.subheading')}
              </Text>
            </Stack>
            <form onSubmit={handleSubmit(props.onSubmit)}>
              <Stack spacing="5" divider={<StackDivider />}>
                <Stack spacing="1">
                  <Controller
                    name="instances"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <CheckboxCardGroup
                        defaultValue={[]}
                        height="22em"
                        paddingRight={6}
                        spacing="3"
                        onChange={onChange}
                      >
                        {props.viewModel.instances &&
                          props.viewModel.instances.map(instance => (
                            <CheckboxCard
                              onChange={onChange}
                              key={instance.instanceId}
                              value={instance.instanceId}
                            >
                              <Text
                                color="emphasized"
                                fontWeight="medium"
                                fontSize="sm"
                              >
                                {instance.name}
                              </Text>
                            </CheckboxCard>
                          ))}
                      </CheckboxCardGroup>
                    )}
                  />
                </Stack>
                <Stack spacing="1">
                  <ButtonGroup alignSelf="end">
                    <Button variant="secondary" onClick={props.onClose}>
                      {t('documents.run.instances.cancel')}
                    </Button>
                    <Button variant="primary" type="submit">
                      {t('documents.run.instances.action')}
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Skeleton>
      </Box>
    </Box>
  )
})
