/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Container,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'

import { ApplicationViewProps } from './../app/@types/application'
import { AccountPendingDeletion } from './Account/AccountPendingDeletion'
import { Banner } from './Banner'
import { MenuActive } from './Menu/MenuActive'
import { MenuPendingDeletion } from './Menu/MenuPendingDeletion'

export const Layout = observer((props: ApplicationViewProps) => {
  const colorModeValue = useColorModeValue('sm', 'sm-dark')
  const pxBreakpointValue = useBreakpointValue({
    base: '0',
    md: '6',
  })
  const widthBreakpointValue = useBreakpointValue({
    base: '4em',
    md: 'full',
  })

  React.useEffect(() => {
    props.viewModel.applicationStore.featureFlags()
    props.viewModel.accountStore.getAccount()
  }, [])

  const { t } = useTranslation()
  const toast = useToast()

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
  }, [props.viewModel.error])

  return (
    <>
      {props.viewModel.willShowBanner && <Banner {...props} />}

      <Flex as="section" bg="bg-canvas" minH="100vh">
        <Flex
          flex="1"
          boxShadow={colorModeValue}
          minW="4em"
          maxW={widthBreakpointValue}
          px={pxBreakpointValue}
        >
          {[
            'active',
            'incomplete_expired',
            'pending_creation',
            'requires_action',
          ].includes(props.viewModel.accountStatus) ? (
            <MenuActive {...props} />
          ) : (
            <MenuPendingDeletion {...props} />
          )}
        </Flex>
        <Flex bg="bg-surface" flex={{ base: '4' }}>
          {[
            'active',
            'incomplete_expired',
            'pending_creation',
            'requires_action',
          ].includes(props.viewModel.accountStatus) ? (
            <Container width="100%" maxW="full" bg="bg-surface">
              {props.children}
            </Container>
          ) : (
            <AccountPendingDeletion {...props} />
          )}
        </Flex>
      </Flex>
    </>
  )
})
