import { observer } from 'mobx-react-lite'
import * as React from 'react'

import {
  BoxProps,
  Divider,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { StepCircle } from './StepCircle'

type DataProps = {
  description: string
  isActive: boolean
  isCompleted: boolean
  isFirstStep: boolean
  isLastStep: boolean
  title: string
}

type StepProps = DataProps & BoxProps

export const AutomaticStep = observer((props: StepProps) => {
  const {
    isActive,
    isCompleted,
    isLastStep,
    isFirstStep,
    title,
    description,
    ...stackProps
  } = props
  const isMobile = useBreakpointValue({ base: true, md: false })

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  })

  return (
    <Stack
      spacing="4"
      direction={{ base: 'row', md: 'column' }}
      flex="1"
      {...stackProps}
    >
      <Stack
        spacing="0"
        align="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <Divider
          display={isMobile ? 'none' : 'initial'}
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isFirstStep
              ? 'transparent'
              : isCompleted || isActive
              ? 'accent'
              : 'inherit'
          }
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'
          }
        />
      </Stack>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? '8' : '0'}
        align={{ base: 'start', md: 'center' }}
      >
        <Text color="emphasized" fontWeight="medium">
          {title}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
    </Stack>
  )
})
