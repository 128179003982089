import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  Box,
  BoxProps,
  Circle,
  Icon,
  Stack,
  StackProps,
  UseRadioProps,
  createIcon,
  useId,
  useRadio,
  useRadioGroup,
  useStyleConfig,
} from '@chakra-ui/react'

type RadioCardGroupProps<T> = {
  defaultValue?: string
  name?: string
  onChange?: (value: T) => void
  value?: T
} & Omit<StackProps, 'onChange'>

export const RadioCardGroup = observer(
  <T extends string>(props: RadioCardGroupProps<T>) => {
    const { children, name, defaultValue, value, onChange, ...rest } = props
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      defaultValue,
      value,
      onChange,
    })

    const cards = React.useMemo(
      () =>
        React.Children.toArray(children)
          .filter<React.ReactElement<RadioCardProps>>(React.isValidElement)
          .map(card => {
            return React.cloneElement(card, {
              radioProps: getRadioProps({
                value: card.props.value,
              }),
            })
          }),
      [children, getRadioProps]
    )

    return <Stack {...getRootProps(rest)}>{cards}</Stack>
  }
)

type RadioCardProps = {
  radioProps?: UseRadioProps
  value: string
} & BoxProps

export const RadioCard = observer((props: RadioCardProps) => {
  const { radioProps, children, ...rest } = props
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useRadio(radioProps)
  const id = useId(undefined, 'radio-button')

  const styles = useStyleConfig('RadioCard', props)
  const inputProps = getInputProps()
  const checkboxProps = getCheckboxProps()
  const labelProps = getLabelProps()

  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      sx={{
        '.focus-visible + [data-focus]': {
          boxShadow: 'outline',
          zIndex: 1,
        },
      }}
    >
      <input type="radio" {...inputProps} aria-labelledby={id} />
      <Box sx={styles} {...checkboxProps} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          {state.isChecked ? (
            <Circle bg="accent" size="4">
              <Icon as={CheckIcon} boxSize="2.5" color="fg.inverted" />
            </Circle>
          ) : (
            <Circle borderWidth="2px" size="4" />
          )}
        </Stack>
      </Box>
    </Box>
  )
})

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 12 10',
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="gray"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
})
