import { computed, makeObservable, observable } from 'mobx'

import { UseNavigateType } from '@tanstack/react-location'

import { ApplicationError } from '../../@types/application'
import { ApplicationConstants as Constants } from '../../constants'
import { AnalyticsService } from '../../services/analyticsService'
import { AccountStore } from '../../stores/accountStore'
import { ApplicationStore } from '../../stores/applicationStore'
import { AuthStore } from '../../stores/authStore'

export class ApplicationViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  authStore: AuthStore
  _error: ApplicationError | undefined

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    authStore: AuthStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.authStore = authStore
    makeObservable(this, {
      accountRequiresAction: computed,
      error: computed,
      hasAdminScope: computed,
      hasBillingScope: computed,
      hasMemberScope: computed,
      scope: computed,
      willShowBanner: computed,
      accountStore: observable,
      applicationStore: observable,
      authStore: observable,
      _error: observable,
      closeBanner: false,
      documentation: false,
      isCurrentMenuItem: false,
      navigateTo: false,
      signOut: false,
      accountStatus: false,
    })
  }

  public get accountRequiresAction() {
    return this.applicationStore.accountRequiresAction
  }

  public get accountStatus() {
    return this.accountStore.accountStatus
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get hasAdminScope(): boolean {
    return this.authStore.scope == 'admin.write'
  }

  public get hasBillingScope(): boolean {
    return [
      'account.read',
      'admin.write',
      'billing.read',
      'billing.write',
    ].includes(this.scope)
  }

  public get hasMemberScope(): boolean {
    return this.authStore.scope == 'member.write'
  }

  public get scope(): string {
    return this.authStore.scope
  }

  public get willShowBanner(): boolean {
    return this.applicationStore.willShowBanner
  }

  public documentation() {
    AnalyticsService.didViewDocumentation()

    window.open(Constants.DOCUMENTATION_URL as string, '_blank')
  }

  public isCurrentMenuItem(label: string): 'page' | false {
    return this.applicationStore.isCurrentMenuItem(label)
  }

  public navigateTo(path: string, navigate: UseNavigateType) {
    navigate({
      to: path,
      replace: false,
    })
  }

  public signOut(navigate: UseNavigateType) {
    this.authStore.signOut().then(() => {
      navigate({
        to: '/',
        replace: false,
      })
    })
  }

  closeBanner(navigate?: UseNavigateType) {
    this.applicationStore.willShowBanner = false

    if (typeof navigate != 'undefined') {
      navigate({
        to: '/accounts/billing',
        replace: false,
      })
    }
  }
}
