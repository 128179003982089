import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { GoGitPullRequest } from 'react-icons/go'
import { MdBuild, MdPeople } from 'react-icons/md'

import {
  Box, // Button,
  Container,
  Link,
  Stack,
  Text,
  useBreakpointValue, // useClipboard,
} from '@chakra-ui/react'
import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from '@chakra-ui/react'

export const SlidingCalculator = observer(() => {
  const { t } = useTranslation()
  const [buildTime, setBuildTime] = React.useState(4)
  const [pullRequestCount, setPullRequestCount] = React.useState(2)
  const [showTooltip, setShowTooltip] = React.useState(false)
  const [teamSize, setTeamSize] = React.useState(3)

  const [daysSaved, setDaysSaved] = React.useState(0)
  const [moneySaved, setMoneySaved] = React.useState(0)
  const [minutesSaved, setMinutesSaved] = React.useState(0)

  // const { hasCopied, onCopy, setValue } = useClipboard('')

  React.useEffect(() => {
    /*
    260 days in a work year, 60 minutes in an hour, 8 hours in a
    work day.
    */
    setDaysSaved(
      Math.round((buildTime * pullRequestCount * 260 * teamSize) / 60 / 8)
    )
    /*
    20 days in a work month.
    */
    setMinutesSaved(Math.round(buildTime * pullRequestCount * 20 * teamSize))
    /*
    150k is average US salary for software developer.
    */
    setMoneySaved(
      Math.round(
        ((150000 / 260) * (buildTime * pullRequestCount * 260 * teamSize)) /
          60 /
          8
      )
    )

    // const text = t(`blog.calculator.paragraph.4.text`, {
    //   amount: moneySaved,
    //   buildTime: buildTime,
    //   daysSaved: daysSaved,
    //   minutesSaved: minutesSaved,
    //   pullRequest: pullRequestCount,
    // })
    // setValue(text)
  }, [buildTime, pullRequestCount, teamSize])

  return (
    <>
      <Box as="section" py={{ base: '2', md: '2' }} textAlign="left">
        <Container maxW={{ base: 'lg', md: '4xl' }}>
          <Stack spacing={12} width="70%" mx="auto">
            <Stack spacing={4}>
              <Text
                fontSize={useBreakpointValue({ base: 'md', md: '2xl' })}
                fontWeight="normal"
              >
                {t(`blog.calculator.paragraph.0.text`)}
              </Text>
              <Slider
                id="slider"
                defaultValue={3}
                min={2}
                max={20}
                colorScheme="teal"
                onChange={v => setTeamSize(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <SliderMark value={5} mt="1" ml="-2.5" fontSize="sm">
                  5
                </SliderMark>
                <SliderMark value={10} mt="1" ml="-2.5" fontSize="sm">
                  10
                </SliderMark>
                <SliderMark value={20} mt="1" ml="-2.5" fontSize="sm">
                  20
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <Tooltip
                  hasArrow
                  bg="teal.500"
                  color="white"
                  placement="top"
                  isOpen={showTooltip}
                  label={t(`blog.calculator.paragraph.3.teamSize`, {
                    count: teamSize,
                  })}
                >
                  <SliderThumb boxSize={6}>
                    <Box color="teal" as={MdPeople} />
                  </SliderThumb>
                </Tooltip>
              </Slider>
            </Stack>
            <Stack spacing={4}>
              <Text
                fontSize={useBreakpointValue({ base: 'md', md: '2xl' })}
                fontWeight="normal"
              >
                {t(`blog.calculator.paragraph.1.text`)}
              </Text>
              <Slider
                id="slider"
                defaultValue={4}
                min={1}
                max={60}
                colorScheme="teal"
                onChange={v => setBuildTime(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <SliderMark value={15} mt="1" ml="-2.5" fontSize="sm">
                  15m
                </SliderMark>
                <SliderMark value={30} mt="1" ml="-2.5" fontSize="sm">
                  30m
                </SliderMark>
                <SliderMark value={60} mt="1" ml="-2.5" fontSize="sm">
                  60m
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <Tooltip
                  hasArrow
                  bg="teal.500"
                  color="white"
                  placement="top"
                  isOpen={showTooltip}
                  label={t(`blog.calculator.paragraph.3.buildTime`, {
                    count: buildTime,
                  })}
                >
                  <SliderThumb boxSize={6}>
                    <Box color="teal" as={MdBuild} />
                  </SliderThumb>
                </Tooltip>
              </Slider>
            </Stack>
            <Stack spacing={4}>
              <Text
                fontSize={useBreakpointValue({ base: 'md', md: '2xl' })}
                fontWeight="normal"
              >
                {t(`blog.calculator.paragraph.2.text`)}
              </Text>
              <Slider
                id="slider"
                defaultValue={2}
                min={1}
                max={10}
                colorScheme="teal"
                onChange={v => setPullRequestCount(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <SliderMark value={1} mt="1" ml="-2.5" fontSize="sm">
                  1
                </SliderMark>
                <SliderMark value={5} mt="1" ml="-2.5" fontSize="sm">
                  5
                </SliderMark>
                <SliderMark value={10} mt="1" ml="-2.5" fontSize="sm">
                  10
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <Tooltip
                  hasArrow
                  bg="teal.500"
                  color="white"
                  placement="top"
                  isOpen={showTooltip}
                  label={t(`blog.calculator.paragraph.3.pullRequest`, {
                    count: pullRequestCount,
                  })}
                >
                  <SliderThumb boxSize={6}>
                    <Box color="teal" as={GoGitPullRequest} />
                  </SliderThumb>
                </Tooltip>
              </Slider>
            </Stack>
          </Stack>
          <Text
            fontSize={useBreakpointValue({ base: 'md', md: '2xl' })}
            fontWeight="normal"
            mt={12}
          >
            "
            {t(`blog.calculator.paragraph.3.text`, {
              amount: moneySaved,
              buildTime: buildTime,
              daysSaved: daysSaved,
              minutesSaved: minutesSaved,
              pullRequest: pullRequestCount,
            })}
            "
            {/* <Button
                as={Text}
                fontSize={useBreakpointValue({ base: 'md', md: '2xl' })}
                fontWeight="normal"
                height={{ base: 6, md: 8 }}
                mt={{ base: 0, md: -2 }}
                px={2}
                onClick={onCopy}
                variant="ghost"
                sx={{ textDecoration: 'underline' }}
              >
                {hasCopied ? '(Copied!)' : '(Copy)'}
              </Button> */}
            <Text fontSize="xs" mt={3}>
              *
              <Trans i18nKey={`blog.calculator.paragraph.5.text`}>
                {
                  'For information on build times read CircleCI (<1 href="https://circleci.com/resources/2023-state-of-software-delivery" target="_blank">here</1>) and on salaries read StackOverflow (<1 href="https://survey.stackoverflow.co/2022/#salary-united-states" target="_blank">here</1>). For other ways your team loses time when building read GitHub (<1 href="https://github.blog/2022-12-08-experiment-the-hidden-costs-of-waiting-on-slow-build-times/" target="_blank">here</1>).'
                }
                <Link textDecoration={'underline'}></Link>
              </Trans>
            </Text>
          </Text>
        </Container>
      </Box>
    </>
  )
})
