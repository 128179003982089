import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FiSearch } from 'react-icons/fi'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'

import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {
  DocumentProps,
  NewDocumentFormInput,
  RunDocumentFormInput,
} from '../../../@types/document'
import { Alert } from './../../../../components/AlertDialog'
import { EditDocument } from './EditDocument'
import { NewDocument } from './NewDocument'
import { RunDocument } from './RunDocument'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const IndexDocuments = observer((props: DocumentProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [documentId, setDocumentId] = React.useState('')

  const {
    isOpen: isOpenNewDocument,
    onOpen: onOpenNewDocument,
    onClose: onCloseNewDocument,
  } = useDisclosure()

  const onCreateDocument = (values: NewDocumentFormInput) => {
    onCloseNewDocument()

    const runTag = {
      id: 'run',
      label: values.onBuild ? 'On Build' : 'On Demand',
    }

    values.tags = [...values.tags, runTag]

    props.viewModel.createDocument(values)
  }

  const newDocumentProps = {
    isOpen: isOpenNewDocument,
    onClose: onCloseNewDocument,
    onOpen: onOpenNewDocument,
    onSubmit: onCreateDocument,
  }

  const {
    isOpen: isOpenShowDocument,
    onOpen: onOpenShowDocument,
    onClose: onCloseShowDocument,
  } = useDisclosure()

  const onShowDocument = (event: unknown, documentId: string) => {
    setDocumentId(documentId)

    props.viewModel.onCloseAction = onCloseShowDocument
    props.viewModel.currentDocument = documentId

    onOpenShowDocument()
  }

  const onUpdateDocument = (values: NewDocumentFormInput) => {
    onCloseShowDocument()

    values.tags.forEach(tag => {
      if (tag.id == 'run') {
        tag.label = values.onBuild ? 'On Build' : 'On Demand'
      }
    })

    props.viewModel.updateDocument(documentId, values)

    setDocumentId('')
  }

  const editDocumentProps = {
    isOpen: isOpenShowDocument,
    onClose: onCloseShowDocument,
    onOpen: onOpenShowDocument,
    onSubmit: onUpdateDocument,
  }

  const {
    isOpen: isOpenRunDocument,
    onOpen: onOpenRunDocument,
    onClose: onCloseRunDocument,
  } = useDisclosure()

  const onRunDocument = (event: unknown, documentId: string) => {
    setDocumentId(documentId)

    props.viewModel.onCloseAction = onCloseRunDocument
    props.viewModel.currentDocument = documentId

    onOpenRunDocument()
  }

  const onApplyDocument = (values: RunDocumentFormInput) => {
    onCloseRunDocument()

    props.viewModel.runDocument(documentId, values.instances)

    setDocumentId('')
  }

  const runDocumentProps = {
    isOpen: isOpenRunDocument,
    onClose: onCloseRunDocument,
    onOpen: onOpenRunDocument,
    onSubmit: onApplyDocument,
  }

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmDelete = (event: unknown) => {
    onCloseDelete()
    props.viewModel.deleteDocument(documentId)

    setDocumentId('')
  }

  const onDeleteDocument = (event: unknown, documentId: string) => {
    setDocumentId(documentId)

    onOpenDelete()
  }

  React.useEffect(() => {
    props.viewModel.getDocuments()
  }, [])

  React.useEffect(() => {
    table.setPageSize(50) // Arbitrary value
    return props.viewModel.trackProgress()
  }, [props.viewModel.documents])

  const breakpointValue = useBreakpointValue({ base: true, md: false })
  const colorModeValue = useColorModeValue('sm', 'sm-dark')

  React.useEffect(() => {
    if (props.viewModel.error) {
      toast({
        description: t(props.viewModel.error.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'error',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.error = undefined
        },
      })
    }
    if (props.viewModel.notice) {
      toast({
        description: t(props.viewModel.notice.message as string),
        duration: 9000,
        isClosable: true,
        position: 'top',
        status: 'success',
        variant: 'subtle',
        onCloseComplete: () => {
          props.viewModel.notice = undefined
        },
      })
    }
  }, [props.viewModel.error, props.viewModel.notice])

  const [globalFilter, setGlobalFilter] = React.useState('')
  const columns = props.viewModel.tableColumns(
    t,
    onRunDocument,
    onShowDocument,
    onDeleteDocument
  )
  const data = props.viewModel.tableData

  const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <Box
      as="section"
      bg="bg-surface"
      p={{ base: '4', md: '8' }}
      w="100%"
      maxW="full"
      id="pw-documents"
    >
      <Container width="100%" height="100%" maxW="5xl">
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Stack
            spacing="4"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Heading
                fontSize={useBreakpointValue({ base: 'lg', md: '3xl' })}
                fontWeight="medium"
              >
                {t('documents.index.header.title')}
              </Heading>
              <Text color="muted">
                {t('documents.index.header.description')}
              </Text>
            </Stack>
            <Stack direction="row" spacing="3">
              <Button
                className="instance-guide-0"
                variant="primary"
                onClick={onOpenNewDocument}
              >
                {t('documents.index.header.buttonLabel')}
              </Button>
            </Stack>
          </Stack>
        </Skeleton>
      </Container>
      <Container
        py={{ base: '4', md: '8' }}
        px={{ base: '0', md: 8 }}
        maxW="5xl"
      >
        <Skeleton isLoaded={!props.viewModel.isLoading}>
          <Box
            bg="bg-surface"
            boxShadow={{
              base: 'none',
              md: colorModeValue,
            }}
            borderRadius={breakpointValue}
          >
            <Stack spacing="5">
              <Box px={{ base: '4', md: '6' }} pt="5">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  justify="space-between"
                >
                  <Text fontSize="lg" fontWeight="medium">
                    {t('documents.index.table.title')}
                  </Text>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search documents"
                      value={globalFilter}
                      onChange={event =>
                        setGlobalFilter(String(event.target.value))
                      }
                    />
                  </InputGroup>
                </Stack>
              </Box>
              <Box overflowX="auto">
                <Table>
                  <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = header.column.columnDef.meta
                          return (
                            <Th
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              isNumeric={meta?.isNumeric}
                            >
                              <Flex direction={'row'}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                <Spacer />
                                <chakra.span pl="4">
                                  {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === 'desc' ? (
                                      <Icon
                                        aria-label="sorted descending"
                                        as={IoArrowDown}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    ) : (
                                      <Icon
                                        aria-label="sorted ascending"
                                        as={IoArrowUp}
                                        color="muted"
                                        boxSize="4"
                                      />
                                    )
                                  ) : null}
                                </chakra.span>
                              </Flex>
                            </Th>
                          )
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map(row => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          const meta: any = cell.column.columnDef.meta
                          return (
                            <Td key={cell.id} isNumeric={meta?.isNumeric}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          )
                        })}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Stack>
          </Box>
          <Alert
            onClose={onCloseDelete}
            onConfirm={onConfirmDelete}
            onOpen={onOpenDelete}
            isOpen={isOpenDelete}
            body="documents.index.table.actions.delete.alert.body"
            confirm="documents.index.table.actions.delete.alert.confirm"
            dismiss="documents.index.table.actions.delete.alert.dismiss"
            header="documents.index.table.actions.delete.alert.header"
          />
        </Skeleton>
      </Container>
      <Drawer isOpen={isOpenNewDocument} onClose={onCloseNewDocument} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <NewDocument {...newDocumentProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={isOpenShowDocument}
        onClose={onCloseShowDocument}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <EditDocument {...editDocumentProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer isOpen={isOpenRunDocument} onClose={onCloseRunDocument} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <RunDocument {...runDocumentProps} {...props} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
})
