import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Box, Container, Stack } from '@chakra-ui/react'

import { AccountBillingViewProps } from '../../app/@types/account'
import { ProductFeaturesTable } from './ProductFeaturesTable'
import { ProductOverviewTable } from './ProductOverviewTable'

export const PlanChange = observer((props: AccountBillingViewProps) => {
  return (
    <Box as="section" bg="bg-surface">
      <Stack spacing={{ base: '16', md: '24' }} shouldWrapChildren>
        <Container maxW="full" px={{ base: '0' }}>
          <Stack spacing={{ base: '5', md: '7' }}>
            <ProductOverviewTable {...props} />
            <ProductFeaturesTable {...props} />
          </Stack>
        </Container>
      </Stack>
    </Box>
  )
})
