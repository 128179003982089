import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { CountdownPoster } from '../../../posters/Countdown'
import { EmailPoster } from '../../../posters/Email'
import { LandingPoster } from '../../../posters/Landing'
import { PosterViewModel } from '../../viewModels/Posters'

export const CountdownPosterProvider = observer(() => {
  return <CountdownPoster />
})

export const LandingPosterProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new PosterViewModel(store.accountStore)
  viewModel.name = 'landing'

  return <LandingPoster viewModel={viewModel} />
})

export const EmailPosterProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new PosterViewModel(store.accountStore)
  viewModel.name = 'email'

  return <EmailPoster viewModel={viewModel} />
})
