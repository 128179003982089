export const AmplifyConfigAuth = {
  aws_project_region: 'us-east-1', // process.env.REACT_APP_AUTH_REGION,
  aws_cognito_region: 'us-east-1', // process.env.REACT_APP_AUTH_REGION,
  aws_user_pools_id: process.env.COGNITO_USERPOOL_ID, // process.env.REACT_APP_AUTH_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.COGNITO_WEB_CLIENT_ID, // process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.COGNITO_DOMAIN,
    redirectSignIn: process.env.COGNITO_SIGN_IN_REDIRECT,
    redirectSignOut: process.env.COGNITO_SIGN_OUT_REDIRECT,
    responseType: 'code',
    // scope: ['email', 'openid'],
  },
  // federationTarget: 'COGNITO_USER_POOLS',
  // aws_cognito_username_attributes: [],
  // aws_cognito_social_providers: [],
  // aws_cognito_signup_attributes: ['EMAIL'],
  // aws_cognito_mfa_configuration: 'OFF',
  // aws_cognito_mfa_types: [],
  // aws_cognito_password_protection_settings: {
  //   passwordPolicyMinLength: 8,
  //   passwordPolicyCharacters: [],
  // },
  // aws_cognito_verification_mechanisms: [],
}
