import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Center,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-location'

import { ApplicationViewProps } from './../../app/@types/application'

export const AccountPendingDeletion = observer(
  (props: ApplicationViewProps) => {
    const colorModeValue = useColorModeValue('sm', 'sm-dark')
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
      <Center width="full">
        <Box as="section" py={{ base: '4', md: '8' }}>
          <Container maxW="3xl">
            <Box
              bg="bg-surface"
              boxShadow={colorModeValue}
              borderRadius="lg"
              p={{ base: '4', md: '6' }}
            >
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text fontSize="lg" fontWeight="medium">
                    {t(`accounts.pendingDeletion.header`)}
                  </Text>
                  <Text fontSize="sm" color="muted">
                    {t(`accounts.pendingDeletion.detail`)}
                  </Text>
                </Stack>
                <Box>
                  <Button
                    onClick={() => {
                      props.viewModel.signOut(navigate)
                    }}
                    variant="primary"
                  >
                    {t(`accounts.pendingDeletion.action`)}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Center>
    )
  }
)
