import { action, computed, makeObservable, observable } from 'mobx'

import { FeatureService } from '../services/featureService'
import { AccountStore } from './accountStore'
import { AuthStore } from './authStore'

export class ApplicationStore {
  accountStore: AccountStore
  authStore: AuthStore
  _flags: { [key: string]: { enabled: boolean } }
  _willShowBanner: boolean
  _willShowGuide = false

  constructor(accountStore: AccountStore, authStore: AuthStore) {
    this.accountStore = accountStore
    this.authStore = authStore

    this._flags = {}
    this._willShowBanner = true
    makeObservable(this, {
      isCurrentMenuItem: action,
      account: computed,
      accountRequiresAction: computed,
      flags: computed,
      identities: computed,
      isAuthenticated: computed,
      willShowBanner: computed,
      willShowGuide: computed,
      accountStore: observable,
      authStore: observable,
      featureFlagFor: false,
      featureFlags: false,
      _flags: observable,
      _willShowBanner: observable,
      _willShowGuide: observable,
    })
  }

  public get account() {
    return this.accountStore.account
  }

  public get accountRequiresAction() {
    return this.accountStore.account?.account.nextAction
  }

  public get flags(): { [key: string]: { enabled: boolean } } {
    return this._flags
  }

  public set flags(value: { [key: string]: { enabled: boolean } }) {
    this._flags = value
  }

  public get identities(): Promise<unknown> {
    return this.authStore.currentSession()
  }

  public get isAuthenticated(): boolean {
    return this.authStore.isAuthenticated
  }

  public get willShowBanner(): boolean {
    return false
    // return this.accountStore.plan == 'free' && this._willShowBanner
  }

  public set willShowBanner(value: boolean) {
    this._willShowBanner = value
  }

  public get willShowGuide(): boolean {
    return this._willShowGuide
  }

  public set willShowGuide(value: boolean) {
    this._willShowGuide = value
  }

  public featureFlagFor(name: string): boolean {
    try {
      return this.flags[name].enabled
    } catch {
      return false
    }
  }

  public featureFlags() {
    FeatureService.featureFlags().then(response => {
      if (response.ok) {
        response.json().then(data => {
          this.flags = (
            data as { flags: { [key: string]: { enabled: boolean } } }
          ).flags
        })
      }
    })
  }

  public isCurrentMenuItem(label: string): 'page' | false {
    return label == window.location.pathname ? 'page' : false
  }
}
