import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Center, Container, HStack } from '@chakra-ui/react'
import { useMatch, useNavigate } from '@tanstack/react-location'

import { AutomaticStep } from '../../../components/Steps/AutomaticStep'
import { useStep } from '../../../hooks/useStep'
import { SessionViewProps } from '../../@types/session'

export const DeleteSession = observer((props: SessionViewProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    params: { session },
  } = useMatch()

  React.useEffect(() => {
    props.viewModel.authenticate()
  }, [])

  React.useEffect(() => {
    if (props.viewModel.nextStep == 2) {
      setStep(props.viewModel.nextStep)
    } else if (props.viewModel.nextStep > 0) {
      setStep(props.viewModel.nextStep)
      props.viewModel.deleteCredentials(session)
    } else {
      props.viewModel.deleteSession(session)
    }
  }, [props.viewModel.nextStep])

  const [currentStep, { setStep }] = useStep({
    maxStep: props.viewModel.steps.length,
    initialStep: 0,
  })

  const deleteInstance = () => {
    navigate({ to: `/spaces?session=${session}` })
  }

  return (
    <Box bg="bg-surface">
      <Container
        display="flex"
        justifyContent="center"
        alignItems="center"
        minH="40"
      >
        <HStack spacing="0" justify="space-evenly" flex="1">
          {props.viewModel.steps.map((step, id) => (
            <AutomaticStep
              key={id}
              cursor="pointer"
              title={t(step.title)}
              description={t(step.description)}
              isActive={currentStep === id}
              isCompleted={currentStep > id}
              isFirstStep={id === 0}
              isLastStep={props.viewModel.steps.length === id + 1}
            />
          ))}
        </HStack>
      </Container>
      <Center>
        <Box>
          <Button
            hidden={currentStep < props.viewModel.steps.length - 1}
            onClick={() => deleteInstance()}
            variant="primary"
          >
            {t('sessions.delete.action')}
          </Button>
        </Box>
      </Center>
    </Box>
  )
})
