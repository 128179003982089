import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Highlight,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'

export const HeroWithVideo = observer((props: PosterProps) => {
  const { t } = useTranslation()

  const didEndVideo = (event: unknown) => {
    props.viewModel.didEndExplainerVideo()
    /* eslint-disable no-self-assign */
    event.currentTarget.src = event.currentTarget.src
  }

  const didPlayVideo = () => {
    props.viewModel.didStartExplainerVideo()
  }

  return (
    <Box>
      <Box as="section">
        <Box
          maxW={{ base: 'xl', md: '6xl' }}
          mx="auto"
          my={{ base: '6', md: '8' }}
          px={{ base: '6', md: '8' }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
              fontWeight="bold"
              mx="auto"
              lineHeight="1.25"
              letterSpacing="tight"
            >
              <Highlight
                query={['days', 'thousands of dollars']}
                styles={{ px: '1', py: '0', rounded: '8px', bg: 'red.200' }}
              >
                {t(`posters.${props.viewModel.name}.hero.heading`) as string}
              </Highlight>
            </Heading>
            <Text fontSize="3xl" lineHeight="1.25" mx="auto">
              {t(`posters.${props.viewModel.name}.hero.subheading`)}
            </Text>
            <Center>
              <HStack my={12} spacing="3">
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/pages/productivity"
                  variant="secondary"
                >
                  {t(`posters.${props.viewModel.name}.hero.actions.secondary`)}
                </Button>
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/auth/signup"
                  variant="primary"
                >
                  {t(`posters.${props.viewModel.name}.hero.actions.primary`)}
                </Button>
              </HStack>
            </Center>
          </Box>
          <Box
            maxW="4xl"
            className="group"
            position="relative"
            overflow="hidden"
            mx="auto"
            px="2"
          >
            <Center>
              <Box
                alt="PairSpaces Flow"
                as="video"
                controls
                objectFit="contain"
                onEnded={didEndVideo}
                onPlay={didPlayVideo}
                poster="/images/explainer_poster.png"
                src="/videos/PairFlow_480p.mov"
                sx={{
                  aspectRatio: '16/9',
                }}
                width="640px"
              />
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
