import { action, computed, makeObservable, observable } from 'mobx'
import { BiHappyBeaming, BiShapeCircle } from 'react-icons/bi'
import { MdSpeed } from 'react-icons/md'

import { Plan, Price } from '../../@types/account'
import { ApplicationConstants as Constants } from '../../constants'
import { AnalyticsService } from '../../services/analyticsService'
import { AccountStore } from '../../stores/accountStore'

export class PosterViewModel {
  accountStore: AccountStore

  _currencySymbol = '$'
  _daysSaved = 0
  _minutesSaved = 0
  _moneySaved = 0
  _name = 'landing'
  _period = 'month'
  _plans: Plan[] = []

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore

    makeObservable(this, {
      togglePeriod: action,
      currencySymbol: computed,
      daysSaved: computed,
      minutesSaved: computed,
      moneySaved: computed,
      name: computed,
      period: computed,
      plans: computed,
      accountStore: observable,
      _daysSaved: observable,
      _minutesSaved: observable,
      _moneySaved: observable,
      _name: observable,
      _period: observable,
      _plans: observable,
      ctaImages: false,
      ctaLargeLinks: false,
      ctaSmallLinks: false,
      didEndExplainerVideo: false,
      didStartExplainerVideo: false,
      didViewHero: false,
      documentation: false,
      faqLength: false,
      featureLength: false,
      features: false,
      icons: false,
      priceForPeriod: false,
      testimonialLength: false,
      _currencySymbol: false,
    })
  }

  public get ctaImages() {
    return {
      landing: ['zerotrust.gif', 'jupyter-diff.png'],
      onboarding: ['login'],
      secure: ['mark'],
    }
  }

  public get ctaLargeLinks() {
    return {
      landing: [
        { primary: '/auth/signup', secondary: '/pages/security' },
        { primary: '/pages/savings', secondary: null },
      ],
      onboarding: [{ primary: '/auth/signup', secondary: '/' }],
      secure: [{ primary: '/auth/signup', secondary: '/' }],
    }
  }

  public get ctaSmallLinks() {
    return {
      landing: { primary: '/about', secondary: '' },
      onboarding: { primary: '/auth/signup', secondary: '/pages/security' },
      secure: { primary: '/auth/signup', secondary: '/pages/security' },
    }
  }

  public get currencySymbol(): string {
    return this._currencySymbol
  }

  public set currencySymbol(value: string) {
    if (value == 'USD') {
      this._currencySymbol = '$'
    }
  }

  public get daysSaved(): number {
    return this._daysSaved
  }

  public set daysSaved(value: number) {
    this._daysSaved = value
  }

  public documentation() {
    window.open(Constants.DOCUMENTATION_URL, '_blank')
  }

  public get faqLength(): number {
    return Constants.FAQ_LENGTH
  }

  public get features() {
    return Constants.FEATURES
  }

  public get featureLength() {
    return {
      landing: 3,
      onboarding: 6,
      secure: 6,
    }
  }

  public get icons() {
    return {
      landing: [[BiHappyBeaming, BiShapeCircle, MdSpeed]],
    }
  }

  public get minutesSaved(): number {
    return this._minutesSaved
  }

  public set minutesSaved(value: number) {
    this._minutesSaved = value
  }

  public get moneySaved(): number {
    return this._moneySaved
  }

  public set moneySaved(value: number) {
    this._moneySaved = value
  }

  public get name(): string {
    return this._name
  }

  public set name(value: string) {
    this._name = value
  }

  public get plans(): Plan[] {
    const key = `PLANS${process.env.PLANS}` as keyof typeof Constants
    return Constants[key] as Plan[]
  }

  public didEndExplainerVideo() {
    AnalyticsService.didEndExplainerVideo()
  }

  public didStartExplainerVideo() {
    AnalyticsService.didStartExplainerVideo()
  }

  public didViewHero() {
    AnalyticsService.didViewHero()
  }

  public priceForPeriod(plan: Plan): number {
    const prices: Price[] = plan.prices
    const factor = this.period == 'year' ? 12 : 1
    let value = 0

    prices.forEach(price => {
      const period = price.recurring.interval
      if (period == this.period) {
        value = parseInt(price.unitAmount) / (factor * 100)
      }
    })

    return value
  }

  public get testimonialLength(): number {
    return Constants.TESTIMONIAL_LENGTH
  }

  public togglePeriod() {
    if (this._period == 'month') {
      this._period = 'year'
    } else {
      this._period = 'month'
    }
  }

  public get period() {
    return this._period
  }
}
