import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useStores } from '../../../hooks/useStores'
import { InstanceViewModel } from '../../viewModels/Instance'
import { IndexInstances } from '../../views/Instance'

export const InstanceIndexProvider = observer(() => {
  const { store } = useStores()

  const viewModel = new InstanceViewModel(
    store.accountStore,
    store.applicationStore,
    store.containerStore,
    store.instanceStore,
    store.watchStore
  )

  return <IndexInstances viewModel={viewModel} />
})
