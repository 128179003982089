import { observer } from 'mobx-react-lite'
import React, { createContext, useContext } from 'react'

import { RootStore } from './../app/stores/index'
import { PlausibleProvider } from './usePlausible'

type UseStores = {
  store: RootStore
}

type Props = {
  children?: React.ReactElement
}

const StoresContext = createContext({} as UseStores)

const useProvideStores = (): UseStores => {
  const store = new RootStore()
  return { store }
}

export const StoresProvider: React.FC<Props> = observer(({ children }) => {
  const store = useProvideStores()
  return (
    <StoresContext.Provider value={store}>
      <PlausibleProvider>{children}</PlausibleProvider>
    </StoresContext.Provider>
  )
})

export const useStores = () => {
  return useContext(StoresContext)
}
