import LeaderLine from 'leader-line-new'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  Heading,
  Highlight,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'
import { CodeEditor } from './CodeEditor'

type CTAProps = PosterProps & {
  level?: number
  staticImage: boolean
}

const branch = [
  {
    padding: 0,
    value: 'from linked_list_node import LinkedListNode',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 0,
    value: 'class LinkedList:',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: 'def __init__(self):',
  },
  {
    padding: 80,
    value: 'self._head = None',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 40,
    value: 'def insert(self, value):',
  },
  {
    padding: 80,
    value: 'new_node = LinkedListNode(value)',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    background: true,
    padding: 80,
    value: 'if self.head is None:',
  },
  {
    background: true,
    padding: 120,
    value: 'self.head = new_node',
  },
  {
    padding: 120,
    value: 'return',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    background: true,
    padding: 80,
    value: 'current = self.head',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 80,
    value: 'while current.next:',
  },
  {
    padding: 120,
    value: 'current = current.next',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    padding: 80,
    value: 'current.next = new_node',
  },
  {
    padding: 0,
    value: ' ',
  },
  {
    background: true,
    padding: 40,
    value: '@property',
  },
  {
    background: true,
    padding: 40,
    value: 'def head(self):',
  },
  {
    background: true,
    padding: 80,
    value: 'return self._head',
  },
]

export const CTA = observer((props: CTAProps) => {
  const { t } = useTranslation()

  let level = props.level
  if (typeof props.level == 'undefined') {
    level = 1
  }

  React.useEffect(() => {
    setTimeout(() => {
      try {
        new LeaderLine(
          document.getElementById(`start-${level}`),
          document.getElementById(`end-${level}`),
          {
            color: 'teal', //'#2d3748',
            path: 'arc',
            startPlug: 'disc',
            startSocket: 'top',
            endSocket: 'bottom',
          }
        )
      } catch {
        const lines = document.getElementsByClassName('leader-line')
        lines.length > 0 && lines[0].remove()
      }
    }, 2000)

    return () => {
      const lines = document.getElementsByClassName('leader-line')
      lines.length > 0 && lines[0].remove()
    }
  }, [])

  return (
    <Box>
      <Container
        pl={{ base: '16', md: '24' }}
        pr={{ base: '16', md: '0' }}
        py={{ base: '16', md: '24' }}
        maxW="auto"
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', lg: '8' }}
          width={{ base: 'auto', md: 'full' }}
        >
          <Stack
            spacing={{ base: '8', md: '10' }}
            width={{ base: 'auto', md: '2xl' }}
            justify="center"
          >
            <Stack spacing={{ base: '4', md: '6' }}>
              <Heading
                fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
              >
                {t(`posters.${props.viewModel.name}.ctaLarge${level}.heading`)}
              </Heading>
              <Text
                color="muted"
                fontSize={useBreakpointValue({ base: 'lg', md: '2xl' })}
                lineHeight="1.25"
              >
                {t(
                  `posters.${props.viewModel.name}.ctaLarge${level}.subHeading`
                )}
              </Text>
            </Stack>
            <Stack
              direction={{ base: 'column-reverse', md: 'row' }}
              spacing="3"
            >
              {props.viewModel.ctaLargeLinks[props.viewModel.name][level - 1]
                .secondary && (
                <Button
                  as={Link}
                  to={
                    props.viewModel.ctaLargeLinks[props.viewModel.name][
                      level - 1
                    ].secondary
                  }
                  variant="secondary"
                  size="lg"
                >
                  {t(
                    `posters.${props.viewModel.name}.ctaLarge${level}.secondaryCTA`
                  )}
                </Button>
              )}
              <Button
                as={Link}
                to={
                  props.viewModel.ctaLargeLinks[props.viewModel.name][level - 1]
                    .primary
                }
                variant="primary"
                size="lg"
                width="auto"
              >
                {t(
                  `posters.${props.viewModel.name}.ctaLarge${level}.primaryCTA`
                )}
              </Button>
            </Stack>
          </Stack>
          <Box
            bg="white"
            boxShadow="lg"
            width={
              props.staticImage
                ? useBreakpointValue({ base: 'auto', md: 'full' })
                : useBreakpointValue({ base: 'auto', md: '2xl' })
            }
            height={{ base: 'auto' }}
            className="group"
            position="relative"
            overflow="hidden"
            rounded="lg"
          >
            <Box id={`end-${level}`} position="absolute" top="75%" left="50%" />
            {props.staticImage ? (
              <Image
                alt="venv cli terminal output"
                maxW={{ base: 'auto', md: 'full' }}
                mx="auto"
                height={{ base: 'auto' }}
                loading="lazy"
                objectFit={{ base: 'scale-down', md: 'cover' }}
                src={`/images/${
                  props.viewModel.ctaImages[props.viewModel.name][level - 1]
                }`}
              />
            ) : (
              <CodeEditor
                branch="feat/linked-list"
                filename="linked_list.py"
                isLast={true}
                lines={branch}
                prefix="llb"
              />
            )}
          </Box>
        </Stack>
        <Text
          id={`start-${level}`}
          fontSize="md"
          fontFamily="Comic Neue, cursive"
          lineHeight={5}
          mx="auto"
          my={level == 1 ? 12 : 4}
          position="relative"
          top={level == 1 ? 0 : 0}
          left={level == 1 ? 12 : -20}
          // textAlign="right"
          width={level == 1 ? 96 : 32}
        >
          <Highlight query={['before']} styles={{ fontStyle: 'italic' }}>
            {t(`posters.${props.viewModel.name}.ctaLarge${level}.image.text`)}
          </Highlight>
        </Text>
      </Container>
    </Box>
  )
})
