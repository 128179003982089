import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FiLogOut } from 'react-icons/fi'

import { Spacer, Stack, useBreakpointValue } from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-location'

import { ApplicationViewProps } from './../../app/@types/application'
import { NavigationButton } from './NavigationButton'

export const MenuPendingDeletion = observer((props: ApplicationViewProps) => {
  const navigate = useNavigate()
  const widthBreakpointValue = useBreakpointValue({
    base: '2em',
    md: 'full',
  })

  return (
    <Stack
      justify="space-between"
      spacing="1"
      width={widthBreakpointValue}
      py={{ base: '4', md: '8' }}
    >
      <Stack spacing="8" shouldWrapChildren></Stack>
      <Spacer />
      <NavigationButton
        onClick={() => {
          props.viewModel.signOut(navigate)
        }}
        label="Sign out"
        icon={FiLogOut}
      />
    </Stack>
  )
})
