import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import './localization'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('app')!
const root = createRoot(container)
root.render(<App />)
