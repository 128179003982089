import { computed, makeAutoObservable, observable } from 'mobx'

import { AuthStore } from './../../stores/authStore'

export class PrivateRouteViewModel {
  authStore: AuthStore
  _isAuthenticated = false
  _isLoading = true

  constructor(store: AuthStore) {
    this.authStore = store
    makeAutoObservable(this, {
      isAuthenticated: computed,
      isLoading: computed,
      _isAuthenticated: observable,
      _isLoading: observable,
      redirectPath: false,
    })
  }

  public async authenticate() {
    return await this.authStore.currentSession().then(session => {
      if (session) {
        this.isAuthenticated = true
      } else {
        this.isAuthenticated = false
      }

      this.isLoading = false
    })
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated
  }

  set isAuthenticated(value: boolean) {
    this._isAuthenticated = value
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  set redirectPath(value: string | undefined) {
    this.authStore.redirectPath = value
  }
}
